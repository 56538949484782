import React, { Component} from "react";
import {NavLink, Navigate } from "react-router-dom";
import axios from "axios";
import SelectCarsMob from "../../Components/SelectCars/SelectCarsMob";
import SelectCarsWeb from "../../Components/SelectCars/SelectCarsWeb";
import { withTranslation } from "react-i18next";
import { ConfigContext } from "../../Context/ConfigContext";
import withRouter from "../../Routes/withRouter";
class BrowseCatalog  extends Component{
  static contextType = ConfigContext;
  constructor(props){
    super(props);
    this.state = {
      isMobile : false,
      selectedCategory: null,
      categories: [],
      loading: true,
      carPartsCategory: [],
      carData : null,
      redirect:false
    }
  }
  GetSelectedCarInformation = () => {
    
    const storedCars = JSON.parse(localStorage.getItem("cars"));
    if(storedCars){

      if(this.props.params.car_id){
        const selectedCar = storedCars.filter((car) => car.car_id === Number(this.props.params.car_id));
      console.log("selectedCar From BC 1", selectedCar);
      return selectedCar[0];
    }else if(storedCars.length > 0){
      const selectedCar = storedCars.filter((car) => car.selected === 1);
      console.log("selectedCar From BC 2", selectedCar);
      return selectedCar[0];
    }else{
      return false;
    }
    }else{
      return false;
    }
  }
  async componentDidMount(){
    
    const {apiURL} = this.context;
      try {
        this.setState({loading:true});
      const response = await axios.get(
        `${apiURL}/Categories/GetCategories?country_id=225&lang=en`
      );
      this.setState({loading:false});
      // s
      const data = response.data.data;
      if (data) {
        this.setState({categories: data});
      } else {
        console.error("Invalid API response:", response.message);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      this.setState({loading:false});
    }
    this.setState((prevState) => {
      return { carData: this.GetSelectedCarInformation() };
    }, () => {
      // console.log("Car Selected Data: ", this.state.carData);
    });

    

    const handleResize = () => {
      // Use this.setState to update the state
      this.setState({
        isMobile: window.innerWidth <= 768, // Adjust the breakpoint as needed
        loading: false,
        error: null,
      });
    };
  
    // Initial check on mount
    // handleResize();
  
    // // Listen for window resize events
    // window.addEventListener('resize', handleResize);
  
    // // Cleanup the event listener
    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
    
  }

  
 
  handleCategoryClick = (categoryId) => {
    const clickedCategory = this.state.categories[0].children.find(
      (category) => Number(category.category_id) === categoryId
    );

    if (
      clickedCategory &&
      clickedCategory.children &&
      clickedCategory.children.length > 0
    ) {
      this.setState({selectedCategory:clickedCategory.children});
    } else {
      this.setState({selectedCategory:[clickedCategory]});
    }
  };
  fetchCategoryParts = (categoryId, categorySlug) => {
    // console.log("categoryId", categoryId, "and categorySlug", categorySlug );
    // navigate(`/Results/${categoryId}/${categorySlug}`);
    this.state.redirect ? <Navigate to={`/Results/${categorySlug}-${categoryId}`} replace={true}/> : <h2>Hello</h2>
    this.props.navigate(`/Results/${categorySlug}-${categoryId}`);
   
    // Navigate(`/Results/${categoryId}/${categorySlug}`);
  };
  
  renderCategories = () => {
    
    const carPartsCategory = this.state.categories.find(
      (category) => category.category_title = "Car Parts"
    );
    const carPartsChildren = carPartsCategory && carPartsCategory.children;
    if (!this.state.selectedCategory) {
      return (
        carPartsChildren &&
        carPartsChildren.length > 0 &&
        carPartsChildren.map((category) => (
          <div key={category.category_id} className="col-md-2">
            <div
              className="card border-0 shadow-hover"
              onClick={() => this.handleCategoryClick(category.category_id)}
            >
              <div className="card-body d-flex flex-column justify-content-center align-items-center">
                <img
                  src={category.category_picture}
                  className="img-fluid"
                  alt={category.category_title}
                />
              </div>
              <div className="card-text text-center pl-2 pr-2 mb-1">
                <h6>{category.category_title}</h6>
              </div>
            </div>
          </div>
        ))
      );
    } else {
      return (
        this.state.selectedCategory &&
        this.state.selectedCategory.length > 0 &&
        this.state.selectedCategory.map((childCategory) => (
          <div key={childCategory.category_id} className="col-md-2">
            <div
              className="card border-0 shadow-hover"
              onClick={() =>
                this.fetchCategoryParts(
                  childCategory.category_id,
                  childCategory.category_slug
                )
              }
            >
              <div className="card-body d-flex flex-column justify-content-center align-items-center">
                <img
                  src={childCategory.category_picture}
                  className="img-fluid"
                  alt={childCategory.category_title}
                />
              </div>
              <div className="card-text text-center pl-2 pr-2 mb-1">
                <h6>{childCategory.category_title}</h6>
              </div>
            </div>
          </div>
        ))
      );
    }
  }
  


 
  
  render() {
    
    const { t } = this.props;
    const carData = this.GetSelectedCarInformation();
    
    var displayCarInfo = carData ? 'block': 'none'
    const {apiURL} = this.context;
    const { isMobile } = this.state;
    // return <div></div>
    return (
      <>
     
        <main className="main">          
                <div className="container my-5" style={{display: displayCarInfo}}>
                  <div className="selected-car p-5">
                    <div className="pr-5 selected-img" >
                      <img
                        src={`${apiURL}/public/placeholder_category.jpeg`}
                        style={{ height: "100px", width: "auto" }}
                        className="img-fluid"
                        alt="Carz7"
                      />
                    </div>
                    <div className="selected-car-text pl-5">
                      <div>
                      <h1 className="text-dark h2">
                        {carData
                          ? `${carData.car_year} ${carData.car_brand} ${carData.car_model} ${carData.car_engine}`
                          : "Loading..."}
                      </h1>
                      </div>
                      <div>
                        {isMobile && isMobile ? (
                          <button className="btn btn-outline-primary btn-ellipse"
                          data-toggle="modal" 
                          data-target=".bg-modal-for-car-select-in-catalog-for-mobile"
                          >
                          {t("ChangeCar")}
                        </button>
                        ) : (
                          <button className="btn btn-outline-primary btn-ellipse"
                        data-toggle="modal" 
                        data-target=".bg-modal-for-car-select-in-catalog"
                        >
                          {t("ChangeCar")}
                        </button>
                        )}
                        
                      </div>
                    </div>
                  </div>
                </div>
              
          <div className="parts p-5">
            <div className="container">
              <div className="flex-wrap d-flex justify-content-between align-items-center">
                <h4 onClick={() => this.props.navigate(-1)}>
                  <i className="fas fa-arrow-left mr-3 my-icon"></i>{" "}
                  {this.state.selectedCategory
                    ? this.state.selectedCategory[0].category_title
                    : this.state.carPartsCategory && this.state.carPartsCategory.category_title}
                </h4>
                <div className="toolbox-item layout-modes">
                  <div>
                    <NavLink
                      to="#"
                      className="layout-btn btn-grid active"
                      title="Grid"
                    >
                      <i className="icon-mode-grid"></i>
                    </NavLink>
                    <span>{t("GridView")}</span>
                  </div>
                  <div className="ml-4">
                    <NavLink to="#" className="layout-btn btn-list" title="List">
                      <i className="icon-mode-list"></i>
                    </NavLink>
                    <span>{t("ListView")}</span>
                  </div>
                </div>
              </div>
              <div className="row mt-3">{this.renderCategories()}</div>
            </div>
          </div>
         
        </main>
       
  
       <div className="modal fade bg-modal-for-car-select-in-catalog" style={{ background:'rgb(0 0 0 / 80%)' }}  role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl" style={{display: 'flex',justifyContent: 'center', height: '67vh' ,alignItems : 'center'}}>
            <div className="modal-content"   style={{ background: 'transparent', boxShadow : 'none', border: 'none', minWidth : '160%',display:"block"}}>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{color:'#fff'}}>
              <span aria-hidden="true" style={{fontSize:"40px" }}>×</span>
            </button>
              <SelectCarsWeb></SelectCarsWeb>
            </div>
          </div>
        </div>
          <div className="modal fade bg-modal-for-car-select-in-catalog-for-mobile" style={{ background:'rgb(0 0 0 / 80%)' }} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-sm" style={{display: 'flex', justifyContent: 'center', height: '67vh', alignItems: 'center'}}>
                <div className="modal-content" style={{ background: 'transparent', boxShadow: 'none', border: 'none', minWidth: 'auto', display: "block"}}>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ color: '#fff', position: 'absolute', top: '-50px', right: '30px' }}>
                    <span aria-hidden="true" style={{ fontSize: "40px" }}>×</span>
                  </button>
                  {/* <SelectCarsMob></SelectCarsMob> */}
                </div>
              </div>
          </div>
      </>
    )
  }
}

export default withTranslation()(withRouter(BrowseCatalog));