import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { ConfigContext } from "../../Context/ConfigContext";
import withRouter from "../../Routes/withRouter";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import AccountSiderBar from "./AccountSideBar";
import Invoice from "../../Components/Invoice/Invoice";
import { withTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
class OrderDetails extends Component {
  static contextType = ConfigContext;

  constructor(props) {
    super(props);
    this.state = {
      orderInfo: [],
      address: [],
      details: [],
      items: [],
      success: true,
    };
  }
  componentDidMount() {
    this.OrderInfo();
  }
  OrderInfo = async () => {
    const { apiURL, token, lang } = this.context;

    try {
      const response = await axios.get(`${apiURL}/Account/GetMyOrderInfo`, {
        params: {
          lang: lang,
          customer_id: localStorage.getItem("customer_id") || 1,
          order_uid: this.props.params.order_uid,
        },
        headers: {
          token: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      console.log(token, response.data);
      var address = JSON.parse(response.data.data.details.address_json);
      this.setState({
        orderInfo: response.data.data,
        address: address,
        details: response.data.data.details,
        items: response.data.data.items,
        success: response.data.success,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  downloadInvoice = () => {
    var uid = this.props.params.order_uid;
    htmlToImage
      .toPng(document.getElementById("myInvoice"))
      .then(async (dataUrl) => {
        console.log(`${dataUrl}`)
        // var link = document.createElement('a');
        // link.download = `Invoice-${uid}.pdf`;
        // link.href = dataUrl.replaceAll('data:image/png;', 'data:application/pdf;');
        // link.click();
        this.downloadPdf(dataUrl);

        // download(dataUrl, 'my-node.png');
      });
  };
  printInvoice = () => {
    var uid = this.props.params.order_uid;
    htmlToImage
      .toPng(document.getElementById("myInvoice"))
      .then(async (dataUrl) => {
        // console.log(`${dataUrl}`)
        // var link = document.createElement('a');
        // link.download = `Invoice-${uid}.pdf`;
        // link.href = dataUrl.replaceAll('data:image/png;', 'data:application/pdf;');
        // link.click();
        this.printPdf(dataUrl);

        // download(dataUrl, 'my-node.png');
      });
  };
  downloadPdf = async (base64Image) => {
    // A4 dimensions in points (1 inch = 72 points)
    const a4Width = 595.28;
    const a4Height = 841.89;

    const pdf = new jsPDF("p", "pt", "a4");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const image = new Image();
    image.src = base64Image;

    image.onload = () => {
      // Calculate the aspect ratio to maintain the image's proportions
      const aspectRatio = image.width / image.height;

      // Calculate the dimensions to fit the image within A4 without stretching
      let newWidth, newHeight;
      if (aspectRatio > 1) {
        newWidth = Math.min(a4Width, image.width);
        newHeight = newWidth / aspectRatio;
      } else {
        newHeight = Math.min(a4Height, image.height);
        newWidth = newHeight * aspectRatio;
      }

      // Set the canvas dimensions
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the image onto the canvas
      context.drawImage(image, 0, 0, newWidth, newHeight);

      // Convert the canvas content to a data URL (base64)
      const dataUrl = canvas.toDataURL("image/jpeg", 1.0);

      // Add the image to the PDF
      pdf.addImage(dataUrl, "JPEG", 0, 0, a4Width, a4Height);
      //   pdf.save('test.pdf');
      var title = "Invoice-" + this.props.params.order_uid;
      pdf.save(title + "test.pdf");
      return pdf;
    };
  };
  printPdf = async (base64Image) => {
    const a4Width = 595.28;
    const a4Height = 841.89;

    const pdf = new jsPDF("p", "pt", "a4");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const image = new Image();
    image.src = base64Image;

    image.onload = () => {
      // Calculate the aspect ratio to maintain the image's proportions
      const aspectRatio = image.width / image.height;

      // Calculate the dimensions to fit the image within A4 without stretching
      let newWidth, newHeight;
      if (aspectRatio > 1) {
        newWidth = Math.min(a4Width, image.width);
        newHeight = newWidth / aspectRatio;
      } else {
        newHeight = Math.min(a4Height, image.height);
        newWidth = newHeight * aspectRatio;
      }

      // Set the canvas dimensions
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the image onto the canvas
      context.drawImage(image, 0, 0, newWidth, newHeight);

      // Convert the canvas content to a data URL (base64)
      const dataUrl = canvas.toDataURL("image/jpeg", 1.0);

      // Add the image to the PDF
      pdf.addImage(dataUrl, "JPEG", 0, 0, a4Width, a4Height);
      var title = "Invoice-" + this.props.params.order_uid;
      const printWindow = window.open(pdf.output("bloburl"), "_blank");
      if (printWindow) {
        printWindow.document.title = title;
        printWindow.print();
      }
    };
  };
  render() {
    const { orderInfo, address, details, items } = this.state;
    const { t } = this.props;
    const dir = localStorage.getItem('dir');
    return (
      <main>
        <div className="page-header">
          <div className="container d-flex flex-column align-items-center">
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <div className="container">
                <ol className="breadcrumb" dir={dir}>
                  <li className="breadcrumb-item">
                    <NavLink to={"/"}>{t("home")}</NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink to={() => {}}>{t("my_account")}</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("orders")}
                  </li>
                </ol>
              </div>
            </nav>
            <h1>{t("order_details")}</h1>
          </div>
        </div>
        <div className="container account-container custom-account-container" dir={dir}>
          <div className="row">
            <AccountSiderBar />
            <div className="col-lg-9 order-lg-last order-1 tab-content">
              <div
                className="tab-pane fade show active"
                id="address"
                role="tabpanel">
                <div className="order-content">
                    <div className="row">
                        <div className="col-md-6">
                        <h3 className="account-sub-title d-none d-md-block">
                            <i className="sicon-social-dropbox align-middle mr-3" />
                            
                            {t("order_number", {value: ":"+this.props.params.order_uid})}
                        </h3>
                        </div>
                        <div className="col-md-6 align-bottom d-flex justify-content-right">
                            
                            <div className="align-bottom d-flex justify-content-right" style={{paddingTop: '10px'}}>
                            <span style={{cursor: 'pointer'}} className={`nav-link`}  onClick={()=>this.downloadInvoice()}><i className="sicon-cloud-download"></i> {t("download_invoice")}</span>
                            <span style={{cursor: 'pointer'}} className={`nav-link`} onClick={()=>this.printInvoice()}><i className="sicon-printer"></i> {t("print_invoice")}</span>
                            <span style={{cursor: 'pointer'}} className={`nav-link`}><i className="sicon-envelope"></i> {t("mail_invoice")}</span>
                            </div>
                        </div>
                    </div>
                  
                  
                  
                  <div className="order-table-container text-center">
                    <div className="order-summary">
                      <table>
                        <tr>
                          <td
                            className="text-left align-top"
                            style={{ width: "40%" }}>
                            <h5>{t("shipping_address")}</h5>
                            <b>
                              {address.first_name} {address.last_name}
                            </b>
                            <p
                              className="xw-50"
                              style={{ fontFamily: "arial" }}>
                              <i>
                                {" "}
                                {address.complete_address} <br />
                                {address.Street} {address.city} -{" "}
                                {address.country} <br />{" "}
                                {address.mobile_number_1} <br />{" "}
                                {address.contact_email}{" "}
                              </i>
                            </p>
                          </td>
                          <td
                            className="text-left align-top"
                            style={{ width: "35%", textTransform: 'uppercase' }}>
                            <h5>{t("payment_method")}</h5>
                            {details.payment_method === "COD" ? (
                              <b>{t("cash_on_delivery")}</b>
                            ) : (
                              <b>{details.payment_method}</b>
                            )}
                          </td>
                          <td
                            className="text-left align-top"
                            style={{ width: "35%" }}>
                            <div className="align-right">
                              <h5>{t("order_summary")}</h5>
                              <table>
                                <tr>
                                  <td>{t("sub_total")}</td>
                                  <td>: {details.sub_total}</td>
                                </tr>
                                <tr>
                                  <td>{t("shipping")}</td>
                                  <td>
                                    :{" "}
                                    {Number(details.shipping_charges)
                                      ? details.shipping_charges
                                      : "00.0"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("total")}</td>
                                  <td>
                                    :{" "}
                                    {Number(details.shipping_charges)
                                      ? details.shipping_charges +
                                        details.sub_total
                                      : details.sub_total}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("promotion_applied")}</td>
                                  <td>: {details.discounted_amount}</td>
                                </tr>
                                <tr>
                                  <th style={{ color: "#000" }}>{t("grand_total")}</th>
                                  <th>: {details.grand_total}</th>
                                </tr>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="order-summary">
                      <table className="table table-cart">
                        <thead>
                          <tr>
                            
                            <th className="product-col">{t(`product`)}</th>
                            <th className="price-col">{t(`price`)}</th>
                            <th className="qty-col">{t(`quantity`)}</th>
                            <th className="text-right">{t(`sub_total`)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((item) => {
                            return (
                              <tr className="product-row" key={item.order_item_id}>
                                
                                <td className="product-col text-left">
                                  <h5 className="product-title">
                                  {item.item_title} - {item.item_number} <br />
                                  <NavLink to={item.item_url}><button style={{padding: '0.2em 0.5em',fontSize: '1.1rem'}} className="btn btn-primary btn-icon-left btn-rounded btn-sm">
									<i className="fa fa-sync-alt"></i> {" "}{t(`buy_it_again`)}{" "}</button></NavLink>
                                  </h5>
                                </td>
                                <td>{item.item_display_price}</td>
                                <td>
                                  <div className="product-single-qty">
                                    <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                      <span className="input-group-btn input-group-prepend">
                                      {item.item_qty}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-right">
                                  <span className="subtotal-price">{item.item_qty * item.item_price}</span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myInvoice" style={{visibility: 'hidden'}}>
                                <Invoice order={this.state.orderInfo} />
                        </div>
      </main>
    );
  }
}
const handleDownloadPdf = async () => {
  // Create the HTML content as a string
  const content = `
    <div style="padding: 20px; background: #fff; width: 600px;">
      <h1>HTML to PDF in React</h1>
      <p>This content will be converted to a high-quality PDF.</p>
      <!-- Add more content or complex HTML structures here -->
    </div>
  `;

  // Create a temporary hidden div to hold the content
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.left = '-9999px';
  tempDiv.innerHTML = content;
  document.body.appendChild(tempDiv);

  // Convert the content to a canvas
  const canvas = await html2canvas(tempDiv, {
    scale: 2, // Increase the scale for better quality
    useCORS: true, // Allow cross-origin images
  });

  const data = canvas.toDataURL('image/png');

  const pdf = new jsPDF('p', 'mm', 'a4');
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
  pdf.save('download.pdf');

  // Clean up the temporary div
  document.body.removeChild(tempDiv);
};
export default withRouter(withTranslation()(OrderDetails));
