import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../Context/ConfigContext";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";

const CatelogModal = ({subs, title, STR_ID, modalStatus}) => {
    const { t } = useTranslation();
    const {categories, gdcURL} = useContext(ConfigContext);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoryName, setSelectedCategoryName] = useState("...");
    const [selectedCategoryID, setSelectedCategoryID] = useState(0); 
    const [loading, setLoading] = useState(false); 

    const navigate = useNavigate();
    useEffect(() => {
        setSelectedCategoryName(title);
        setSelectedCategories(subs);
        setSelectedCategoryID(STR_ID)
        setShowModal(modalStatus);
    }, [subs, title, STR_ID, modalStatus])  
    
    const GetSubMenu = ({parent, flag}) => {
        if(parent.children.length === 0){
          return <div className="row mb-3"> 
            <div className="col-md-12">
            <h5>{parent.STR_NODE_NAME}</h5>
            </div>
            <ItemTab item={parent} /> 
          </div>
        }
        return <div className="row mb-3">
          
          <div className="col-md-12">
            <h5  style={parent.children.length === 0 ? {color: 'blue', textDecoration: 'underline', cursor: 'pointer'}: {}} >{parent.STR_NODE_NAME}</h5>
          </div>
          {
            parent.children.length > 0 && parent.children.map((item) => {
              return <GetSubChilds childs={item} />
            })
          }
        </div>
      }
      const GetSubChilds = ({ childs }) => {
        if (childs.children && childs.children.length > 0) {
          
          return (
            <>
              {childs.children.map((item) => ( <ItemTab item={item} />
                
              ))}
            </>
          );
        } else {
          return ( <ItemTab item={childs} /> );
        }
      };
      const ItemTab = ({item}) => {
        return <div className="col-md-4 submenutabforsection" onClick={() => navigate(`/Spare-Parts/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}`)}>
              <table>
                <tbody>
                  <tr>
                    <td width={60}>
                    <img
                        src={`${gdcURL}/category-images/600x600/${item.STR_ID}`}
                        className="img-fluid"
                        alt={item.STR_NODE_NAME} 
                        style={{borderRadius: '5px'}}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = "https://placehold.co/40x40/E4E4E4/E4E4E4/png";
                        }}
                        />
                    </td>
                    <td>{item.STR_NODE_NAME}</td>
                  </tr>
                </tbody>
              </table>
            </div>
      }

      return (
        <div className={`modal fade ${showModal ? 'show d-block' : 'd-none'}`}id="subcatsModal" tabIndex="-1" role="dialog" aria-labelledby="subcatsModalLabel" aria-hidden="true" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="subcatsModalLabel">
                  
                <table><tr><td width={50}>
                <img
                        src={`${gdcURL}/category-images/600x600/${selectedCategoryID}`}
                        className="img-fluid"
                        alt={selectedCategoryName} 
                        style={{borderRadius: '5px'}}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = "https://placehold.co/40x40/E4E4E4/E4E4E4/png";
                        }}
                        />
                  </td><td>{selectedCategoryName}</td></tr></table>
                  </h3>
                <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{minHeight: '400px'}}>
                <div className="container" >
                  <div className="row">
                  {
                    selectedCategories.map((item) => {
                      return <div className="col-md-12"><GetSubMenu flag={true} parent={item} /></div>
                     
                    })
                  }
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      )
}

export default CatelogModal;