import { useContext, useEffect, useState } from "react"
import axios from "axios";
import { ConfigContext } from "../../Context/ConfigContext";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import slugify from "slugify";
const Offer4x4Section = () => {
   
    const {gdcURL} = useContext(ConfigContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
   
    return <section className="blog-section pb-0">
        <div className="container">
        <h2 className="section-title heading-border border-0" data-animation-name="fadeInUp" > {t('4x4_upgrade')} </h2>
        <div className="row justify-content-center">
        
        </div>
        </div>
    </section>
}

export default Offer4x4Section;