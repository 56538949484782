import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ConfigContext } from "../../Context/ConfigContext";
import { withTranslation } from 'react-i18next';
import {
  CartProcess,
  DeleteCartItem,
  GetCarts,
  QtyUpdate
} from "../../Helper/MyCart";
import {Spinner} from "../../Components/Shimmers";
class Cart extends Component {
  static contextType = ConfigContext;

  constructor(props) {
    super(props);
    this.state = {
      cartItems: [],
      subtotal: 0,
      coupon: localStorage.getItem("Coupon"),
      couponData: [],
      cartLoading : true,
      couponsLoading: true,
      shimmerCarItem : 'cart-item-',
    };
    this.MySwal = withReactContent(Swal);
  }
  componentDidMount() {
    
    this.setState({cartLoading: true, couponsLoading: true}, (prevState)=>{
      this.fetchCartDataFromLocalStorage();
      this.GetCoupons();
    });
    
  }
  fetchCartDataFromLocalStorage = async () => {
    const { apiURL,gdcURL, UpdateCartList, ChangeCartStatus } = this.context;
    const { coupon } = this.state;
    const { t, i18n } = this.props;
    if (coupon) {
      localStorage.setItem("Coupon", coupon);
    }
    try {
      var carts = GetCarts();
      
      if (carts && Array.isArray(carts) && carts.length > 0) {
        
        const response = await axios.get(`${gdcURL}/Orders/GetCart`, {
          params: {
            currency: localStorage.getItem('currency')??'aed',
            lang: this.context.lang,
            customer_id: localStorage.getItem("customer_id") || 0,
            carts: JSON.stringify(carts),
            coupon: coupon,
          },
        });
        const data = response.data.data;

        if (data) {
        
          this.setState({cartLoading: false, couponsLoading: false, cartItems: data, coupon: coupon, shimmerCarItem : 'cart-item-'}, (prevState)=>{
            // CartProcess(data.items);
            // UpdateCartList(data);
            // ChangeCartStatus(true)
           
          });
        } else {
          console.error(data.message);
        }
       
      }else{
        this.setState({cartLoading: false, couponsLoading: false, shimmerCarItem : 'cart-item-', cartItems: []})
        // ChangeCartStatus(false);
      }
    } catch (err) {
      // console.log("LoadCart err", err);
    }
  };
  GetCoupons = async () => {
    // const couponData = this.state;
    const { apiURL } = this.context;
    try {
      const response = await axios.get(`${apiURL}/Home/GetCoupons?lang=en`);
      // console.log("response", response);
      const data = response.data.data;
     
      if (data) {
        this.setState({ couponData: data });
      } else {
        console.log("No Coupons Found");
      }
    } catch (err) {
      console.log("err Something Went Wrong", err);
    }
  };
  handleCouponCodeChange = (e) => {
    this.setState({ coupon: e.target.value });
  };

  handleDeleteCartItem = async (index) => {
    const { UpdateCartList } = this.context;
    const result = await this.MySwal.fire({
      title: "Remove Item",
      text: "Are you sure you want to remove this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes Delete",
      cancelButtonText: "Cancel!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      DeleteCartItem(index);
      // console.log('tstcart', GetCarts());
      UpdateCartList(GetCarts());
      this.fetchCartDataFromLocalStorage();
      this.setState({shimmerCarItem: 'cart-item-'});
      toast.success("Item Removed successfully!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      this.setState({shimmerCarItem: 'cart-item-'});
      return;
    }
  };

  updateLocalStorageCartItem = (item) => {
    // UpdateQty(item.part_id, item.part_qty, item.part_price);
  };

  handleQuantityChange = async (index, qty) => {
    const { UpdateCartList } = this.context;
    await QtyUpdate(index, qty);
      // UpdateCartList(GetCarts());
      this.fetchCartDataFromLocalStorage();
      this.setState({shimmerCarItem: 'cart-item-'});
    
  };
  RemoveCoupon = () => {
    localStorage.removeItem("Coupon");
    this.setState(
      {
        coupon: "",
      },
      () => {
        this.setState({cartLoading: true, coupon: ''}, (prevState)=>{
          localStorage.setItem("Coupon", '');
          this.fetchCartDataFromLocalStorage();
        });
       
      }
    );
  };
  ApplyCoupon = async (couponCode, e) => {
    e.preventDefault();
    localStorage.setItem("Coupon", couponCode);
    this.setState({cartLoading: true, coupon: couponCode}, (prevState)=>{
      this.fetchCartDataFromLocalStorage();
    });
  };

  render() {
    const { cartItems, couponData } = this.state;
    const { CartStatus } = this.context;
    const { apiURL } = this.context;
    const { t } = this.props;
    const Cart = cartItems && cartItems.items && Array.isArray(cartItems.items) && cartItems.items.length > 0;
    // console.log("cartItems", cartItems.items)
    return (
      <main className="main">
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="dark"
          closeButton={false}
        />
        <div className="container">
          <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
            <li className="active">
              <NavLink to={"/Cart"}>{t("shopping_cart")}</NavLink>
            </li>
            <li>
              <NavLink to={"/Checkout"}>{t("checkout")}</NavLink>
            </li>
            <li className="disabled">
              <NavLink to={"OrderInformation"}>{t("complete_order")}</NavLink>
            </li>
          </ul>
          {this.state.cartLoading === true? <center><Spinner /></center> :
          cartItems.items && cartItems.items.length > 0 ? (
            <div className="row">
              <div className="col-lg-8">
                <div className="cart-table-container">
                <table className="table table-cart">
                    <thead>
                      <tr>
                        <th className="thumbnail-col" />
                        <th className="product-col">{t("product")}</th>
                        <th className="price-col">{t("price")}</th>
                        <th className="qty-col">{t("quantity")}</th>
                        <th className="text-right">{t("sub_total")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Cart && 
                      cartItems.items.map((item, index) => 
                      (
                          
                          <tr key={'cart-item-'+index} className="product-row">
                            <td>
                              <figure className="product-image-container">
                                <NavLink to={item.item_url} className="product-image" >
                                  <img src={item.item_img_url} alt={item.item_title}  onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = "https://placehold.co/40x40/E4E4E4/E4E4E4/png";
                        }}/>
                                </NavLink>
                                <NavLink onClick={() => this.setState({shimmerCarItem: 'cart-item-'+index}, (prevState)=>{ this.handleDeleteCartItem(index) }) } className="btn-remove icon-cancel" title="Remove Product" />
                              </figure>
                            </td>
                            <td className="product-col">
                              <h5 className="product-title">
                                <NavLink to={item.item_url}>
                                  {item.item_title} <br />
                                  <small>{item.item_number}</small>
                                </NavLink>
                              </h5>
                            </td>
                            <td>{item.display_price}</td>
                            <td>
                              <div className="product-single-qty">
                                <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-outline btn-down-icon bootstrap-touchspin-down"
                                      type="button"
                                      disabled={Number(item.item_qty) > 1?false: true}
                                      onClick={() =>  this.handleQuantityChange( index, (Number(item.item_qty)-1)) } 
                                    ></button>
                                  </span>
                                  <input className="horizontal-quantity form-control" type="text" value={item.item_qty} readOnly />
                                  <span className="input-group-btn input-group-prepend">
                                    <button
                                      className="btn btn-outline btn-up-icon bootstrap-touchspin-up btnPlus"
                                      type="button"
                                      onClick={() =>  this.handleQuantityChange( index, (Number(item.item_qty)+1)) } >
                                      +
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-right">
                              <span className="subtotal-price">
                                {item.total_display_price}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                      
                      }
                    </tbody>

                  
                  </table> 
                  
                </div>
              </div>

              <div className="col-lg-4">
                <div className="cart-summary">
                  <h3 className="">{"Order Summary"}</h3>
                  <div className="float-left">
                    <div className="cart-discount">
                      <form action="#">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder={t("coupon_code")}
                            value={this.state.coupon}
                            onChange={this.handleCouponCodeChange}
                            required
                            disabled={
                              localStorage.getItem("Coupon") ? true : false
                            }
                          />
                          {localStorage.getItem("Coupon") ? (
                            <div className="input-group-append">
                              <button
                                className="btn btn-sm btn-primary"
                                type="button"
                                onClick={this.RemoveCoupon}
                              >
                                {t("remove_coupon")}
                                
                              </button>
                            </div>
                          ) : (
                            <div className="input-group-append">
                              <button
                                className="btn btn-sm btn-primary"
                                type="button"
                                onClick={this.fetchCartDataFromLocalStorage}
                              >
                                {"ApplyCoupon"}
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                      <button
                        className="d-flex justify-content-between align-items-center w-100 p-2 mt-2"
                        data-toggle="modal"
                        data-target="#CouponModel"
                        style={{
                          border: "1px solid #e7e7e7",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/Files/Cart/copen.svg`}
                            alt=""
                          />
                          <span
                            className="ml-2"
                            style={{ color: "#e65904", fontWeight: "500" }}
                            type="button"
                          >
                            {t("view_available_offers")}
                            
                          </span>
                        </div>
                        <div style={{ color: "#e65904" }}>
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </button>
                      <div
                        className="modal fade"
                        id="CouponModel"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content w-75 ml-5">
                            <div className="modal-header">
                              <h5
                                className="modal-title mb-0"
                                style={{
                                  fontSize: "20px",
                                  color: "#404553",
                                  fontWeight: "medium",
                                }}
                                id="exampleModalLongTitle"
                              >
                                {t("available_offers")}
                              </h5>
                              <button
                                type="button"
                                className="close h3"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <h4>{t("coupon_offers")}</h4>
                              
                              { couponData && couponData.length > 0 ? (
                                couponData.map((item) => (
                                  <div
                                    className="main-Featured card"
                                    style={{
                                      background: "white",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <div
                                      className="card-header d-flex align-items-center"
                                      style={{
                                        background: "white",
                                        borderRadius: "10px",
                                        borderBottom: "1px solid #dddddd",
                                        borderBottomRightRadius: "0px",
                                        borderBottomLeftRadius: "0px",
                                      }}
                                    >
                                      <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/Files/Cart/coupon-discount.svg`}
                                        alt=""
                                        width={20}
                                        height={20}
                                      />{" "}
                                      <span className="ml-3">
                                        {item.coupon_title}
                                      </span>
                                    </div>
                                    <div className="p-4">
                                     
                                      <span>{item.coupon_description}</span>
                                    </div>
                                    <div className="d-flex justify-content-between  align-items-center">
                                      <h4
                                        className="text-success text-center alert-success ml-4"
                                        style={{
                                          padding: "2px 10px",
                                          border: "1px dashed rgb(40, 167, 69)",
                                        }}
                                      >
                                        {item.coupon_code}
                                      </h4>
                                      {localStorage.getItem("Coupon") === item.coupon_code ? (
                                        <button className="btn btn-success Apply mr-4 mb-1">
                                          {t("applied")}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-primary Apply mr-4 mb-1"
                                          data-dismiss="modal"
                                          onClick={(e) =>
                                            this.ApplyCoupon(
                                              item.coupon_code,
                                              e
                                            )
                                          }
                                        >
                                          {t("apply")}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <h1>{t("no_offers")}</h1>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Cart && (
                    <>
                      <table className="table table-totals">
                        <tbody>
                          <tr>
                            <th>{"Product"}</th>
                            <th className="custom-dark">{"Total"}</th>
                          </tr>
                          {Cart &&
                            // ? (
                            cartItems.items.map((item) => (
                              <tr key={item.item_id}>
                                <td className="custom-light">
                                  {item.item_title} x {item.item_qty}
                                </td>
                                <td className="float-right">
                                  {item.total_display_price}
                                </td>
                              </tr>
                            ))}

                          <tr>
                            <td>{t("sub_total")}</td>
                            <td className="custom-dark">
                              <span>{cartItems.sub_total_display}</span>
                            </td>
                          </tr>

                          {localStorage.getItem("Coupon") &&
                            cartItems.total_saving > 0 && (
                              <tr>
                                <td>{"Total Saving"}</td>
                                <td className="custom-dark">
                                  <span className="text-success">
                                    {" "}
                                    - {cartItems.total_saving}
                                  </span>
                                </td>
                              </tr>
                            )}
                          <tr>
                            <td colSpan={2} className="text-left">
                              <div className="d-flex justify-content-between">
                                <h4>{t("shipping")}</h4>
                                <span
                                  className="text-success alert-success"
                                  style={{
                                    padding: "1px 5px",
                                    border: "1px dashed",
                                  }}
                                >
                                  {t("free_shipping_all_over_nation")}
                                </span>
                              </div>
                            </td>
                          </tr>
                          {localStorage.getItem("Coupon") &&
                            cartItems.total_saving > 0 && (
                              <tr>
                                <td colSpan={2} className="text-left">
                                  <div className="d-flex justify-content-center">
                                    <h4
                                      className="text-success text-center alert-success"
                                      style={{
                                        padding: "2px 10px",
                                        border: "1px dashed #28a745",
                                      }}
                                    >
                                       
                                      {t("you_will_save",{value:cartItems.total_saving})}
                                    </h4>
                                  </div>
                                </td>
                              </tr>
                            )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>{t("grand_total")}</td>
                            <td className="custom-dark">
                              <span>
                                {cartItems.grand_total_display}
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <div className="checkout-methods">
                        <NavLink
                          to={
                            localStorage.getItem("token")
                              ? "/Checkout"
                              : "/Signup"
                          }
                          className="btn btn-block btn-dark"
                        >
                          {t("proceed_to_checkout")}
                          <i className="fa fa-arrow-right" />
                        </NavLink>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* End .col-lg-4 */}
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="cart-table-container  cart-summary">
                  <div className="d-flex justify-content-center">
                    <h5>
                    {t("your_cart_is_empty")}
                      <i className="minicart-icon"></i>
                    </h5>
                  </div>
                  <div className="checkout-methods d-flex justify-content-center">
                    <NavLink to={"/"} className="btn btn-dark btn-lg px-5">
                    {t("shop_now")}
                      <i className="fa fa-arrow-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {/* End .row */}
        </div>
        {/* End .container */}
        <div className="mb-6" />
        {/* margin */}
      </main>
    );
  }
}
export default withTranslation()(Cart);
