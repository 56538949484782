//////////// NOTES /////////////////
/**
 * part_uid = Parts Price ID (parts__prices.parts_price_id)
 * part_id = Parts ID (parts.part_id)
 * 
 * 
 * 
 */

exports.AddToCart = (part_id, part_uid, part_qty = 1, part_slug) => {
  
  var carts = localStorage.getItem('carts');
  
  
  if (carts !== "" && carts !== undefined && carts !== null) {
    
    carts = JSON.parse(carts);
    // Check if the cart item already exist
    const index = carts.findIndex(item => item.part_uid === part_uid);
    console.log("index from helper", index);
    if (index !== -1) {
      carts[index].part_qty = carts[index].part_qty + 1;
      localStorage.setItem('carts', JSON.stringify(carts));
      // console.log("in Update")
      
      return true;
    }else{
      carts.push({part_id:part_id, part_uid:part_uid, part_qty:part_qty, part_title: '', part_price:0, part_img:'', part_slug: part_slug});
      localStorage.setItem('carts', JSON.stringify(carts));
      return true;
    }
    
  } else { 
      carts = [];
      carts.push({part_id:part_id, part_uid:part_uid, part_qty:part_qty, part_title: '', part_price:0, part_img:''});
      localStorage.setItem('carts', JSON.stringify(carts));
      // console.log("in Fresh")
      return true;
  }
}
exports.CartProcess = async(carts) => {
  localStorage.setItem('carts', JSON.stringify(carts));
  return true;
  //part_uid, part_title, part_price, part_img
    
    // var carts = localStorage.getItem('carts');
    // carts = JSON.parse(carts);
    // const index = carts.findIndex(item => item.part_uid === part_uid);
    // carts[index].part_title = part_title;
    // carts[index].part_price = part_price;
    // carts[index].part_img = part_img;
    // localStorage.setItem('carts', JSON.stringify(carts));
    // return true;
  
}
exports.UpdateQty = (part_id, part_qty) => {

  var carts = this.GetCarts();
  
  const item = carts.find(cartItem => cartItem.part_id === part_id);
  
  if (item) {
    item.part_qty = part_qty;
    localStorage.setItem('carts', JSON.stringify(carts));
    return true;
  } else {
    return false;
  }
};

exports.DeleteCartItem = (index) => {
  var carts = localStorage.getItem('carts');
  console.log("carts",carts);
  carts = JSON.parse(carts);
  carts.splice(index, 1);
  localStorage.setItem('carts', JSON.stringify(carts));
  return true;
}



exports.GetCarts = () => {
    var carts = localStorage.getItem('carts');
    
    if(carts === "" || carts === null){
      return [];
    }
    
    carts = carts!==""?JSON.parse(carts):[];
    return carts;
}

