import React, { Component } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { NavLink, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AccountSiderBar from "./AccountSideBar";
import { Spinner } from "../../Components/Shimmers";

class AccountDetails extends Component {
  static contextType = ConfigContext;
  constructor(props) {
    super(props);
    this.state = {
      
      profile_loading: false,
      first_name: "",
      last_name: "",
      display_name: "",
      email_address: "",
      password: "",
      new_password: "",
      confirm_password: "",
      update_loading: false
    };
  }
  componentDidMount() {
    this.setState({ profile_loading: true }, (prevState) => {
      this.GetCustomerInfo();
    });
  }
  GetCustomerInfo = async () => {
    const { apiURL, token } = this.context;

    try {
      const customer_id = localStorage.getItem("customer_id");
      const headers = { token: token, Accept: "application/json", "Content-Type": "application/json", };

      const response = await axios.get(
        `${apiURL}/Account/GetCustomerInfo?customer_id=${customer_id}`,
        { headers }
      );

      const data = response.data;
      this.setState({ ordersLoading: false });
      if (data.success) {
        var info = data.data[0];

        this.setState({
          profile_loading: false,
          first_name: info.first_name,
          last_name: info.last_name,
          password: info.password,
          email_address: info.email_address,
          
        });
      } else {
        this.setState({ profile_loading: false });
        Swal.fire({
          title: 'Error!',
          text: data.message,
          icon: 'error',
          confirmButtonText: 'Okay'
        })
        console.log("Error While Fetching Customer Addresses", data.message);
      }
    } catch (error) {
      this.setState({ profile_loading: false });
      console.log("Oops Something Went Wrong", error);
    }
  };

  UpdateProfile = async() => {
    const { apiURL, token } = this.context;
    try {
      const customer_id = localStorage.getItem("customer_id");
      const headers = { token: token, Accept: "application/json", "Content-Type": "application/json", };
      var formData = {
        customer_id:customer_id,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        password: this.state.password,
        new_password: this.state.new_password,
        confirm_password: this.state.confirm_password
      }
      const response = await axios.post(
        `${apiURL}/Account/UpdateCustomerInfo`,formData, { headers }
      );

      const data = response.data;
      this.setState({ update_loading: false });
      if (data.success) {
        this.setState({ update_loading: false });
        Swal.fire({
        title: 'Successfully',
        text: data.message,
        icon: 'success',
        confirmButtonText: 'Okay'
      })
      } else {
        this.setState({ update_loading: false });
        Swal.fire({
          title: 'Error!',
          text: data.message,
          icon: 'error',
          confirmButtonText: 'Okay'
        })
        // console.log("Error While Fetching Customer Addresses", data.message);
      }
    } catch (error) {
      this.setState({ update_loading: false });
      Swal.fire({
        title: 'Error!',
        text: error,
        icon: 'error',
        confirmButtonText: 'Okay'
      })
      console.log("Oops Something Went Wrong", error);
    }
  }
  
  render() {
    const { update_loading, profile_loading } = this.state;
    return (
      <main>
        <div className="page-header">
          <div className="container d-flex flex-column align-items-center">
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <div className="container">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to={"/"}>{"Home"}</NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink to={() => {}}>{"Account Details"}</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {"Account Details"}
                  </li>
                </ol>
              </div>
            </nav>
            <h1>{"Account Details"}</h1>
          </div>
        </div>
        <div className="container account-container custom-account-container">
          <div className="row">
            <AccountSiderBar />
            <div className="col-lg-9 order-lg-last order-1 tab-content">
              <div
                className="tab-pane fade show active"
                id="address"
                role="tabpanel">
                <div className="order-content">
                  <h3 className="account-sub-title d-none d-md-block">
                    <i className="sicon-user align-middle mr-3" />
                    {"Account Details"}
                  </h3>
                  <div className="order-table-container">
                    <form action="#">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="acc-name">
                              {"First name"} <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={this.state.first_name}
                              id="acc-name"
                              name="acc-name"
                              value={this.state.first_name}
                              onChange={(e) =>
                                this.setState({ first_name: e.target.value })
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="acc-lastname">
                              {"Last name"} <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="acc-lastname"
                              name="acc-lastname"
                              value={this.state.last_name}
                              onChange={(e) =>
                                this.setState({ last_name: e.target.value })
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor="acc-text">
                          {"Display name"} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="acc-text"
                          name="acc-text"
                          readOnly
                          placeholder={`${this.state.first_name} ${this.state.last_name} `}
                          defaultValue={`${this.state.first_name} ${this.state.last_name} `}
                          required
                        />
                        <p>
                          {
                            "This will be how your name will be displayed in the account section and in reviews"
                          }
                        </p>
                      </div>
                      <div className="form-group mb-4">
                        <label htmlFor="acc-email">
                          {"Email address"} <span className="required">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="acc-email"
                          name="acc-email"
                          placeholder={this.state.email_address}
                          defaultValue={this.state.email_address}
                          required
                        />
                      </div>
                      <div className="change-password">
                        <h3 className="text-uppercase mb-2">
                          {"Password Change"}
                        </h3>
                        <div className="form-group">
                          <label htmlFor="acc-password">
                            {
                              "Current Password (leave blank to leave unchanged)"
                            }
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="acc-password"
                            name="acc-password" 
                            defaultValue={this.state.password}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="acc-password">
                            {"New Password (leave blank to leave unchanged)"}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="acc-new-password"
                            name="acc-new-password" 
                            value={this.state.new_password}
                            onChange={(e) =>
                              this.setState({ new_password: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="acc-password">
                            {"Confirm New Password"}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="acc-confirm-password"
                            name="acc-confirm-password" 
                            value={this.state.confirm_password}
                            onChange={(e) =>
                              this.setState({ confirm_password: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-footer mt-3 mb-0">
                      {update_loading === true?
                        <button type="button" className="btn btn-default mr-0">
                        {"Saving changes..."}
                      </button>
                      :
                        <button type="button"  onClick={() => this.setState({update_loading: true}, (prevState)=>this.UpdateProfile())} className="btn btn-primary mr-0">
                          {"Save changes"}
                        </button>
                        }
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default AccountDetails;
