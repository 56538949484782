import React from 'react'
import { NavLink } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const Footer = () =>{
  const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    return (

      <footer className="footer darkColor">
        <ToastContainer position="bottom-right"/>
      <div className="footer-middle" dir={dir} style={{textAlign: dir==='rtl'?'right': 'left'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="widget">
                <h4 className="widget-title">{t('contact_info')}</h4>
                <ul className="contact-info">
                  <li>
                    <span className="contact-info-label">{('Carz7')}{('E-Commerce')}</span>
                    {/* {('FooterPara1')} */}
                    is a limited liability company (LLC) registered in the Emirate of Dubai in the UAE, a member of Dubai SME No. (8258), and a member of the National Program No. (DUB003385).
                  </li>
                 <div className="row mb-0">
                  <li className="col-md-6">
                      <span className="contact-info-label">{t('phone')}</span>
                      <NavLink to="tel:">
                        {('+971800442522')}
                      </NavLink>
                    </li>
                    <li className="col-md-6">
                      <span className="contact-info-label">{t('email')}</span> <NavLink to="https://car partstheme.com/cdn-cgi/l/email-protection#1e737f77725e7b667f736e727b307d7173"><span className="__cf_email__" data-cfemail="0469656d6844617c65697468612a676b69">contact@ghayar.ae</span></NavLink>
                    </li>
                 </div>
                 <div className="row">
                  <li className="col-md-6">
                    <span className="contact-info-label">{t('our_locations')}</span> 
                    <p style={{ textAlign : "justify" }}>
                    {/* {('FooterPara2')} */}
                      Address Head Office: Street 5,AJD building,showroom 6 - Umm Ramool - Dubai - United Arab Emirates Address Al Quoz Branch: Showroom No.8, 26th St - Al Quoz Industrial Area 4 - Dubai
                    </p>
                  </li>
                  <li className="col-md-6">
                    <span className="contact-info-label">{t('working_hours')}</span> {('8:00AM-7:00 PM')}
                  </li>
                  </div>
                </ul>
                <div className="social-icons">
                <NavLink to={`#`} className="social-icon social-youtube icon-youtube" target="_blank" rel="noreferrer" title="Twitter">
                  <FacebookIcon />
                </NavLink>
                <NavLink to={`#`} className="social-icon social-youtube icon-youtube" target="_blank" rel="noreferrer" title="Twitter">
                  <YouTubeIcon />
                </NavLink>
                <NavLink to={`#`} className="social-icon social-youtube icon-youtube" target="_blank" rel="noreferrer" title="Twitter">
                  <InstagramIcon />
                </NavLink>
                <NavLink to={`#`} className="social-icon social-youtube icon-youtube" target="_blank" rel="noreferrer" title="Twitter">
                  <XIcon />
                </NavLink>
                  
                </div>
                {/* End .social-icons */}
              </div>
              {/* End .widget */}
            </div>
            {/* End .col-lg-2.5 */}
            <div className="col-lg-3 col-sm-6">
              <div className="widget">
                <h4 className="widget-title">{t("customer_service")}</h4>
                <ul className="links">
                  <li><NavLink to={`#`}>{t("help_and_faqs")}</NavLink></li>
                  <li><NavLink to={`#`}>{t('privacy')}</NavLink></li>
                  <li><NavLink to={`#`}>{t("delivery_shipment")}</NavLink></li>
                  <li><NavLink to={`#`}>{t("cancelation_return")}</NavLink></li>
                  <li><NavLink to={`#`}>{t("warranty_coverage")}</NavLink></li>
                  <li><NavLink to={`#`}>{t("international_customers")}</NavLink></li>
                  <li><NavLink to={`#`}>{t("contact_us")}</NavLink></li>
                </ul>
              </div>
              {/* End .widget */}
            </div>
            {/* End .col-lg-2.5 */}
            <div className="col-lg-2 col-sm-6">
              <div className="widget">
                <h4 className="widget-title">{t("for_users")}</h4>
                <ul>

                  <li><NavLink to={`#`}>{t("login")}</NavLink></li>
                  <li><NavLink to={`#`}>{t('create_account')}</NavLink></li>
                  <li><NavLink to={`#`}>{t("my_account")}</NavLink></li>
                  <li><NavLink to={`#`}>{t("my_orders")}</NavLink></li>
                  <li><NavLink to={`#`}>{t("profile")}</NavLink></li>
                </ul>
                  
              </div>
              {/* End .widget */}
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="widget">
                <h4 className="widget-title">{t("about_us")}</h4>
                <ul>

                <li><NavLink to={`#`}>{t("company_info")}</NavLink></li>
                  <li><NavLink to={`#`}>{t('easy_way_to_shop')}</NavLink></li>
                  <li><NavLink to={`#`}>{t("safe_shopping")}</NavLink></li>
                  <li><NavLink to={`#`}>{t("car_parts_online")}</NavLink></li>
                  <li><NavLink to={`#`}>{t("attractive_price")}</NavLink></li>
                  <li><NavLink to={`#`}>{t("brands")}</NavLink></li>
                  <li><NavLink to={`#`}>{t("careers")}</NavLink></li>
                </ul>
                  
              </div>
              {/* End .widget */}
            </div>
            {/* End .col-lg-2.5 */}
            <div className="col-lg-2 col-sm-6">
              <div className="widget widget-newsletter">
                <h4 className="widget-title ml-1">{t("our_app")}</h4>
                <ul>
                  <li style={{ paddingBottom : '15px' }}>
                    <img src="assets/images/Files/Our_Apps/apple-store.png" alt={"AppleStore"}  />
                  </li>
                  <li>
                    <img src="assets/images/Files/Our_Apps/google-play.png" alt={"GoogleStore"}  />
                  </li>
                </ul>
              </div>
              {/* End .widget */}
            </div>
            {/* End .col-lg-2.5 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      {/* End .footer-middle */}
      <div className="container">
        <div className="footer-bottom">
          <div className="container d-sm-flex align-items-center">
            <div className="footer-left">
              <span className="footer-copyright">&copy;CARZ7 all rights reserved, Powered by Ghayar LLC</span>
            </div>
            <div className="footer-right ml-auto mt-1 mt-sm-0">
              <div className="payment-icons">
                <span className="payment-icon visa" style={{backgroundImage: 'url(assets/images/payments/payment-visa.svg)'}} />
                <span className="payment-icon paypal" style={{backgroundImage: 'url(assets/images/payments/payment-paypal.svg)'}} />
                <span className="payment-icon stripe" style={{backgroundImage: 'url(assets/images/payments/payment-stripe.png)'}} />
                <span className="payment-icon verisign" style={{backgroundImage: 'url(assets/images/payments/payment-verisign.svg)'}} />
              </div>
            </div>
          </div>
        </div>
        {/* End .footer-bottom */}
      </div>
      {/* End .container */}
    </footer>
      );
}

export default Footer;