import { Component } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import Swal from "sweetalert2";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Spinner } from "../../Components/Shimmers";
import { withTranslation } from 'react-i18next';
import { Rating } from "@mui/material";
import '@smastrom/react-rating/style.css'
class PartReview extends Component{
    static contextType = ConfigContext;
    constructor(props){
        super(props);
        this.state = {
            review_text: '',
            rating: 0,
            reviews:[],
            loading: false
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
        this.setState({ loading: true}, (prevState) => {
            this.LoadReviews();
        })
    }
    LoadReviews = async() => {
        const { apiURL, token } = this.context;
    
        try {
        const { part_number} = this.props;
          const headers = { token: token, Accept: "application/json", "Content-Type": "application/json", };
    
          const response = await axios.get(
            `${apiURL}/Account/GetReviewByPart?part_number=${part_number}`,
            { headers }
          );
    
          const data = response.data;
            console.log("reviews data", data);
          if (data.success) {
            this.setState({ reviews: data.data , loading: false});
          } else {
            console.log("Error While Fetching Customer reviews", data.message);
          }
          this.setState({  loading: false});
        } catch (error) {
            this.setState({  loading: false});
        }
    }
    PostReview = async() => {
        const { apiURL, token } = this.context;
        const {part_id, part_uid, part_number} = this.props;
        const headers = { token: token, Accept: "application/json", "Content-Type": "application/json", };
        var postData = {
            customer_id: localStorage.getItem('customer_id'),
            part_number: part_number,
            part_id: part_id,
            part_uid: part_uid,
            review_text: this.state.review_text,
            rating: this.state.rating
        }
        var response = await axios.post(`${apiURL}/Account/AddReview`, postData, { headers, });
        if(response.data.success){
            this.setState({review_text: '', loading: true}, (prevState) => {
                this.LoadReviews();
            })
            
          Swal.fire({
              title: 'Successfully',
              text: response.data.message,
              icon: 'success',
              confirmButtonText: 'Okay'
            })
            
        }else{
          Swal.fire({
              title: 'Error',
              text: response.data.message,
              icon: 'error',
              confirmButtonText: 'Okay'
            })
        }
    }
    handleChange(event) {
        this.setState({ rating: event.target.value });
      }
    render() {
        const {reviews, loading} = this.state;
        const {part_title} = this.props;
        const { t } = this.props;
        const dir = localStorage.getItem('dir');
        return (
            <div className="product-reviews-content" > <br />
            {
                reviews.length !== 0?
                <div>
                    <h3 className="reviews-title">{reviews.length} review for {part_title}</h3>

                    <div className="comment-list">
                        {loading === true?<center><Spinner /></center>:
                            reviews.length === 0? <p className="mb-5 mt-5"> No Order has been made yet. </p>: 
                            
                                reviews.map((review) => {
                                    return <div className="comments">
                                        <figure className="img-thumbnail">
                                            {/* <i className="icon-user-2"></i> */}
                                            <img src="/assets/images/profile.png" alt="customer" width="80" height="80" />
                                        </figure>
                                        <div className="comment-block">
                                        <div className="comment-header">
                                            <div className="comment-arrow"></div>

                                            <div className="ratings-container float-sm-right">
                                                <div className="product-ratings">
                                                    <span className="ratings" style={{width:'60%'}}></span>
                                                    <span className="tooltiptext tooltip-top"></span>
                                                </div>
                                            </div>

                                            <span className="comment-by">
                                                <strong>{review.first_name} {review.last_name}</strong> – {review.review_datetime}
                                            </span>
                                        </div>

                                        <div className="comment-content">
                                            <p>{review.review_text}</p>
                                        </div>
                                        </div>
                                        <div className="divider"></div>
                                    </div>
                                    
                                })
                            
                        }
                        
                    </div>
                </div>:<div></div>
            }
                

                <div className="divider"></div>
                {
                    !localStorage.getItem('customer_id')? <center><NavLink to={`/Signup`} className={`nav-link`}>You must login before reviwing</NavLink></center>:
                
                    <div className="add-product-review" >
                        <h3 className="review-title" style={{textAlign: dir==='rtl'?'right': 'left'}}>{t('add_review')}</h3>

                        <form action="#" className="comment-form m-0" >
                            <div className="rating-form col-sm-1" style={{float: dir==='rtl'?'right': 'left'}}>
                                <label htmlFor="rating">{t('your_rating')}</label>
                                <Rating onChange={this.handleChange} value={this.state.rating} />

                                
                            </div>

                            <div className="form-group ">
                               
                                <textarea dir={dir} cols="5" rows="6" className="form-control form-control-sm" onChange={(e)=>this.setState({review_text: e.target.value})} placeholder={t('your_review')}>{this.state.review_text}</textarea>
                            </div>

                            <input style={{float: dir==='rtl'?'right': 'left'}} type="button" onClick={() => this.PostReview()} disabled={this.state.review_text !== ''? false: true} className="btn btn-primary" value={t('submit')} />
                        </form>
                    </div>
                }
                
            </div>
        );
    }
}

export default withTranslation()(PartReview);