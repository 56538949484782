import React, {Component} from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { NavLink, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AccountSiderBar from "./AccountSideBar";
import { Spinner } from "../../Components/Shimmers";
import { AddressCard } from "../../Components/Cards/MyAccountComponents";
import { withTranslation } from 'react-i18next';
class Addresses extends Component{
    static contextType = ConfigContext;
    constructor(props, context){
        super(props,context);
        this.state = {
            customers_addresses: []
        };
        
    }
    componentDidMount(){
        this.GetAddresses();
    }
     
    GetAddresses = async () => {

        const { apiURL, token } = this.context;
    
        try {
          const customer_id = localStorage.getItem("customer_id");
          const headers = { token: token, Accept: "application/json", "Content-Type": "application/json", };
    
          const response = await axios.get(
            `${apiURL}/Account/GetAddresses?customer_id=${customer_id}`,
            { headers }
          );
    
          const data = response.data;
         
          if (data.success) {
            this.setState({ customers_addresses: data.data });
          } else {
            console.log("Error While Fetching Customer Addresses", data.message);
          }
        } catch (error) {
          console.log("Oops Something Went Wrong", error);
        }
    }
   
    render() {
        const {customers_addresses} = this.state;
        const { t } = this.props;
        const dir = localStorage.getItem('dir');
        return <main>
            <div className="page-header">
              <div className="container d-flex flex-column align-items-center">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                  <div className="container">
                    <ol className="breadcrumb" dir={dir}>
                      <li className="breadcrumb-item">
                        <NavLink to={'/'}>{t("home")}</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink to={() => {}}>{t("my_account")}</NavLink>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        {t("addresses")}
                      </li>
                    </ol>
                  </div>
                </nav>
                <h1>{t("addresses")}</h1>
              </div>
            </div>
            <div className="container account-container custom-account-container">
              <div className="row">
                <AccountSiderBar />
                <div className={`col-lg-9 order-lg-last order-1 tab-content ${dir==='rtl'?'text-right': ''}`} >
                    <div className="tab-pane fade show active" id="address" role="tabpanel">
                        <div className="addresses-content">
                            <div className="address">
                                <div className="card">
                                    <div className="card-body" >
                                        <p className="card-text">
                                            {t( "manage_address_line" )}
                                        </p>
                                        <NavLink to={`/MyAccount/ManageAddress`} className="btn btn-default address-action link-to-tab" style={{ textDecoration: "none", background : '#e65904', color : "white" }} onClick={this.ClearFormFields} > <i className="sicon-plus align-middle mr-3"></i> {t("add_new_address")} </NavLink>
                                    </div>
                                </div>
                            </div>
  
                            <div className="address">
                                <div className="card">
                                    <div className="card-header">
                                        <i className="sicon-location-pin align-middle mr-3" /> {t("other_address")}
                                    </div>
                                    <div className="card-body">
                                        <div className="container-fluid">
                                            <div className="row">
                                            { customers_addresses.map((item) => {
                                                return <div className="col-sm-4" key={`address-key-${item.address_id}`} ><AddressCard onDataChanged={this.GetAddresses} item={item}/></div>
                                            })  }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </main>;
    
    }
    
}
export default withTranslation()(Addresses);