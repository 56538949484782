import React , { Component, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

const SearchBar = () =>{
   
      const { t } = useTranslation();
      const [keyword, setKeyword] = useState('');
      const navigate = useNavigate();
      const modalRef = useRef(null);
      const SearchNow = () => {
        navigate(`/Search-Parts/${btoa(keyword)}`)
      }
      const showModal = () => {
        const modal = new window.bootstrap.Modal(modalRef.current);
        modal.show();
      };
      const lang = localStorage.getItem('lang');
      const dir = localStorage.getItem('dir');
      return (
        <>
        <div>
        </div>
        <div className="modal fade" style={{textAlign: lang === 'ar'?'right': 'left'}} dir={dir} ref={modalRef} id="searchExampleModal" tabIndex={-1} role="dialog" aria-labelledby="searchExampleModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="searchExampleModalTitle"></h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {
                  lang === 'ar'?
                  <><p style={{ fontSize: 'x-large' }}>5,800,000 قطعة غيار – ابحث عن الجزء المناسب لسيارتك <br />
                        ابحث عن قطع الغيار باستخدام التركيبات التالية:</p><table className='table' style={{ fontSize: 'medium' }}>
                          <tbody>
                            <tr><th>نوع البحث</th><th>مثال</th></tr>
                            <tr><td>قطعة غيار</td><td>شمعات الاحتراق</td></tr>
                            <tr><td>قطعة غيار + مصنع القطعة</td><td>شمعات الاحتراق RIDEX</td></tr>
                            <tr><td>قطعة غيار + ماركة السيارة</td><td>شمعات الاحتراق CITROËN</td></tr>
                            <tr><td>قطعة غيار + رقم الصنف</td><td>شمعات الاحتراق 686S0071</td></tr>
                            <tr><td>رقم الصنف</td><td>13447</td></tr>
                            <tr><td>رقم الصنف + مصنع القطعة</td><td>SKSP-1990029 STARK</td></tr>
                            <tr><td>رقم المعدات الأصلي (OEN)</td><td>101000003AA</td></tr>
                            <tr><td>رقم المعدات الأصلي + مصنع القطعة</td><td>101000004AA FEBI BILSTEIN</td></tr>
                          </tbody>
                        </table></>
                  :<><p style={{ fontSize: 'x-large' }}>5,800,000 auto parts – find the right spare part for your car <br />
                        Search for spare parts using the following combinations:</p><table className='table' style={{ fontSize: 'medium' }}>
                          <tbody>
                            <tr><th>Search type</th><th>Example</th></tr>
                            <tr><td>Car part</td><td>Spark plugs</td></tr>
                            <tr><td>Car part + car part manufacturer</td><td>Spark plugs RIDEX</td></tr>
                            <tr><td>Car part + car brand</td><td>Spark plugs CITROËN</td></tr>
                            <tr><td>Car part + item number</td><td>Spark plugs 686S0071</td></tr>
                            <tr><td>Item number</td><td>13447</td></tr>
                            <tr><td>Item number + car part manufacturer</td><td>SKSP-1990029 STARK</td></tr>
                            <tr><td>OEN (original equipment number)</td><td>101000003AA</td></tr>
                            <tr><td>OEN + car part manufacturer</td><td>101000004AA FEBI BILSTEIN</td></tr>
                          </tbody>
                        </table></>
                }
              </div>
              
            </div>
          </div>
        </div>
        <div style={{direction: dir}} className="header-icon header-search header-search-inline header-search-category w-lg-max text-right mt-0">
          <div className="input-group">
            <input type="text" className="form-control" style={{borderRadius: '0px',}} placeholder={t("enter_part_number_name")} aria-label={t("enter_part_number_name")} aria-describedby="basic-addon2" />
            <div className="input-group-append">
            <span  style={{backgroundColor: '#f4f4f4',borderTop: '#f4f4f4',borderBottom: '#f4f4f4',borderRight: '#f4f4f4', color: '#a8a8a8', cursor: 'pointer'}} className="input-group-text" onClick={() => showModal()} >{t('example')} {" \u00A0  "}{" "} <i style={{fontSize: '12px'}} className={`fas fa-info-circle`}></i></span>
              <button style={{paddingTop: 'unset', paddingBottom: 'unset', borderRadius: '0px'}} className="btn btn-primary" type="button">{t("search")} </button>
            </div>
          </div>
        </div>
        
        </>

        
      );
        return(
            <div style={{direction: localStorage.getItem('dir')}} className="header-icon header-search header-search-inline header-search-category w-lg-max text-right mt-0">
              
              <NavLink to={() => {}} className="search-toggle" role="button"><i className="icon-search-3" /></NavLink>
              <form action="#" method="get">
                <div className="header-search-wrapper w-100">
                  <input type="search" className="form-control text-end" name="q" id="q" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder={`${t("search")} ...`} style={{borderRadius: 0}} required />
               
                  {/* End .select-custom */}
                  <button className="btn icon-magnifier p-0" title={t("search")} type="button" onClick={() => SearchNow()} style={{borderRadius: 0}}/>
                </div>
                {/* End .header-search-wrapper */}
              </form>
            </div>
        );
    
}

export default SearchBar;