import React, {Component} from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { NavLink, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";


class MyAccount extends Component {
  static contextType = ConfigContext;
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      customers_addresses: [],
      customer_id: localStorage.getItem("customer_id"),
      isEditing: false,
      selectedAddress: null,
      addingNewAddress: false,
      countries: [],
      formData: {
        first_name: "",
        last_name: "",
        company_name: "",
        country: "",
        complete_address: "",
        apartment: "",
        city: "",
        state: "",
        pincode: "",
        mobile_number_1: "",
        mobile_number_2: "",
        contact_email: "",
        selected: false, // Set initial value to false
        country_code: "",
        country_id: "",
        address_lat: "",
        address_lng: "",
      },
      
    };

    this.MySwal = withReactContent(Swal);
  }

  forceUpdateHandler = () => {
    this.forceUpdate();
  };
  componentDidMount() {
    
    this.getCustomerAddresses();
    this.fetchCountries();
    const { isEditing, selectedAddress } = this.props;

    if (isEditing && selectedAddress) {
      this.setFormData({
        first_name: selectedAddress.first_name,
        last_name: selectedAddress.last_name,
        company_name: selectedAddress.company_name,
        complete_address: selectedAddress.complete_address,
        apartment: selectedAddress.apartment,
        pincode: selectedAddress.pincode,
        city: selectedAddress.city,
        state: selectedAddress.state,
        country: selectedAddress.country,
        country_code: selectedAddress.country_code,
        country_id: selectedAddress.country_id,
        address_lat: selectedAddress.address_lat,
        address_lng: selectedAddress.address_lng,
        mobile_number_1: selectedAddress.mobile_number_1,
        mobile_number_2: 123456789,
        contact_email: selectedAddress.contact_email,
        selected: selectedAddress.selected === 1, // Convert 1 to true, 0 to false
      });
    }
  }

  getCustomerAddresses = async () => {

    const { apiURL } = this.context;

    try {
      const customer_id = localStorage.getItem("customer_id");
      const { token } = this.context
      const headers = {
        token: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `${apiURL}/Account/GetAddresses?customer_id=${customer_id}`,
        { headers }
      );

      const data = response.data;

      if (data.success) {
        this.setState({ customers_addresses: data.data });
      } else {
        console.log("Error While Fetching Customer Addresses", data.message);
      }
    } catch (error) {
      console.log("Oops Something Went Wrong", error);
    }
  };

  RemoveCustomerAddress = async (customer_id, address_id) => {
    const { apiURL, token } = this.context;
    try {
      const headers = {
        token: token,
        Accept: "application/json",
        "content-type": "application/json",
      };
      const customers_address_info = {
        customer_id,
        address_id,
      };
      const result = await this.MySwal.fire({
        title: "Confirm Deletion",
        text: "Are you sure you want to delete this address?!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete",
        cancelButtonText: "Cancel!",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        const { data } = await axios.post(
          `${apiURL}/Account/DeleteAddress?customer_id=${customer_id}&address_id=${address_id}`,
          customers_address_info,
          { headers }
        );

        if (data.success) {
          this.forceUpdate();
          this.getCustomerAddresses();
          // Show success toast
          toast.success("Successfully removed", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      } else {
        return;
      }
    } catch (error) {
      toast.error("Oops! Something went wrong.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };
  fetchCountries = async () => {
    const { apiURL } = this.context;
    try {
      const response = await axios.get(`${apiURL}/Home/GetCountries`);
      const data = response.data;
      
      if (data) {
        this.setState({ countries: data.data });
      } else {
        console.log("No Countries Found", response.data.message);
      }
    } catch (err) {
      console.log("Something Went Wrong", err);
    }
  };
 
  setFormData = (formData) => {
    this.setState({ formData });
  };

  handleChange = (e) => {
    const { formData, countries } = this.state;
    const { name, type, value, checked } = e.target;
  
    // If changing the country, find the corresponding country details
    const countryValue = name === "country" ? value : formData.country;
    const currentCountry = countries.find(country => country.country === countryValue);
  
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: type === "checkbox" ? checked : value,
        country_id: currentCountry ? currentCountry.country_id : prevState.formData.country_id,
        country_code: currentCountry ? currentCountry.country_alpha1_code : prevState.formData.country_code,
        country: countryValue,
      },
    }));
  };
  
  
  AddUpdateCustomerAddress = async (e) => {
    e.preventDefault();

    const { isEditing } = this.state;
    console.log("isEditing", isEditing);
    const { apiURL, token } = this.context;

    try {
      const headers = {
        token: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      console.log("localStorage.getItem('customer_id')", localStorage.getItem('customer_id'));
      const addressData = {
        customer_id: localStorage.getItem('customer_id'),
        first_name: this.state.formData.first_name,
        last_name: this.state.formData.last_name,
        company_name: this.state.formData.company_name,
        complete_address: this.state.formData.complete_address,
        apartment: this.state.formData.apartment,
        pincode: this.state.formData.pincode,
        city: this.state.formData.city,
        state: this.state.formData.state,
        country: this.state.formData.country,
        country_code: this.state.formData.country_code,
        country_id: this.state.formData.country_id,
        address_lat: "address_lat",
        address_lng: "address_lng",
        mobile_number_1: this.state.formData.mobile_number_1,
        mobile_number_2: 123456789,
        contact_email: this.state.formData.contact_email,
        selected: this.state.formData.selected ? 1 : 0, // Convert true to 1, false to 0
        address_id: isEditing ? this.state.formData.address_id : undefined,
      };

      console.log("addressData -1 ",addressData);

      const response = isEditing
        ? await axios.post(`${apiURL}/Account/AddAddress`, addressData, {
            headers,
          })
        : await axios.post(`${apiURL}/Account/AddAddress`, addressData, {
            headers,
          });

          console.log("response - 2", response);
          
          if (response.data.success) {
        console.log("response - 3", response.data);
        this.getCustomerAddresses();
        toast.success(response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        console.log("response - 4", response.data);
        toast.error(response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        errorMessage: "An error occurred Adding/Updating Address.",
        successMessage: "",
      });
    }
  };
  EditCustomerAddress = (address) => {
    console.log("item", address);
    this.setState({
      isEditing: true,
      formData: address,
    });
  };
  Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email_address");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("mobile_number");
    localStorage.removeItem("first_name");
    localStorage.removeItem("mobile_number");

    Navigate("/Signup");
    // You might want to navigate to the login page after logout
  };
  clearFormFields = () => {
    // Clear the form fields by resetting the formData state
    this.setFormData({
      first_name: "",
      last_name: "",
      mobile_number_1: "",
      contact_email: "",
      company_name: "",
      country: "", // Set to the default or initial value
      complete_address: "",
      apartment: "",
      city: "",
      state: "",
      pincode: "",
      selected: false,
    });

    this.setAddingNewAddress(true);
  };
    render(){
        const {
            customers_addresses,
            formData,
            countries,
            errorMessage,
            successMessage,
            selectedAddress,
          } = this.state;

        return(
          <>
          <div>
            <ToastContainer
              position="bottom-center" // Change the position to bottom-center
              autoClose={2500}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              theme="dark"
              closeButton={false}
            />
          </div>
          <main className="main">
            <div className="page-header">
              <div className="container d-flex flex-column align-items-center">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                  <div className="container">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to={'/'}>{("Home")}</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <a href={() => {}}>{("Shop")}</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        {("My Account")}
                      </li>
                    </ol>
                  </div>
                </nav>
                <h1>{("My Account")}</h1>
              </div>
            </div>
  
            <div className="container account-container custom-account-container">
              <div className="row">
                <div className="sidebar widget widget-dashboard mb-lg-0 mb-3 col-lg-3 order-0">
                  <h2 className="text-uppercase">{("My Account")}</h2>
                  <ul
                    className="nav nav-tabs list flex-column mb-0"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="dashboard-tab"
                        data-toggle="tab"
                        href="#dashboard"
                        role="tab"
                        aria-controls="dashboard"
                        aria-selected="true"
                      >
                        {("Dashboard")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="order-tab"
                        data-toggle="tab"
                        href="#order"
                        role="tab"
                        aria-controls="order"
                        aria-selected="true"
                      >
                        {("Orders")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="download-tab"
                        data-toggle="tab"
                        href="#download"
                        role="tab"
                        aria-controls="download"
                        aria-selected="false"
                      >
                        {("Downloads")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="address-tab"
                        data-toggle="tab"
                        href="#address"
                        role="tab"
                        aria-controls="address"
                        aria-selected="false"
                      >
                        {("Addresses")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="edit-tab"
                        data-toggle="tab"
                        href="#edit"
                        role="tab"
                        aria-controls="edit"
                        aria-selected="false"
                      >
                        {("Account details")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href={() => {}}>
                        {("Wishlist")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="Logout" onClick={this.Logout}>
                        {("Logout")}
                      </a>
                    </li>
                  </ul>
                </div>
  
                <div className="col-lg-9 order-lg-last order-1 tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="dashboard"
                    role="tabpanel"
                  >
                    <div className="dashboard-content">
                      <p>
                        {("Hello")} <strong className="text-dark"></strong> (
                        {localStorage.getItem("first_name")}
                        <strong className="text-dark"></strong>
                        )
                      </p>
                      <p>
                        {("From your account dashboard you can view your")}
                        <a className="btn btn-link link-to-tab" href="#order">
                          {("recent orders")}
                        </a>
                        , {("manage your")}
                        <a className="btn btn-link link-to-tab" href="#address">
                          {("shipping and billing addresses")}
                        </a>
                        , and
                        <a className="btn btn-link link-to-tab" href="#edit">
                          {("edit your password and account details.")}
                        </a>
                      </p>
                      <div className="mb-4" />
                      <div className="row row-lg">
                        <div className="col-6 col-md-4">
                          <div className="feature-box text-center pb-4">
                            <a href="#order" className="link-to-tab">
                              <i className="sicon-social-dropbox" />
                            </a>
                            <div className="feature-box-content">
                              <h3>{("ORDERS")}</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-md-4">
                          <div className="feature-box text-center pb-4">
                            <a href="#download" className="link-to-tab">
                              <i className="sicon-cloud-download" />
                            </a>
                            <div className=" feature-box-content">
                              <h3>{("DOWNLOADS")}</h3>
                            </div>
                          </div>
                        </div>
  
                        <div className="col-6 col-md-4">
                          <div className="feature-box text-center pb-4">
                            <a href="#address" className="link-to-tab">
                              <i className="sicon-location-pin" />
                            </a>
                            <div className="feature-box-content">
                              <h3>{("ADDRESSES")}</h3>
                            </div>
                          </div>
                        </div>
  
                        <div className="col-6 col-md-4">
                          <div className="feature-box text-center pb-4">
                            <a href="#edit" className="link-to-tab">
                              <i className="icon-user-2" />
                            </a>
                            <div className="feature-box-content p-0">
                              <h3>{("ACCOUNT DETAILS")}</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-md-4">
                          <div className="feature-box text-center pb-4">
                            <a href={() => {}}>
                              <i className="sicon-heart" />
                            </a>
                            <div className="feature-box-content">
                              <h3>{("WISHLIST")}</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-md-4">
                          <div
                            className="feature-box text-center pb-4"
                            onClick={this.Logout}
                          >
                            <a href={"/Signup"} onClick={this.Logout}>
                              <i className="sicon-logout" />
                            </a>
                            <div className="feature-box-content">
                              <h3>{("LOGOUT")}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End .row */}
                    </div>
                  </div>
                  {/* End .tab-pane */}
                  <div className="tab-pane fade" id="order" role="tabpanel">
                    <div className="order-content">
                      <h3 className="account-sub-title d-none d-md-block">
                        <i className="sicon-social-dropbox align-middle mr-3" />
                        {("Orders")}
                      </h3>
                      <div className="order-table-container text-center">
                        <table className="table table-order text-left">
                          <thead>
                            <tr>
                              <th className="order-id">{("ORDER")}</th>
                              <th className="order-date">{("DATE")}</th>
                              <th className="order-status">{("STATUS")}</th>
                              <th className="order-price">{("TOTAL")}</th>
                              <th className="order-action">{("ACTIONS")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center p-0" colSpan={5}>
                                <p className="mb-5 mt-5">
                                  {("No Order has been made yet.")}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <hr className="mt-0 mb-3 pb-2" />
                        <a href={() => {}} className="btn btn-primary">
                          {("Go Shop")}
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End .tab-pane */}
                  <div className="tab-pane fade" id="download" role="tabpanel">
                    <div className="download-content">
                      <h3 className="account-sub-title d-none d-md-block">
                        <i className="sicon-cloud-download align-middle mr-3" />
                        {("Downloads")}
                      </h3>
                      <div className="download-table-container">
                        <p>{("No downloads available yet.")}</p>{" "}
                        <a
                          href={() => {}}
                          className="btn btn-primary text-transform-none mb-2"
                        >
                          {("GO SHOP")}
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End .tab-pane */}
                  {customers_addresses && customers_addresses.length > 0 ? (
                    <>
                      <div className="tab-pane fade" id="address" role="tabpanel">
                        <div className="addresses-content">
                          <div className="address">
                            <div className="card">
                              <div className="card-body">
                                <p className="card-text">
                                  {(
                                    "Manage your saved addresses for fast and easy checkout across our marketplaces"
                                  )}
                                </p>
                                <a
                                  href="#billing"
                                  className="btn btn-default address-action link-to-tab"
                                  style={{ textDecoration: "none", background : '#e65904', color : "white" }}
                                  onClick={this.ClearFormFields}
                                >
                                  <i className="sicon-plus align-middle mr-3"></i>
                                  {("Add New Address")}
                                </a>
                              </div>
                            </div>
                          </div>
  
                          <div className="address">
                            <div className="card">
                              <div className="card-header">
                                <i className="sicon-location-pin align-middle mr-3" />
                                {("Default Address")}
                                <div className="float-right">
                                  <button
                                    className="btn btn-sm btn-primary mx-1"
                                    title="Set As Default"
                                    disabled
                                  >
                                    <i className="fa fa-check"></i>
                                  </button>
                                  {customers_addresses.length > 0 ? (
                                    customers_addresses.map((item) => {
                                      if (item.selected === 1) {
                                        return (
                                          <a
                                            href="#billing"
                                            style={{
                                              backgroundColor: "#e65904",
                                              color: "white",
                                            }}
                                            className="btn btn-sm btn-primary mx-1 address-action link-to-tab"
                                            id="EditAddressBtn"
                                            title="Edit"
                                            onClick={() =>
                                              this.EditCustomerAddress(item)
                                            }
                                          >
                                            <i className="fa fa-edit"></i>
                                          </a>
                                        );
                                      }
                                      return null; // Skip non-selected addresses
                                    })
                                  ) : (
                                    <h1>{("Bye")}</h1>
                                  )}
  
                                  <button
                                    className="btn btn-sm btn-primary mx-1"
                                    title="Remove"
                                    disabled
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="card-body">
                                {customers_addresses.length > 0 ? (
                                  customers_addresses.map((item) => {
                                    if (item.selected === 1) {
                                      return (
                                        <div key={item.address_id}>
                                          <div>
                                            <strong>{("Name")}</strong>
                                            <p>
                                              {item.first_name} {item.last_name}
                                            </p>
                                          </div>
                                          <div>
                                            <strong>{("Address")}</strong>
                                            <p>
                                              {item.apartment},{" "}
                                              {item.complete_address},{" "}
                                              {item.pincode}, {item.city},{" "}
                                              {item.state}, {item.country}
                                            </p>
                                          </div>
                                          <div>
                                            <strong>{("Phone")}</strong>
                                            <p>
                                              {item.mobile_number_2}{" "}
                                              <span className="text-primary">
                                                {("Verify now")}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    }
                                    return null; // Skip non-selected addresses
                                  })
                                ) : (
                                  <h1>{("Bye")}</h1>
                                )}
                              </div>
                            </div>
                          </div>
  
                          <div className="address">
                            <div className="card">
                              <div className="card-header">
                                <i className="sicon-location-pin align-middle mr-3" />
                                {("Other Addresses")}
                              </div>
                              <div className="card-body">
                                <div className="container-fluid">
                                  <div className="row">
                                { customers_addresses ? (
                                  customers_addresses.map((item) => (
                                    item.selected !== 1 &&
                                      <div
                                      className="col-sm-4"
                                      key={item.address_id}
                                    >
                                      <div className="card">
                                        <div className="card-header">
                                          <div className="float-right">
                                            <button
                                              className="btn btn-sm btn-primary mx-1"
                                              title="Set As Default"
                                            >
                                              <i className="fa fa-check"></i>
                                            </button>
                                            <a
                                              href="#billing"
                                              style={{
                                                backgroundColor: "#e65904",
                                                color: "white",
                                              }}
                                              className="btn btn-sm btn-primary mx-1 address-action link-to-tab"
                                              id="EditAddressBtn"
                                              title="Edit"
                                              onClick={() =>
                                                this.EditCustomerAddress(item)
                                              }
                                            >
                                              <i className="fa fa-edit"></i>
                                            </a>
                                            <button
                                              className="btn btn-sm btn-primary mx-1"
                                              title="Remove"
                                              onClick={() =>
                                                this.RemoveCustomerAddress(
                                                  item.customer_id,
                                                  item.address_id
                                                )
                                              }
                                            >
                                              <i className="fa fa-trash"></i>
                                            </button>
                                          </div>
                                        </div>
  
                                        <div className="card-body">
                                          <div>
                                            <strong>{("Name")}</strong>
                                            <p>{item.first_name}</p>
                                          </div>
                                          <div>
                                            <strong>{("Address")}</strong>
                                            <p>{item.complete_address}</p>
                                          </div>
                                          <div>
                                            <strong>{("Phone")}</strong>
                                            <p>
                                              {item.mobile_number_1}{" "}
                                              <span className="text-primary">
                                                {("Verify now")}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                )))  : (
                                  <h2>No Other Addresses Found</h2>
                                )
                                
                                }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="edit" role="tabpanel">
                        <h3 className="account-sub-title d-none d-md-block mt-0 pt-1 ml-1">
                          <i className="icon-user-2 align-middle mr-3 pr-1" />
                          {("Account Details")}
                        </h3>
                        <div className="account-content">
                          <form action="#">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="acc-name">
                                    {("First name")}{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Editor"
                                    id="acc-name"
                                    name="acc-name"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="acc-lastname">
                                    {("Last name")}{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="acc-lastname"
                                    name="acc-lastname"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-2">
                              <label htmlFor="acc-text">
                                {("Display name")}{" "}
                                <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="acc-text"
                                name="acc-text"
                                placeholder="Editor"
                                required
                              />
                              <p>
                                {(
                                  "This will be how your name will be displayed in the account section and in reviews"
                                )}
                              </p>
                            </div>
                            <div className="form-group mb-4">
                              <label htmlFor="acc-email">
                                {("Email address")}{" "}
                                <span className="required">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="acc-email"
                                name="acc-email"
                                placeholder="editor@gmail.com"
                                required
                              />
                            </div>
                            <div className="change-password">
                              <h3 className="text-uppercase mb-2">
                                {("Password Change")}
                              </h3>
                              <div className="form-group">
                                <label htmlFor="acc-password">
                                  {(
                                    "Current Password (leave blank to leave unchanged)"
                                  )}
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  id="acc-password"
                                  name="acc-password"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="acc-password">
                                  {(
                                    "New Password (leave blank to leave unchanged)"
                                  )}
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  id="acc-new-password"
                                  name="acc-new-password"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="acc-password">
                                  {("Confirm New Password")}
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  id="acc-confirm-password"
                                  name="acc-confirm-password"
                                />
                              </div>
                            </div>
                            <div className="form-footer mt-3 mb-0">
                              <button type="submit" className="btn btn-primary mr-0">
                                {("Save changes")}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="billing" role="tabpanel">
                        <div className="address account-content mt-0 pt-2">
                          <h4 className="title">
                            <a
                              href="#address"
                              className="link-to-tab"
                              style={{ textDecoration: "none", color: "#000" }}
                              onClick={this.ClearFormFields}
                            >
                              <i className="icon-left-open"></i>
                              {("Back to addresses")}
                            </a>
                          </h4>
                          <form
                            className="mb-2"
                            // onSubmit={this.AddUpdateCustomerAddress}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    {("First name")}{" "}
                                    <span className="required">*</span>
                                  </label>
                                
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={this.handleChange}
                                    required
                                  />

                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    {("Last name")}{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    {("Phone")}{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="mobile_number_1"
                                    value={formData.mobile_number_1}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    {("Email address")}{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    name="contact_email"
                                    value={formData.contact_email}
                                    onChange={this.handleChange}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>{("Company")} </label>
                              <input
                                type="text"
                                className="form-control"
                                name="company_name"
                                value={formData.company_name}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                            <div className="select-custom">
                              <label>
                                {("Country / Region")}{" "}
                                <span className="required">*</span>
                              </label>
                              <select
                                name="country"
                                className="form-control"
                                value={formData.country_name} // Make sure this reflects the actual country code
                                onChange={this.handleChange}
                              >
                                {countries.length > 0 ? (
                                  countries.map((country) => (
                                    <option
                                      key={country.country_id}
                                      value={country.country}
                                    >
                                      {country.country}
                                    </option>
                                  ))
                                ) : (
                                  <option value="">No countries available</option>
                                )}
                              </select>
                            </div>
  
                            <div className="form-group">
                              <label>
                                {("Street address")}{" "}
                                <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="complete_address"
                                value={formData.complete_address}
                                onChange={this.handleChange}
                                placeholder={("House number and street name")}
                                required
                              />
                              <input
                                type="text"
                                className="form-control"
                                name="apartment"
                                value={formData.apartment}
                                onChange={this.handleChange}
                                placeholder={(
                                  "Apartment, suite, unit, etc. (optional) "
                                )}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                {("Town / City")}{" "}
                                <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={formData.city}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                {("State / Country")}{" "}
                                <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                value={formData.state}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                {("Postcode / ZIP")}{" "}
                                <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="pincode"
                                value={formData.pincode}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
  
                            <div className="form-group">
                              <label>
                                {("Set as default address")}
  
                                <input
                                  type="checkbox"
                                  name="selected"
                                  checked={formData.selected}
                                  onChange={this.handleChange}
                                />
                              </label>
                            </div>
                            <div className="form-footer mb-0 ">
                              <div className="form-footer-right d-flex justify-content-between ">
                                <span>
                                  {errorMessage && (
                                    <div
                                      id="error"
                                      className="alert alert-danger"
                                    >
                                      {errorMessage}
                                    </div>
                                  )}
                                  {successMessage && (
                                    <div
                                      id="success"
                                      className="alert alert-success"
                                    >
                                      {successMessage}
                                    </div>
                                  )}
                                </span>
                                <a
                                href="#address"
                                  type="button"
                                  className="btn btn-primary py-4 address-action link-to-tab"
                                  onClick={this.AddUpdateCustomerAddress}
                                >
                                  {("Save Address")}
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    
                    
                    </>
                  ) : (
                    <>
                      <div className="tab-pane fade" id="address" role="tabpanel">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="cart-table-container  cart-summary" style={{ padding : '110px' }}>
                              <div className="d-flex justify-content-center">
                                <h5>
                                No Addresses found in your account!
                                </h5>
                              </div>
                              <p className="text-center">
                                Add a delivery address.
                                </p>
                              <div className="checkout-methods d-flex justify-content-center">
                              <div className="address">
                                
                              <a
                                href="#billing"
                                className="btn address-action link-to-tab"
                                style={{ textDecoration: "none", background : '#e65904', color : "white" }}
                                onClick={this.ClearFormFields}
                              >
                                <i className="sicon-plus align-middle mr-3"></i>
                                {("Add New Address")}
                              </a>
                        </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </>
                  )}
                  {/* End .tab-pane */}
  
                  {/* End .tab-pane */}
  
                  {/* Adddd new address  */}
  
                  {/* End .tab-pane */}
  
                  {/* End .tab-pane */}
                </div>
                {/* End .tab-content */}
              </div>
              {/* End .row */}
            </div>
            {/* End .container */}
            <div className="mb-5" />
            {/* margin */}
          </main>
        </>
        )
    }
}
export default MyAccount