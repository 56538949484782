import { useContext, useState } from "react"
import { ConfigContext } from "../../Context/ConfigContext"
import Carousel from "react-multi-carousel";
import { NavLink } from "react-router-dom";
import slugify from "slugify";
const Makes = () => {
    const {makes, gdcURL} = useContext(ConfigContext);
    const [responsive, setResponsive] =  useState({
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 10,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3,
          slidesToSlide: 1 // optional, default to 1.
        }
      })
    return <div className='container mb-1 mt-1 pb-2 pt-2' style={{borderBottom: '1px solid #f3f3f3',borderTop: '1px solid #f3f3f3'}}>
    <div className='col-lg-12'>
    {
        makes && 
        <Carousel 
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        
        infinite={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {
            makes && makes.map((make, index) => {

                return <div key={`img-sup-brand-${index}`} style={{cursor: 'pointer',height: '50px', width: '100px', textAlign: 'center', border:  '1px solid #CCC' , marginLeft: '10px'}}>
                    <NavLink to={`/Makes/${slugify(make.MFA_BRAND)}/${make.MFA_ID}`}>
                    <ImageWithFallback src={`${gdcURL}/make_logo/${make.MFA_ID}.png`} alt={make.MFA_BRAND} index={index} fallbackText={make.MFA_BRAND}/>
                    </NavLink>
                    </div>
            })
        } 
        </Carousel>
        }
        
    </div>
</div>
 
}
const ImageWithFallback = ({ src, index, alt, fallbackText }) => {
    const [imgError, setImgError] = useState(false);
  
    const handleError = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setImgError(true);
    };
    const fallbackStyle = {
      textAlign: 'center',
      fontSize: '10px',
      padding: '10px',
      wordBreak: 'break-word',
      maxWidth: '90%',
    };
  
    const containerStyle = {
      position: 'relative',
      width: '100%',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    return (
      <div style={containerStyle}>
        {imgError ? (
          <div style={fallbackStyle}>{fallbackText}</div>
        ) : (
          <img style={{height: '40px', margin: 'auto', float: 'none', marginTop: '5px'}} key={`img-part-${index}`} src={src} alt={alt} onError={handleError}  />
         
        )}
      </div>
    );
  };
export default Makes;