import React, { Component } from "react";
// import $ from 'jquery';
// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from "react-router-dom";
import CarSearchBox from "../../Layout/CarSeachBox";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
const Slider = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
    return (
      <main className="main">
        <div className="row justify-content-center">
          <div className="col-md-3 d-flex">
            {<CarSearchBox />}
          </div>
          <div className="col-md-6 d-flex">
          
          <div style={{ width: '98%', margin: '0 auto' }}>
      <Carousel 
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        infinite={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-20-px">
        <div style={{ backgroundImage: `url('/assets/slider/slider.jpg')`, backgroundPosition: 'center', backgroundSize: 'cover', width: '100%', height: '410px', backgroundRepeat: 'no-repeat' }}></div>
        <div style={{ backgroundImage: `url('/assets/slider/slider.jpg')`, backgroundPosition: 'center', backgroundSize: 'cover', width: '100%', height: '410px', backgroundRepeat: 'no-repeat' }}></div>
      </Carousel>
    </div>
          </div>
        </div>
        <div style={{display: 'none'}} className="home-slider slide-animate owl-carousel owl-theme show-nav-hover nav-big mb-2 text-uppercase owl-loaded owl-drag" data-owl-options="{ 'loop': false }" >
         
          {/* End .home-slide */}
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transition: "all 0s ease 0s",
                width: "2698px",
              }}
            >
              <div className="owl-item active" style={{ width: "1349px" }}>
                <div className="home-slide home-slide1 banner">
                  <img
                    className="slide-bg"
                    src="assets/images/sliders/CSlider7.png"
                    width={1903}
                    height={499}
                    alt="slider"
                  />
                  <div className="container d-flex align-items-center">
                    <div
                      className="banner-layer appear-animate fadeInUpShorter animated appear-animation-visible"
                      data-animation-name="fadeInUpShorter"
                      style={{
                        animationDelay: "0ms",
                        animationDuration: "1000ms",
                      }}
                    >
                      <h4 className="text-transform-none m-b-3">
                      
                      {/* {t("Drivetheextramile")} */}
                      Drivetheextramile
                      </h4>
                      <h2 className="text-transform-none mb-0">
                        {/* {t("SpeedySavings")} */}
                        SpeedySavings
                        </h2>
                      <h3 className="m-b-3">
                        {/* {t("70%Off")} */}70%Off
                        </h3>
                      <h5 className="d-inline-block mb-0">
                        <span>
                          {/* {t("StartingAt")} */}StartingAt
                          </span>
                        <b className="coupon-sale-text text-white bg-secondary align-middle">
                          <sup>
                            {/* {t("AED")} */}AED
                            </sup>
                          <em className="align-text-top">
                            {/* {t("199")} */}199
                            </em>
                          <sup>
                            {/* {t("99")} */}99
                            </sup>
                        </b>
                      </h5>
                      <NavLink
                          to={'/'}
                          className="btn btn-lg btn-dark"
                        >
                          
                          {/* {t("ShopNow")} */}ShopNow

                        </NavLink>
                    </div>
                    {/* End .banner-layer */}
                  </div>
                </div>
              </div>
              <div className="owl-item" style={{ width: "1349px" }}>
                <div className="home-slide home-slide2 banner banner-md-vw">
                  <img
                    className="slide-bg"
                    style={{ backgroundColor: "#ccc" }}
                    width={1903}
                    height={499}
                    src="assets/images/sliders/CSlider6.png"
                    alt="slider"
                  />
                  <div className="container d-flex align-items-center">
                    <div
                      className="banner-layer d-flex justify-content-center appear-animate"
                      data-animation-name="fadeInUpShorter"
                      style={{}}
                    >
                      <div className="mx-auto">
                        <h4 className="m-b-1">
                          {/* {t("Extra")} */}Extra
                          </h4>
                        <h3 className="m-b-2">
                          {/* {t("20%off")} */}20%off
                          </h3>
                        <h3 className="mb-2 heading-border">
                          {/* {t("Accessories")} */}Accessories
                          </h3>
                        <h2 className="text-transform-none m-b-4">
                        
                        {/* {t("SavingsHighway")} */}
                        SavingsHighway
                        </h2>
                        <NavLink
                          to={'/'}
                          className="btn btn-block btn-dark"
                        >
                          {/* {t('ShopAllSale')} */}
                          ShopAllSale
                        </NavLink>
                      </div>
                    </div>
                    {/* End .banner-layer */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="owl-nav">
            <button
              type="button"
              title="nav"
              role="presentation"
              className="owl-prev disabled"
            >
              <i className="icon-left-open-big" />
            </button>
            <button
              type="button"
              title="nav"
              role="presentation"
              className="owl-next"
            >
              <i className="icon-right-open-big" />
            </button>
          </div>
          <div className="owl-dots disabled" />
        </div>
        {/* End .home-slider */}
      
        {/* End .container */}
      </main>
    )
        
  
}

export default Slider;