import React, {Component} from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import withRouter from "../../Routes/withRouter";
import axios from "axios";
import { AddCar, GetCars } from "../../Components/Cars";

class CarVin extends Component{

    static contextType = ConfigContext;
    constructor(props){
        super(props);
        this.state = {
            vin : this.props.params.vin || '',
            VinCarData : null,
            VinSelectedCar : null
        }
        console.log("vin 1", this.state.vin);
    }

    componentDidMount(){
        
        console.log("vin 2", this.state.vin);
        if(this.state.vin){
            this.handleSubmit();
        }
    }
    componentDidUpdate(prevProps) {
        const { vin: prevVin } = prevProps.params;
        const { vin: currentVin } = this.props.params;
    
        if (currentVin !== prevVin) {
          this.setState({ vin: currentVin }, () => {
            this.handleSubmit();
          });
        }
      }
    
      handleSubmit = async () => {
        const  vin  = this.state.vin;

        console.log("vin 1 From Search", vin);

        // console.log("vin 2 k", vin);
        const {apiURL} = this.context;
        try {
          const response = await axios.get(`${apiURL}/Parts/GetVINCar?country_id=225&lang=en&vin=${vin}`);
          console.log("response", response);
          this.setState({ VinCarData: response.data.data });
        } catch (error) {
          console.error('Error fetching car data:', error);
          this.setState({ VinCarData: null });
        }
      };
    handleSearch = () => {
        // Trigger a new search when the user clicks the search button
        this.handleSubmit();
      };
    
      SelectCar = (selectedCar) => {
        const { ChangeCarStatus, UpdateCarList } = this.context
        console.log("selectedCar", selectedCar);
        this.setState({ selectedCar }, () => {
          AddCar(selectedCar);
          ChangeCarStatus(true);
          UpdateCarList( GetCars() )
          setTimeout(() => {

              this.props.navigate(`/Catalog/${selectedCar.car_id}/${selectedCar.car_brand.replace(/\s/g, "_")}/${selectedCar.car_year}/${selectedCar.car_model.replace(/\s/g, "_")}/${selectedCar.car_engine.replace(/\s/g, "_")}`);
          }, 500);
        });
      };
    render(){
        const { VinCarData } = this.state;
        const  vin  = this.state.vin;
        console.log("vin 4", vin);
        console.log("VinCarData", VinCarData);
        return(
            <main className="main">
        <div className="container">
        <section className="default-section text-center">
        <div className='row'>
        <div className='col-lg-3'>

        </div>
        <div className='col-lg-6'>
        <form
          className=""
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              id="vin"
              placeholder="Enter VIN"
              value={vin}
              onChange={(e) => this.setState({ vin: e.target.value })}
            />
            <div className="input-group-append">
              <div className="mx-2"></div>
              <button type="button" style={{ background : "#e65904", color : "#FFFFFF" }} className="btn btn-md" onClick={this.handleSearch}>
                Search
              </button>
            </div>
          </div>
        </form>

        </div>
        <div className='col-lg-3'>

        </div>
      </div>
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="tabs mt-0 mb-5">
                <div className="tab-content">
                  <div className="row">
                
                  {VinCarData && VinCarData.length > 0
            ? VinCarData.map((car) => (
                <div className="col-md-4" key={car.car_id}>
                  <div
                    className={`card ${
                      localStorage.getItem('cars') &&
                      JSON.parse(localStorage.getItem('cars')).some((item) => item.car_id === car.car_id)
                        ? 'selected'
                        : ''
                    }`}
                  >
                    <div className="card-header">{car.car_brand}</div>
                    <div className="card-body">
                      <>
                        <table className="table">
                          <tr>
                            <th>Year</th>
                            <th>Model</th>
                            <th>Engine</th>
                          </tr>
                          <tr>
                            <td>{car.car_year}</td>
                            <td>{car.car_model}</td>
                            <td>{car.car_engine}</td>
                          </tr>
                        </table>
                        <button
                          className={`btn ${
                            localStorage.getItem('cars') &&
                            JSON.parse(localStorage.getItem('cars')).some((item) => item.car_id === car.car_id)
                              ? 'btn-success'
                              : 'btn-primary'
                          } btn-xs`}
                          onClick={() => this.SelectCar(car)}
                        >
                          {localStorage.getItem('cars') &&
                          JSON.parse(localStorage.getItem('cars')).some((item) => item.car_id === car.car_id)
                            ? 'Selected'
                            : 'Select'}
                        </button>
                        {/* Add more car data fields */}
                      </>
                    </div>
                  </div>
                </div>
              ))
            : (
                <div className="col-lg-12">
                  <div className="card-body">
                    <h6 className="text-danger"> ! Please Enter a Valid Car VIN Number to display Cars.</h6>
                  </div>
                </div>
              )}
                   
                  </div>
                  {/* Table */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


    </main>
        )
    }
}
export default withRouter(CarVin);