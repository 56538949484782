import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";

const Reviews = ({title}) => {
    const [recents, setRecents] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    const {gdcURL, apiURL} = useContext(ConfigContext);

    return (
        <div className="container" dir={dir} style={{textAlign: dir ==='rtl'?'right': 'left'}}>
            <h5>{title}</h5>
            <div className="row">
                <div className="col-md-6">
                   
                     <input className="form-control" placeholder={t('name')} />
                    
                </div>
                <div className="col-md-6">
                    <input className="form-control" placeholder={t('email')} />
                </div>
            
                <div className="col-md-12">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label ml-4 mr-4" htmlFor="flexCheckDefault" > {t('yes_i_would_like')}
                        </label>
                    </div>
                </div>
                
                <div className="col-md-12">
                    <div className="form-group">
                    
                        

                        <textarea className="form-control" placeholder={t('write_review')}>

                        </textarea>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary" type="button">{t('submit_a_review')}</button>
        </div>
    )
}

export default Reviews;