import React, {Component} from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { NavLink, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AccountSiderBar from "./AccountSideBar";
import { withTranslation } from 'react-i18next';
class ManageAddress extends Component{
  static contextType = ConfigContext;
    constructor(props){
        super(props);
        this.state = {
          countries: [],
          formData: {
            first_name: "",
            last_name: "",
            company_name: "",
            country: "",
            complete_address: "",
            apartment: "",
            city: "",
            state: "",
            pincode: "",
            mobile_number_1: "",
            mobile_number_2: "",
            contact_email: "",
            selected: false, // Set initial value to false
            country_code: "",
            country_id: 0,
            address_lat: "",
            address_lng: "",
          },
        }
    }
    componentDidMount(){
      this.GetCountries();
    }

    GetCountries = async () => {
      const { apiURL } = this.context;
      try {
        const response = await axios.get(`${apiURL}/Home/GetCountries`);
        const data = response.data;
        if (data) {
          this.setState({ countries: data.data });
        } else {
          console.log("No Countries Found", response.data.message);
        }
      } catch (err) {
        console.log("Something Went Wrong", err);
      }
    };

    handleChange = (e) => {
      const { formData, countries } = this.state;
      const { name, type, value, checked } = e.target;
    
      // If changing the country, find the corresponding country details
      const countryValue = name === "country" ? value : formData.country;
      const currentCountry = countries.find(country => country.country === countryValue);
    
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: type === "checkbox" ? checked : value,
          country_id: currentCountry ? currentCountry.country_id : prevState.formData.country_id,
          country_code: currentCountry ? currentCountry.country_alpha1_code : prevState.formData.country_code,
          country: countryValue,
        },
      }));
    };

    AddUpdateCustomerAddress = async (e) => {
      e.preventDefault();
      const { isEditing } = this.state;
      const { apiURL, token } = this.context;
      try {
        const headers = { token: token, Accept: "application/json", "Content-Type": "application/json", };
        const addressData = {
          customer_id: localStorage.getItem('customer_id'),
          first_name: this.state.formData.first_name,
          last_name: this.state.formData.last_name,
          company_name: this.state.formData.company_name,
          complete_address: this.state.formData.complete_address,
          apartment: this.state.formData.apartment,
          pincode: this.state.formData.pincode,
          city: this.state.formData.city,
          state: this.state.formData.state,
          country: this.state.formData.country,
          country_code: this.state.formData.country_code,
          country_id: this.state.formData.country_id,
          address_lat: "address_lat",
          address_lng: "address_lng",
          mobile_number_1: this.state.formData.mobile_number_1,
          mobile_number_2: 0,
          contact_email: this.state.formData.contact_email,
          selected: this.state.formData.selected ? 1 : 0, // Convert true to 1, false to 0
          address_id:  this.state.formData.address_id? this.state.formData.address_id: '',
          saving: true
        };
        var response = await axios.post(`${apiURL}/Account/AddAddress`, addressData, { headers, });
        if(response.data.success){
          Swal.fire({
              title: 'Successfully',
              text: response.data.message,
              icon: 'success',
              confirmButtonText: 'Okay'
            })
            if(!this.state.formData.address_id){
              this.resetFields();
            }
        }else{
          Swal.fire({
              title: 'Error',
              text: response.data.message,
              icon: 'error',
              confirmButtonText: 'Okay'
            })
        }
        this.setState({saving: false});
      } catch (error) {
        this.setState({ saving: false, errorMessage: "An error occurred Adding/Updating Address.", successMessage: "", });
      }
    };
    resetFields = () => {
      this.setState(
        {
          formData:{
          first_name: "",
          last_name: "",
          company_name: "",
          country: "",
          complete_address: "",
          apartment: "",
          city: "",
          state: "",
          pincode: "",
          mobile_number_1: "",
          mobile_number_2: "",
          contact_email: "",
          selected: false, // Set initial value to false
          country_code: "",
          country_id: "",
          address_lat: "",
          address_lng: "",
          }
        }
      );
    }

    render() {
      const { t } = this.props;
        const dir = localStorage.getItem('dir');
      const {formData, errorMessage, successMessage, countries, saving} = this.state;
        return <main>
            <div className="page-header">
              <div className="container d-flex flex-column align-items-center">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                  <div className="container">
                    <ol className="breadcrumb" dir={dir}>
                      <li className="breadcrumb-item">
                        <NavLink to={'/'}>{t("home")}</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink to={() => {}}>{t("my_account")}</NavLink>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        {t("manage_address")}
                      </li>
                    </ol>
                  </div>
                </nav>
                <h1>{t("manage_address")}</h1>
              </div>
            </div>
            <div className={`container account-container custom-account-container `} dir={dir}>
              <div className="row">
                <AccountSiderBar />
                <div className={`col-lg-9 order-lg-last order-1 tab-content ${dir==='rtl'?'text-right': ''}`} dir={dir}>
                    <div className="tab-pane fade show active" id="address" role="tabpanel">
                      <div className="address account-content mt-0 pt-2">
                        <h4 className="title"> <NavLink to={`/MyAccount/Addresses`} className={`link-to-tab ${dir==='rtl'?'text-right': ''}`} style={{ textDecoration: "none", color: "#000" }} onClick={this.ClearFormFields} > <i className="icon-left-open"></i> {t("back_to_address")} </NavLink> </h4>
                        <form className="mb-2" >
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label> {t("first_name")}{" "}
                                    <span className="required">*</span>
                                  </label>
                                
                                  <input type="text" className="form-control" name="first_name" value={formData.first_name} onChange={this.handleChange} required />

                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label> {t("last_name")}{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input type="text" className="form-control" name="last_name" value={formData.last_name} onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label> {t("phone")}{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input type="number" className="form-control" name="mobile_number_1" value={formData.mobile_number_1} onChange={this.handleChange} required />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label> {t("email")}{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input type="email" className="form-control" name="contact_email" value={formData.contact_email} onChange={this.handleChange} required />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>{t("company")} </label>
                              <input type="text" className="form-control" name="company_name" value={formData.company_name} onChange={this.handleChange} required />
                            </div>
                            
  
                            <div className="form-group">
                              <label> {t("address")}{" "}
                                <span className="required">*</span>
                              </label>
                              <textarea type="text" className="form-control" name="complete_address" value={formData.complete_address} onChange={this.handleChange} placeholder={t("address")} required >{formData.complete_address}</textarea>
                              
                            </div>
                            <div className="form-group">
                              <label> {t("town_city")}{" "} <span className="required">*</span> </label>
                              <input type="text" className="form-control" name="city" value={formData.city} onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                              <label> {t("state")}{" "} <span className="required">*</span>
                              </label>
                              <input type="text" className="form-control" name="state" value={formData.state} onChange={this.handleChange} required />
                            </div>
                            <div className="select-custom">
                              <label> {t("country")}{" "} <span className="required">*</span> </label>
                              <select name="country" className="form-control" value={formData.country_name} onChange={this.handleChange} >
                              <option disabled selected > {t("select_country")} </option>
                                {countries.length > 0 ? ( countries.map((country) => (
                                    <option key={country.country_id} value={country.country} > {country.country} </option>
                                  ))
                                ) : (
                                  <option value="">No countries available</option>
                                )}
                              </select>
                            </div>
                            <div className="form-group">
                              <label> {t("pincode")} {" "} <span className="required">*</span> </label>
                              <input type="text" className="form-control" name="pincode" value={formData.pincode} onChange={this.handleChange} required />
                            </div>
  
                            <div className="form-group" >
                              <label> {t("set_as_default_address")} 
                                <input type="checkbox" name="selected" checked={formData.selected} onChange={this.handleChange} />
                              </label>
                            </div>
                            <div className="form-footer mb-0 ">
                              <div className="form-footer-right d-flex justify-content-between ">
                                <span>
                                  {errorMessage && (
                                    <div
                                      id="error"
                                      className="alert alert-danger"
                                    >
                                      {errorMessage}
                                    </div>
                                  )}
                                  {successMessage && (
                                    <div
                                      id="success"
                                      className="alert alert-success"
                                    >
                                      {successMessage}
                                    </div>
                                  )}
                                </span>
                                {saving === true?
                                <button type="button" className="btn btn-default"  > {("Saving Address...")} </button>
                                :<button type="button" className="btn btn-primary" onClick={(e) => this.setState({saving: true}, (prevState)=>this.AddUpdateCustomerAddress(e))} > {("Save Address")} </button>}
                                
                              </div>
                            </div>
                          </form>
                      </div>
                    </div>
                </div>
              </div>
            </div>
        </main>
    }
}
export default withTranslation()(ManageAddress);