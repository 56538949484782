// wishlistFunctions.js
import axios from 'axios';



const addToWishlist = async (partDetails, apiURL, token) => {

  console.log("partDetails", partDetails);

  const customer_id = localStorage.getItem('customer_id');

  try {
  
    const headers = {
      'token': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    const CartData = {
      customer_id,
      part_id: partDetails.basic_info.part_id,
      part_uid: partDetails.basic_info.parts_price_id,
      part_qty: 1,
    };
    console.log("CartData", CartData);
    const { data } = await axios.post(
      `${apiURL}/Carts/AddToWishlist`,
      CartData,
      { headers }
    );
      console.log("data", data);
    if (data.success) {
      console.log(`Part ${partDetails.basic_info.part_id} added to wishlist successfully`);
      
    } else {
      console.error(data.message);
      
    }
  } catch (error) {
    console.error(`Error adding part ${partDetails.basic_info.part_id} to wishlist:`, error);
    
  }
};

export default addToWishlist;
