

import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Suspense } from "react";
import axios from "axios";
import { ConfigContext } from "../Context/ConfigContext";
import MobileHeader from "./MobileHeader";
import { GetCars } from "../Components/Cars";
import { jwtDecode } from "jwt-decode";
import Sidebar from "./Sidebar";
// import SelectedCarView from "../Components/Home/SelectedCarView";
// import Invoice from "../Components/Invoice/Invoice";
class AppLayout extends Component {
  static contextType = ConfigContext;

  constructor(props) {
        super(props);
        this.state = {
            categories: [],
            cars: [],
            // isMobile : false,
            loading: true,
            error: ''
        }
        localStorage.setItem('dir', 'ltr');
        if(localStorage.getItem('lang') === 'ar'){
          localStorage.setItem('dir', 'rtl');
        }
        
  }

  componentDidMount() {
    const {UpdateCartList,setLang, setWindowResize, setCartItemsQty} = this.context;
    setLang(localStorage.getItem('selectedLanguage')?localStorage.getItem('selectedLanguage'):'en');
    setCartItemsQty();
    // this.GetCarsAPI();
    // this.GetCategories();
    
    // UpdateCartList(GetCarts());
    setWindowResize({
      isMobile: window.innerWidth <= 768,
      loading: false,
      error: null,
    });
    this.CheckLogin();

  }
  CheckLogin = () => {
    const {setLoginStatus} = this.context;
    if(!localStorage.getItem('token')){
      setLoginStatus(false);
      return false
    }
    let token = localStorage.getItem('token');
    let decodedToken = jwtDecode(token);
    let currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      setLoginStatus(false);
      return false
    } else {
      

      setLoginStatus(true);
    }
  }
  GetCategories(){

    const {apiURL} = this.context;
    const { setCategories, lang } = this.context;
    axios.get(`${apiURL}/Categories/GetCategories?country_id=225&lang=${lang}`)
    .then(response => {
       var data = response.data;
      
       setCategories(data.data);
       
      this.setState({
        categories: data.data,
        loading: false,
        error: null,
          });
          this.forceUpdate();
    })
    .catch(error => {
        // Handle an error
        this.setState({
        data: [],
        loading: false,
        error: error,
        });
    });
  }
  
  async GetCarsAPI(){
    const {apiURL, token, setCars, setMakes} = this.context;
    

    axios.get(`${apiURL}/Parts/GetCars`,null,{ token })
    .then(response => {
      var data = response.data;
      setCars(data.data);
      var mMakes = [];
      data.data.reduce((acc, car) => {
        const brand = car.car_brand;
        if (!acc[brand]) {
          acc[brand] = [];
          mMakes.push({ value: car.car_brand, label: car.car_brand });
        }
        acc[brand].push(car);
        return acc;
      }, {});
      setMakes(mMakes);
      this.setState({
        cars : data.data,
        loading : false,
        error : null
      })
    })
    .catch(error => {
      this.setState({
        data : [],
        loading : false,
        error : error
      })
    })
  }

  CheckStatus(){
    if(GetCars().length > 0 ){
      const {ChangeCarStatus, UpdateCarList} = this.context;
      ChangeCarStatus(true);
      UpdateCarList(GetCars());
    }else{
      // console.log("T1001:", GetCars().length);
    }
  }


 
  render() {
    const { CarStatus, resizeState } = this.context;
    const { isMobile } = resizeState;
    
    return (
      <>
      <div className="page-wrapper">
        <Sidebar />
        <Header />
        {/* {CarStatus === false && isMobile?<SelectCarsMob />:<div></div>} */}
        
        <main className="main">
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </main>
        
        <Footer />
      </div>
      <MobileHeader/>
      </>
      
    );
  }
}

export default AppLayout;
