import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ConfigContext } from "../../../Context/ConfigContext";
import { MagnifierContainer, MagnifierPreview, MagnifierZoom } from "@datobs/react-image-magnifiers";

const OEPartImages = ({ ART_ID }) => {
  const [medias, setMedias] = useState([]);
  const [selectedImg, setSelectedImg] = useState(0);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const { gdcURL } = useContext(ConfigContext);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  useEffect(() => {
    GetPartMedias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ART_ID]); // Fetch medias whenever ART_ID changes
  useEffect(() => {

  },[selectedImg])
  const GetPartMedias = () => {
    setLoadingMedia(true);
    fetch(`${gdcURL}/Parts/GetOEPartMedias?lang=en&art_id=${ART_ID}&lang=en`)
      .then((res) => res.json())
      .then((data) => {
        const sortedMediaArray = data.data.sort((a, b) => {
            if (a.ART_MEDIA_TYPE === "URL" && b.ART_MEDIA_TYPE !== "URL") {
                return -1; // a should come before b
            } else if (a.ART_MEDIA_TYPE !== "URL" && b.ART_MEDIA_TYPE === "URL") {
                return 1; // b should come before a
            } else {
                return 0; // no change in order
            }
        });
        setMedias(sortedMediaArray);
        setLoadingMedia(false);
        
        
      })
      .catch((error) => {
        console.error("Error fetching media:", error);
        setLoadingMedia(false);
      });
  };

  const handleMediaClick = (index) => {
    setSelectedImg(index);
  };

  const getYouTubeThumbnail = (url) => {
    if (url && typeof url === 'string' && url.indexOf("youtube") !== -1) {
      const videoId = url.split("/").pop();
      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      return thumbnailUrl;
    } else {
      return "/assets/icons/360.png"; // Default or fallback image URL
    }
  };
  

  const thumbStyle = {
    border: "1px solid #CCC",
    padding: "10px",
    height: "100px",
    width: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer" // Add cursor pointer for clickable effect
  };

  return (
    <div style={{marginTop: '45px'}}>
      {loadingMedia && <p>Loading media...</p>}
      <div style={{minHeight: '300px'}}>
      {
        medias && medias.map((thumb, index) => {
            if(index === selectedImg){
                
                if (thumb.ART_MEDIA_TYPE === "JPEG" || thumb.ART_MEDIA_TYPE === "JPG" || thumb.ART_MEDIA_TYPE === "PNG") {
                  
                  return <img key={`thumb-${index}`} alt="Carz7" src={`${gdcURL}/images/${thumb.ART_MEDIA_SOURCE}`} style={{ height: "auto", width: "70%", margin: 'auto' }} />
                  } else if (thumb.ART_MEDIA_TYPE === "URL") {
                    return <embed src={thumb.ART_MEDIA_HIPPERLINK.replaceAll('http:', 'https:')} type="application/pdf" style={{ height: "auto", width: "70%", margin: 'auto' }} />
                  }
            }
        })
      }
      </div>
      <div style={{ marginTop: "10px" }}>
        {medias && (
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            infinite={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
          >
            {medias.map((img, index) => (
              <div
                key={`img-part-${index}`}
                style={thumbStyle}
                onClick={() => handleMediaClick(index)}
              >
                <img
                  alt="Carz7"
                  src={
                    img.ART_MEDIA_TYPE === "JPEG" || img.ART_MEDIA_TYPE === "JPG" || img.ART_MEDIA_TYPE === "PNG"
                      ? `${gdcURL}/images/${img.ART_MEDIA_SOURCE}`
                      : getYouTubeThumbnail(img.ART_MEDIA_HIPPERLINK)
                  }
                  width={100}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default OEPartImages;
