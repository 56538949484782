import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../Context/ConfigContext';

import { useParams } from 'react-router-dom';
import PartsCard2 from '../../Components/Cards/PartsCard2';
import { Spinner } from '../../Components/Shimmers';
import slugify from 'slugify';
import Carousel from 'react-multi-carousel';
import PartsHorizontalCard from '../../Components/Cards/PartsHorizontalCard';
import CarSearchBox from '../../Layout/CarSeachBox';

const SearchParts = () => {
   
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang')??'en';
    const {gdcURL} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    var { keyword } = useParams();
    var decode_keyword = atob(keyword);
    const [loading, setLoading] = useState(false);
    const [parts, setParts] = useState([]);
    // useEffect(() => {
    //     if(localStorage.getItem('pc_id'))
    //     {
    //       setLoading(true);   
    //     fetch(`${gdcURL}/Parts/SearchParts?keyword=${keyword}&lang=${locale}`)
    //     .then((res) => res.json())
    //     .then((data) => {
            
    //         setParts(data.data);
    //         setLoading(false)
    //     })
    //     .catch((error) => {
    //         console.error('Fetch error:', error);
    //         setLoading(false);
    //     });
    //     }
    // }, [location])
    
    return (
        <main className="main" dir={dir}>
            <div className="container">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to={'/'}><i className="icon-home" /></NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={() => {}}>{t('home')}</NavLink></li>
                        <li className="breadcrumb-item active" aria-current="page">{decode_keyword}</li>
                    </ol>
                </nav>
                {!localStorage.getItem('pc_id') && 
                <div className='container mb-1 mt-1 pb-2 pt-2'>
                  <div className='alert alert-info' style={{textAlign: 'center'}}>
                  <div className='col-sm-12'>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'en' ?
                      <div>
                        To ensure you get the right parts that fit your car perfectly, please select your car before exploring our wide range of products. 
                    This helps us tailor the best options specifically for your make and model, guaranteeing a perfect fit and optimal performance every time.
                    <br /><b>Select Your Car</b> Now and start shopping with confidence!</div>
                    :<div>
                      <b>للحصول على القطع المناسبة لسيارتك!</b>
                      <br />
لتضمن حصولك على القطع التي تناسب سيارتك تمامًا، يرجى اختيار سيارتك قبل استكشاف مجموعة منتجاتنا الواسعة. يساعدنا هذا في تقديم أفضل الخيارات التي تتناسب مع طراز سيارتك لضمان ملاءمة مثالية وأداء أمثل في كل مرة.
<br />
<b>اختر سيارتك الآن وابدأ التسوق بثقة!</b>
                      </div>
                    }
                  </div>
                  </div>
                </div>
                }
            </div>
        </main>
    );
}
const ImageWithFallback = ({ src, index, alt, fallbackText }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };
  const fallbackStyle = {
    textAlign: 'center',
    fontSize: '12px',
    padding: '10px',
    wordBreak: 'break-word',
    maxWidth: '90%',
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <div style={containerStyle}>
      {imgError ? (
        <div style={fallbackStyle}>{fallbackText}</div>
      ) : (
        <img style={{height: '55px', margin: 'auto', float: 'none', marginTop: '5px'}} key={`img-part-${index}`} src={src} alt={alt} onError={handleError}  />
       
      )}
    </div>
  );
};
export default SearchParts;