import React, { Component } from "react";
import Slider from "./Slider";
import FeaturedProducts from "../../Components/Home/FeaturedProducts/FeaturedProducts";
import InfoBox from "../../Components/Home/InfoBox/InfoBox";
import NewArrivals from "../../Components/Home/NewArrivals/NewArrivals";
import Promotion from "../../Components/Home/Promotions/Promotion";
import CustomerSupport from "../../Components/Home/CustomerSupport/CustomerSupport";
import LatestNews from "../../Components/Home/LatestNews/LatestNews";
import SelectedCarView from "../../Components/Home/SelectedCarView";
import { ConfigContext } from "../../Context/ConfigContext";
import CarSelectionOption from "../../Components/CarSelectionOption";
import Offers from "./Offers";
import CarSearchBox from "../../Layout/CarSeachBox";
import Suppliers from "./Suppliers";
import Makes from "./Makes";
import RecentViews from "./RecentViews";
import Offer4x4Section from "./4x4Section";
import Sections from "../SpareParts/Catelog/Sections";

// import Slider from "./Slider";
// import BelowSliderHighlights from "./BelowSliderHighlights";

class Home extends Component{
    
    static contextType = ConfigContext;
    
    componentDidMount(){
        this.setState()
    }

    render(){
        const { CarStatus, resizeState } = this.context
        const { isMobile } = resizeState;
        console.log("isMobile", isMobile, "CarStatus", CarStatus)
        return (
                <div>
                    {/* {CarStatus === false && isMobile ? <CarSelectionOption></CarSelectionOption> : <div></div>}
                    {CarStatus === true && isMobile ? <SelectedCarView /> : <div></div>}  */}
                    
                    <Slider />
                    <InfoBox />
                    <section className="featured-products-section" >
                    <RecentViews />
                   </section>
                   
                    <Offer4x4Section />
                    <Sections />
                    <CustomerSupport />
                    <Suppliers />
                    {/* <LatestNews /> */}
                    <hr className="mt-0 m-b-5" />
                    <Makes /> 
                    {/* End .brands-slider */}
                    <hr className="mt-4 m-b-5" />
                
                    {/* <BelowSliderHighlights /> */}
                </div>
        );
    }
}
export default Home;