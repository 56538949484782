import { useContext, useEffect, useState } from "react";
import { GetRecents } from "../../Helper/RecentCart";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import Carousel from 'react-multi-carousel';
const RecentViews = () => {
    const [recents, setRecents] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const dir = localStorage.getItem('dir');
    const {gdcURL, apiURL} = useContext(ConfigContext);
    const [responsive, setResponsive] =  useState({
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      })
    useEffect(() => {
        // localStorage.setItem('recents','');
        var rows =  GetRecents();
        setRecents(rows);
    }, [])
    return (
        recents && recents.length > 0?  
         <div className="container" dir={dir} style={{textAlign: dir === 'rtl'? 'right': 'left'}}>
            <h2 className="product-title">{t('recent_views')}</h2>
            <div >
            <Carousel 
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        
                        infinite={false}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px">
            {
                recents.map((recent, index) => {
                    var partImg = recent.item_img_url?`${gdcURL}/images/${recent.item_img_url}`:`${apiURL}/public/placeholder_category.jpeg`
                   
                    return <div onClick={() => navigate(recent.url)} key={`recent-${index}`} className="col-md-12">
                        <div className="card pl-5 pr-5 pb-5 pt-5">
                            <img style={{height: '200px'}} src={partImg} alt={recent.item_title} />
                            <div  className="pt-5" style={{minHeight: '130px'}}>
                            <h5 style={{margin:0,}}><NavLink to={recent.item_url}>{recent.item_title}</NavLink></h5>
                            <p>{t('article_number', {value: recent.item_number})}</p>
                            </div>
                            
                            <button  onClick={() => navigate(recent.url)} className="btn btn-dark add-cart mr-2 text-light" ><i className="icon-shopping-cart" /> <span>{t('add_to_cart')}</span> </button>
                        </div>
                    </div>
                })
            }
            </Carousel>
            </div>
            
        </div>
       : <div></div>
            
    )
}
export default RecentViews;