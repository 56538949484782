import axios from "axios";
import React, { Component } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import withRouter from "../../Routes/withRouter";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PartsCard from "../../Components/Cards/PartsCard";
import { NavLink } from "react-router-dom";
import { AddToCart, GetCarts } from "../../Helper/Carts";
import addToWishlist from "../../Helper/WishList";
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton}from "react-share";
import FooterOffers from "../Home/FooterOffers";
import {  Spinner } from "../../Components/Shimmers";
import Select from "react-select";
import PartReview from "./PartReview";
import { ToastContainer, toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
class PartDetail extends Component {
  static contextType = ConfigContext;
  
  constructor(props) {
    super(props);

    this.state = {
      partDetails: null,
      quantity: 1,
      isLoggedIn: false,
      media: [],
      sliderPosition: 0,
      selectedTab: 0,
      part_id: 0,
      part_uid: 0,
      prevSlug:this.props.params.part_slug,
      partLoading: true,
      selectedQty: 1,
      options: this.generateOptions(1000)
    };
  }
  generateOptions = (count) => {
    const options = [];
    for (let i = 1; i <= count; i++) {
      options.push({ value: i, label: i.toString() });
    }
    return options;
  }

  handleAddToCart = (slug) => {
    const { UpdateCartList, ChangeCartProcessStatus } = this.context;
    AddToCart(this.state.part_id, this.state.part_uid, this.state.selectedQty, slug);
    toast.success('🦄 <b>Wow so easy</b>!', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
      });
    UpdateCartList(GetCarts());
    ChangeCartProcessStatus(true);
  };
  componentDidMount() {
    this.LoadPageInit();
    
  }
  componentDidUpdate(){
    if(this.state.prevSlug !== this.props.params.part_slug){
      this.LoadPageInit();
    }
  }
  LoadPageInit = () => {
    this.setState({partLoading: true,prevSlug:this.props.params.part_slug }, (prevState) => {
      window.scrollTo(0, 0)
      this.GetPartDetail();
    })
  }
  GetPartDetail = async () => {
    const { part_slug } = this.props.params;
    const { apiURL, lang } = this.context;

    try {
      const response = await axios.get(
        `${apiURL}/Parts/GetPartDetails?country_id=225&lang=${lang}&part_uid=${part_slug.split('-').pop()}`
      );
      console.log("part_fit_in", response.data.data);
      if (response.data.success) {
        console.log("part_fit_in", response.data.data.part_fit_in);
        var initTab = 0
        if(response.data.data.aftermarkets.enable === true){
          initTab = 0;
        }else if(response.data.data.alternatives.enable === true){
          initTab = 1;
        }else if(response.data.data.specification.enable === true){
          initTab = 2;
        }else if(response.data.data.part_fit_in.enable === true){
          initTab = 3;
        } 
        this.setState({
          partDetails: response.data.data,
          media: response.data.media,
          selectedTab: initTab,
          part_id: response.data.data.basic_info.part_id,
          part_uid: response.data.data.basic_info.parts_price_id,
          partLoading: false
        }); 
        
      } else {
        this.setState({
          partDetails: [],
          media: [],
          selectedTab: initTab,
          partLoading: false
        }); 
        // console.error("Error fetching part details:", response.data.message);
      }
    } catch (error) {
      this.setState({
        partDetails: [],
        media: [],
        selectedTab: initTab,
        partLoading: false
      }); 
      // console.error("Error fetching part details:", error);
    }
  };
  handleAddToWishlist = (partDetails) => {
    const { apiURL, token } = this.context;
    addToWishlist(partDetails, apiURL, token);
    
  };

  render() {
    const { partDetails, partLoading } = this.state;
    const { t } = this.props;
    const dir = localStorage.getItem('dir');
    return (
      <main className="main">
        {
          partLoading === true?<center><Spinner /></center>:
          <div className="container">

          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <ol className="breadcrumb" dir={dir}>
              <li className="breadcrumb-item">
                <NavLink to={'/'}>
                  <i className="icon-home" />
                </NavLink>
              </li>
              {partDetails ? (
                <li className="breadcrumb-item">
                  <NavLink to={() => {}}>
                    {partDetails.basic_info.category_title}{" "}
                    <i className="fas fa-angle-right"></i>{" "}
                    {partDetails.basic_info.part_title}
                  </NavLink>
                </li>
              ) : (
                <li className="breadcrumb-item">
                  <NavLink to={() => {}}>...</NavLink>
                </li>
              )}
            </ol>
          </nav>
          <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
          <div className="product-single-container product-single-default">
            <div className="cart-message d-none">
              <strong className="single-cart-notice">
                
              </strong>
              <span>has been added to your cart.</span>
            </div>
            <div className="row">
              {partDetails && partDetails.media.enable === false ? (
                <div className="col-lg-5 col-md-6 product-single-gallery">
                  <div className="product-item">
                    <img
                      className="product-single-image"
                      src="http://146.190.141.14:3010/public/placeholder_category.jpeg"
                      data-zoom-image="http://146.190.141.14:3010/public/placeholder_category.jpeg"
                      width={468}
                      height={468}
                      alt="product"
                    />
                  </div>
                </div>
              ) : (
                <div className="col-lg-5 col-md-6 product-single-gallery">
                  <div className="product-slider-container">
                    {partDetails ? (
                      <div className="label-group">
                        <div className="product-label label-hot">
                          {partDetails.basic_info.part_type === 3
                            ? `Aftermarket`
                            : "Genuine"}
                        </div>

                        <div className="product-label label-sale">
                          {partDetails.basic_info.part_brand}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <OwlCarousel
                      navText={[
                        '<span className="arrow prev">‹</span>',
                        '<span className="arrow next">›</span>',
                      ]}
                      className="navContainerClass navClass controlsClass"
                      items={1}
                      startPosition={this.state.sliderPosition}
                      nav
                    >
                      {partDetails ? 
                      partDetails.media.data.map((img, index)=>{
                        return  (
                          <div className="product-item" key={`product-item-${index}`}>
                            <img
                              className="product-single-image"
                              src={img.part_media}
                              data-zoom-image={img.part_media}
                              width={468}
                              height={468}
                              alt="product"
                            />
                          </div>
                        );
                        
                      })
                      :<div></div>
                    }
                     
                    </OwlCarousel>

                    {/* End .product-single-carousel */}
                  </div>
                  <OwlCarousel
                    className="navContainerClass navClass controlsClass"
                    items={4}
                    margin={5}
                    mergeFit={true}
                  >
                    {partDetails ? 
                      partDetails.media.data.map((img, index)=>{
                        return (<div key={`product-thumbnail-${index}`} className="owl-dot">
                        <img
                          onClick={() => this.setState({ sliderPosition: index })}
                          src={img.part_media}
                          width={110}
                          height={110}
                          alt="product-thumbnail"
                        />
                      </div>)
                      })
                      :<div></div>
                    }
                    
                    {/* <div className="owl-dot">
                      <img
                        onClick={() => this.setState({ sliderPosition: 1 })}
                        src="http://146.190.141.14:3010/public/placeholder_category.jpeg"
                        width={110}
                        height={110}
                        alt="product-thumbnail"
                      />
                    </div>
                    <div className="owl-dot">
                      <img
                        onClick={() => this.setState({ sliderPosition: 2 })}
                        src="http://146.190.141.14:3010/public/placeholder_category.jpeg"
                        width={110}
                        height={110}
                        alt="product-thumbnail"
                      />
                    </div>
                    <div className="owl-dot">
                      <img
                        onClick={() => this.setState({ sliderPosition: 3 })}
                        src="http://146.190.141.14:3010/public/placeholder_category.jpeg"
                        width={110}
                        height={110}
                        alt="product-thumbnail"
                      />
                    </div>
                    <div className="owl-dot">
                      <img
                        onClick={() => this.setState({ sliderPosition: 4 })}
                        src="http://146.190.141.14:3010/public/placeholder_category.jpeg"
                        width={110}
                        height={110}
                        alt="product-thumbnail"
                      />
                    </div> */}
                  </OwlCarousel>
                </div>
              )}

              {/* End .product-single-gallery */}
              <div className="col-lg-7 col-md-6 product-single-details">
                <h1 className="product-title">
                  {partDetails && partDetails.basic_info.part_title}
                </h1>
                <div className="product-nav">
                  <div className="product-prev">
                    <NavLink to={() => {}}>
                      <span className="product-link" />
                      <span className="product-popup">
                        <span className="box-content">
                          <img
                            alt="product"
                            width={150}
                            height={150}
                            src="http://146.190.141.14:3010/public/placeholder_category.jpeg"
                            style={{ paddingTop: "0px" }}
                          />
                          <span>
                            {partDetails && partDetails.basic_info.part_title}
                          </span>
                        </span>
                      </span>
                    </NavLink>
                  </div>
                  <div className="product-next">
                    <NavLink to={() => {}}>
                      <span className="product-link" />
                      <span className="product-popup">
                        <span className="box-content">
                          <img
                            alt="product"
                            width={150}
                            height={150}
                            src="http://146.190.141.14:3010/public/placeholder_category.jpeg"
                            style={{ paddingTop: "0px" }}
                          />
                          <span>
                            {partDetails && partDetails.basic_info.part_title}
                          </span>
                        </span>
                      </span>
                    </NavLink>
                  </div>
                </div>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "60%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                  <NavLink to={() => {}} className="rating-link">
                    ( 6 Reviews )
                  </NavLink>
                </div>
                {/* End .ratings-container */}
                <hr className="short-divider" />
                <div className="price-box">
                  <span className="old-price">
                    {partDetails && partDetails.basic_info.price_mrp}
                  </span>
                  <span className="new-price">
                    {partDetails && partDetails.basic_info.price}
                  </span>
                </div>
                {/* End .price-box */}
                <div className="product-desc ml-0">
                  <div
                    className="tab-pane fade show active"
                    id="product-desc-content"
                    role="tabpanel"
                    aria-labelledby="product-tab-desc"
                  >
                    <div className="product-desc-content">
                      <ul>
                        <li>{t('product_detail_quick')}</li>
                        <li>{t('product_detail_customer_support_call')}</li>
                        <li>{t('product_detail_safety_genuine')}</li>
                        <li>{t('product_detail_secure_payment')}</li>
                        
                      </ul>
                    </div>
                    {/* End .product-desc-content */}
                  </div>
                </div>
                {/* End .product-desc */}
                <ul className="single-info-list">
                  <li className="mr-5">
                  {t('part_number')} :
                    <strong>
                      {partDetails && partDetails.basic_info.part_number}
                    </strong>
                  </li>
                  <li>
                  {t('category')}:{" "}
                    <strong>
                      <NavLink to={() => {}} className="product-category">
                        {partDetails && partDetails.basic_info.category_title}
                      </NavLink>
                    </strong>
                  </li>
                  <li>
                  {t('brand')}:{" "}
                    <strong>
                      <NavLink to={() => {}} className="product-category">
                        {partDetails && partDetails.basic_info.part_brand}
                      </NavLink>
                    </strong>
                    ,
                  </li>
                  <li>
                    {t('condition')}:{" "}
                    <strong>
                      <NavLink to={() => {}} className="product-category">
                      {t('new')}
                      </NavLink>
                    </strong>
                    
                  </li>
                  {/* <li>
                    Country:{" "}
                    <strong>
                      <NavLink to={() => {}} className="product-category">
                        KSA
                      </NavLink>
                    </strong>
                    ,
                  </li> */}
                </ul>
                <div className="product-action">
                  <div className="product-single-qty">
                    <Select onChange={(e)=>{this.setState({selectedQty: e.value})}} options={this.state.options} placeholder={`${t('qty')} :${this.state.selectedQty}`}/>
                    {/* <input className="horizontal-quantity form-control" type="text" /> */}
                  </div>
                  {/* End .product-single-qty */}
                  <button
                    onClick={() =>
                      this.handleAddToCart( `/Part/${partDetails.basic_info.parts_price_id}/${partDetails.basic_info.part_id}/${partDetails.basic_info.part_title}/${partDetails.basic_info.part_number}` )
                    }
                    className="btn btn-dark add-cart mr-2 text-light"
                    title={t('add_to_cart')}
                  >
                    {t('add_to_cart')}
                  </button>
                  <NavLink to={'Cart'} className="btn btn-gray view-cart d-none">
                  {t('view_cart')}
                  </NavLink>
                </div>
                {/* End .product-action */}
                <hr className="divider mb-0 mt-0" />
                <div className="product-single-share mb-3">
                  <label className="sr-only">{t('share')}:</label>
                  <div className="social-icons mr-2">
                    <FacebookShareButton url={`https://carz7.com`}>
                      <i className="social-icon social-facebook icon-facebook" target="_blank" title="Facebook" />
                    </FacebookShareButton>
                    <TwitterShareButton url="https://carz7.com">
                      <i className="social-icon social-twitter icon-twitter" target="_blank" title="Twitter" />
                    </TwitterShareButton>
                    <LinkedinShareButton url="https://carz7.com">
                    <i className="social-icon social-linkedin fab fa-linkedin-in" target="_blank" title="Linkedin" />
                    </LinkedinShareButton>
                    <WhatsappShareButton url="https://carz7.com">
                    <i className="social-icon social-whatsapp fab fa-whatsapp" target="_blank" title="Google +" />
                    </WhatsappShareButton>
                    <EmailShareButton url="https://carz7.com">
                      <i  className="social-icon social-mail icon-mail-alt" target="_blank" title="Mail" />
                    </EmailShareButton>
                    
                  </div>
                  {/* End .social-icons */}
                  <NavLink
                    to={() => {}}
                    className="btn-icon-wish add-wishlist"
                    title={t('add_to_wishlist')}
                    onClick={() => this.handleAddToWishlist(partDetails)}
                  >
                    <i className="icon-wishlist-2" />
                    <span>{t('add_to_wishlist')}</span>
                  </NavLink>
                </div>
                {/* End .product single-share */}
              </div>
              {/* End .product-single-details */}
            </div>
            {/* End .row */}
          </div>

          {/* End .product-single-container */}
          <div className="product-single-tabs">
            <ul className="nav nav-tabs" role="tablist">
              {partDetails && partDetails.aftermarkets.enable && (
                <li style={{cursor: 'pointer'}} onClick={() => this.setState({selectedTab: 0})} className={`nav-item ${ this.state.selectedTab === 0 ? "active" : "" }`} >
                  <span className={`nav-link ${ this.state.selectedTab === 0 ? "active" : "" }`} >
                  {t('aftermarket')}
                  </span>
                </li>
              )}
              {partDetails && partDetails.alternatives.enable && (
              <li style={{cursor: 'pointer'}} onClick={() => this.setState({selectedTab: 1})} className={`nav-item ${ this.state.selectedTab === 1 ? "active" : "" }`} >
                  <span className={`nav-link ${ this.state.selectedTab === 1 ? "active" : "" }`} >
                  {t('aftermarket')}
                  </span>
                </li>
              )}
              {partDetails && partDetails.specification.enable && (
                <li style={{cursor: 'pointer'}} onClick={() => this.setState({selectedTab: 2})} className={`nav-item ${ this.state.selectedTab === 2 ? "active" : "" }`} >
                  <span className={`nav-link ${ this.state.selectedTab === 2 ? "active" : "" }`} >
                    {t("specification")}
                  </span>
                </li>
              )}
              <li style={{cursor: 'pointer'}} onClick={() => this.setState({selectedTab: 4})} className={`nav-item ${ this.state.selectedTab === 4 ? "active" : "" }`} >
                <span className={`nav-link ${ this.state.selectedTab === 4 ? "active" : "" }`} >
                {t("fit_in")}
                </span>
              </li>
              
              <li style={{cursor: 'pointer'}} onClick={() => this.setState({selectedTab: 3})} className={`nav-item ${ this.state.selectedTab === 3 ? "active" : "" }`} >
                <span className={`nav-link ${ this.state.selectedTab === 3 ? "active" : "" }`} >
                {t("reviews")}
                </span>
              </li>
              
            </ul>
            <div className="tab-content">
              {/* End .tab-pane */}
              <div className={`tab-pane fade ${ partDetails && partDetails.aftermarkets.enable && this.state.selectedTab === 0 ? "show active" : "" }`} id="product-size-content" role="tabpanel" aria-labelledby="product-tab-size" >
                <div className="product-size-content">
                  <div className="row">
                    {/* End .col-md-4 */}
                    <div className="col-md-12">
                     
                      <table className="table table-striped table-bordered" style={{background:"#e659041f"}}>
                        <thead className="text-center">
                          <tr>
                            <th className="p-4">{t("aftermarket_number")}</th>
                            <th className="p-4">{t("brand")}</th>
                            <th className="p-4">{t("price")}</th>
                            <th className="p-4">{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {partDetails &&
                            partDetails.aftermarkets.enable &&
                            partDetails.aftermarkets.data.map((info) => (
                              <tr key={info.part_id}>
                                <td>{info.part_number}</td>
                                <td>{info.part_brand}</td>
                                <td>{info.sale_price}</td>
                                <td>
                                  <NavLink
                                    className="btn btn-sm btn-primary"
                                    to={`/Part/${info.part_slug}`}
                                  >
                                    {"View"}
                                  </NavLink>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* End .row */}
                </div>
                {/* End .product-size-content */}
              </div>
              {/* End .tab-pane */}
              <div className={`tab-pane fade ${  partDetails && partDetails.alternatives.enable && this.state.selectedTab === 1 ? "show active" : "" }`} id="product-alt-no-content" role="tabpanel" aria-labelledby="product-tab-alt-no" >
                <div className="row">
                  {/* End .col-md-4 */}
                  <div className="col-md-12">
                    <table className="table table-striped table-bordered" style={{background:"#e659041f"}}>
                      <thead className="text-center">
                        <tr>
                          <th>{t("aftermarket_number")}</th>
                          <th>{t("brand")}</th>
                          <th>{t("price")}</th>
                          <th className="p-4">{t("action")}</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {partDetails &&
                          partDetails.alternatives.enable &&
                          partDetails.alternatives.data.map((info) => (
                            <tr key={info.part_id}>
                              <td>{info.part_number}</td>
                              <td>{info.part_brand}</td>
                              <td>{info.sale_price}</td>
                              <td>
                              <NavLink className="btn btn-sm btn-primary" to={`/Part/${info.part_slug}`} exact>
                                  {"View"}
                              </NavLink>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className={`tab-pane fade ${  partDetails && partDetails.specification.enable && this.state.selectedTab === 2 ? "show active" : "" }`} id="product-tags-content" role="tabpanel" aria-labelledby="product-tab-tags" >
                <table className="table table-striped table-bordered" style={{background:"#e659041f"}}>
                  <tbody className="text-center">
                    {partDetails &&
                      partDetails.specification.enable &&
                      partDetails.specification.data.map((info) => (
                        <React.Fragment key={info.information_id}>
                          <tr>
                            <th>{t("weight")}</th>
                            <td>{info.weight}</td>
                          </tr>
                          <tr>
                            <th>{t("height")}</th>
                            <td>{info.height}</td>
                          </tr>
                          <tr>
                            <th>{t("width")}</th>
                            <td>{info.width}</td>
                          </tr>
                          <tr>
                            <th>{t("length")}</th>
                            <td>{info.length}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className={`tab-pane fade ${  this.state.selectedTab === 4 ? "show active" : "" }`} id="product-fitins-content" role="tabpanel" aria-labelledby="product-fitins-tags" >
              <div className="row">
                  {/* End .col-md-4 */}
                  <div className="col-md-12">
                    <table className="table table-striped table-bordered" style={{background:"#e659041f"}}>
                      <thead className="text-center">
                        <tr>
                          <th>{t("car_make")}</th>
                          <th>{t("model")}</th>
                          <th>{t("year")}</th>
                          <th >{t("engine")}</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {partDetails && partDetails.part_fit_in?.enable && partDetails.part_fit_in.data.map((info) => (
                            <tr key={info.part_id}>
                              <td>{info.car_brand}</td>
                              <td>{info.car_model}</td>
                              <td>{info.car_year}</td>
                              <td>{info.car_engine}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className={`tab-pane fade ${  this.state.selectedTab === 3 ? "show active" : "" }`} id="product-reviews-content" role="tabpanel" aria-labelledby="product-reviews-tags" >
                    <PartReview part_id={partDetails.basic_info.part_id} part_uid={partDetails.basic_info.parts_price_id} part_number={partDetails.basic_info.part_number} part_title={partDetails.basic_info.part_title} />
              </div>
              
              

              {/* End .tab-pane */}

              {/* End .tab-pane */}
            </div>
            {/* End .tab-content */}
          </div>
          {/* End .product-single-tabs */}
          <section className="new-products-section" style={{display: 'none'}}>
            <div className="container">
              <h2 className="section-title heading-border ls-20 border-0">
                {"Related Parts"}
              </h2>
              <OwlCarousel
                className="owl-theme"
                items={6}
                loop
                margin={10}
                dots={false}
                responsive={false}
                nav
              >
                <PartsCard
                  title={`Filter Oil`}
                  price={`AED49.00`}
                  mrpPrice={`AED60.00`}
                  action={true}
                />
                <PartsCard
                  title={`Filter Oil`}
                  price={`AED49.00`}
                  mrpPrice={`AED60.00`}
                  action={true}
                />
                <PartsCard
                  title={`Filter Oil`}
                  price={`AED49.00`}
                  mrpPrice={`AED60.00`}
                  action={true}
                />
                <PartsCard
                  title={`Filter Oil`}
                  price={`AED49.00`}
                  mrpPrice={`AED60.00`}
                  action={true}
                />
                <PartsCard
                  title={`Filter Oil`}
                  price={`AED49.00`}
                  mrpPrice={`AED60.00`}
                  action={true}
                />
                <PartsCard
                  title={`Filter Oil`}
                  price={`AED49.00`}
                  mrpPrice={`AED60.00`}
                  action={true}
                />
                <PartsCard
                  title={`Filter Oil`}
                  price={`AED49.00`}
                  mrpPrice={`AED60.00`}
                  action={true}
                />
                <PartsCard
                  title={`Filter Oil`}
                  price={`AED49.00`}
                  mrpPrice={`AED60.00`}
                  action={true}
                />
                <PartsCard
                  title={`Filter Oil`}
                  price={`AED49.00`}
                  mrpPrice={`AED60.00`}
                  action={true}
                />
              </OwlCarousel>
              {/* End .featured-proucts */}
            </div>
          </section>
          {/* End .products-section */}
          <hr className="mt-0 m-b-5" />
          <FooterOffers />
          {/* End .row */}
        </div>
        }
        
        {/* End .container */}
      </main>
    );
  }
}
export default withRouter(withTranslation()(PartDetail));
