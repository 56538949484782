import React, { useContext, useEffect, useState } from 'react'

import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../Context/ConfigContext';

import { useParams } from 'react-router-dom';
import { Spinner } from '../../Components/Shimmers';
import slugify from 'slugify';
import Carousel from 'react-multi-carousel';
import PartsHorizontalCard from '../../Components/Cards/PartsHorizontalCard';
import CarSearchBox from '../../Layout/CarSeachBox';
import OEParts from '../OESpareParts/OEParts';

const Parts = () => {
   
    const location = useLocation();
    const { t } = useTranslation();
    const locale = localStorage.getItem('lang')??'en';
    const {gdcURL} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    var { slug } = useParams();
    var STR_ID = slug.split('-').slice(-1);
    const [loading, setLoading] = useState(false);
    const [parts, setParts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedOE, setSelectedOE] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedBrandTags, setSelectedBrandTags] = useState([]);
    
    const [responsive, setResponsive] =  useState({
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 10,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3,
          slidesToSlide: 1 // optional, default to 1.
        }
      })
      
      const [car_info, setCarInfo] = useState(localStorage.getItem('car_info')?JSON.parse(localStorage.getItem('car_info')): []);
    useEffect(() => {
     
        if(localStorage.getItem('pc_id'))
        {
          setLoading(true);   
        fetch(`${gdcURL}/Parts/GetPartsByCar?pc_id=${localStorage.getItem('pc_id')}&str_id=${STR_ID}&lang=${locale}`)
        .then((res) => res.json())
        .then((data) => {
            
            setParts(data.data);
            const uniqueData = data.data.reduce((acc, current) => {
                const x = acc.find(item => item.SUP_ID === current.SUP_ID);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            setBrands(uniqueData);
            setLoading(false)
        })
        .catch((error) => {
            console.error('Fetch error:', error);
            setLoading(false);
        });
        }
    }, [location])
      useEffect(() => {
    }, [selectedBrandTags, selectedOE])
    const toggleBrand = (SUP_ID, SUP_BRAND) => {
        setSelectedBrands((prevSelectedBrands) => {
          if (prevSelectedBrands.includes(SUP_ID)) {
            // Remove SUP_ID if it is already in the array
            return prevSelectedBrands.filter(id => id !== SUP_ID);
          } else {
            // Add SUP_ID if it is not in the array
            return [...prevSelectedBrands, SUP_ID];
          }
        });
        let setSelectedBTs = [...selectedBrandTags]; // Copy the current state

        const index = selectedBrandTags.findIndex(tag => tag.SUP_ID === SUP_ID);
        if (index === -1) {
            // If the tag is not found, add it
            setSelectedBTs.push({ SUP_ID, SUP_BRAND });
        } else {
            // If the tag is found, remove it
            setSelectedBTs.splice(index, 1);
        }

        setSelectedBrandTags(setSelectedBTs);
      };

    const toggleOE = () => {
      if(selectedOE === false || selectedOE === null){
        setSelectedOE(true);
      }else{
        if(selectedBrandTags.length === 0){
          setSelectedOE(null);
        }else{
          setSelectedOE(false);
        }
        
      }
     
    }
    
    return (
        <main className="main" dir={dir}>
            <div className='row'>
              <div className='col-md-3'>
                <CarSearchBox />
              </div>
              <div className='col-md-9'>
                <div className="container">
                  <nav aria-label="breadcrumb" className="breadcrumb-nav">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item"><NavLink to={'/'}><i className="icon-home" /></NavLink></li>
                          <li className="breadcrumb-item"><NavLink to={() => {}}>{t('home')}</NavLink></li>
                          <li className="breadcrumb-item active" aria-current="page">{slug.split('-').slice(0,-1).join(' ')}</li>
                      </ol>
                  </nav>
                  {!localStorage.getItem('pc_id') && 
                  <div className='container mb-1 mt-1 pb-2 pt-2'>
                    <div className='alert alert-info' style={{textAlign: 'center'}}>
                    <div className='col-sm-12'>
                      {
                        localStorage.getItem('lang') === null || localStorage.getItem('lang') === 'en' ?
                        <div>
                          To ensure you get the right parts that fit your car perfectly, please select your car before exploring our wide range of products. 
                      This helps us tailor the best options specifically for your make and model, guaranteeing a perfect fit and optimal performance every time.
                      <br /><b>Select Your Car</b> Now and start shopping with confidence!</div>
                      :<div>
                        <b>للحصول على القطع المناسبة لسيارتك!</b>
                        <br />
  لتضمن حصولك على القطع التي تناسب سيارتك تمامًا، يرجى اختيار سيارتك قبل استكشاف مجموعة منتجاتنا الواسعة. يساعدنا هذا في تقديم أفضل الخيارات التي تتناسب مع طراز سيارتك لضمان ملاءمة مثالية وأداء أمثل في كل مرة.
  <br />
  <b>اختر سيارتك الآن وابدأ التسوق بثقة!</b>
                        </div>
                      }
                    </div>
                    </div>
                  </div>
                  }

                  {
                    loading === false && localStorage.getItem('pc_id') && car_info.length!==0 && <h4 className='my_title'>{t('actual_offers_of', {category: slug.split('-').slice(0,-1).join(' '), car_info: car_info[0].TYPEL})}</h4>
                    
                  }
                  <br />
                  {loading === false && localStorage.getItem('pc_id') && selectedBrandTags && <div>
                    <h6 style={{lineHeight: 0, marginTop: '10px'}}>{t('selected_filters')}</h6>
                    {selectedOE && <span onClick={() => toggleOE()} className="badge badge-pill  mr-4 pt-2 pb-2" style={{backgroundColor: '#e9e9e9'}} key={`tags-OE-brand`}>{car_info[0].MFA_BRAND} <i className="fas fa-times-circle lightColorText"></i>  </span>}
                    {
                      selectedBrandTags && selectedBrandTags.map((item, index) => {
                        return <span onClick={() => toggleBrand(item.SUP_ID, item.SUP_BRAND)} className="badge badge-pill  mr-4 pt-2 pb-2" style={{backgroundColor: '#e9e9e9'}} key={`tags-${item.SUP_ID}`}>{item.SUP_BRAND} <i className="fas fa-times-circle lightColorText"></i>  </span>
                        
                      })
                    }
                    </div>
                  }
                  { loading === false && localStorage.getItem('pc_id') && 
                  <div className='container mb-1 mt-1 pb-2 pt-2' style={{borderBottom: '1px solid #f3f3f3',borderTop: '1px solid #f3f3f3'}}>
                      <div className='col-lg-12'>
                        
                      {
                          brands && 
                          <Carousel 
                          swipeable={false}
                          draggable={false}
                          showDots={false}
                          responsive={responsive}
                          
                          infinite={false}
                          autoPlaySpeed={1000}
                          keyBoardControl={true}
                          customTransition="all .5"
                          transitionDuration={500}
                          containerClass="carousel-container"
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          // deviceType={this.props.deviceType}
                          dotListClass="custom-dot-list-style"
                          itemClass="carousel-item-padding-40-px">
                            {
                           car_info && car_info[0] && car_info[0].PC_MFA_ID && <div onClick={() => toggleOE()} key={`img-sup-brand-100288`}  style={{cursor: 'pointer',height: '50px', width: '100px', textAlign: 'center', border: selectedOE === false || selectedOE === null ? '1px solid #CCC' : '2px solid #e65a05', marginLeft: '10px'}}><ImageWithFallback  OE={true} src={`${gdcURL}/make_logo/${car_info[0].PC_MFA_ID}.png`} alt={car_info[0].MFA_BRAND} index={101102} fallbackText={car_info[0].MFA_BRAND}/></div>
                        }
                          {
                             
                              brands && brands.map((img, index) => {

                                  return <div key={`img-sup-brand-${index}`} onClick={() => toggleBrand(img.SUP_ID, img.ART_SUP_BRAND)} style={{cursor: 'pointer',height: '50px', width: '100px', textAlign: 'center', border: selectedBrands.includes(img.SUP_ID) === false ? '1px solid #CCC' : '2px solid #e65a05', marginLeft: '10px'}}>
                                      <ImageWithFallback src={`${gdcURL}/sup-logo/${img.SUP_LOGO_NAME}`} alt={img.ART_SUP_BRAND} index={index} fallbackText={img.ART_SUP_BRAND} OE={false}/>
                                      
                                      
                                      </div>
                              })
                          } 
                          </Carousel>
                          }
                          
                      </div>
                  </div>}
                  { 
                  localStorage.getItem('pc_id') && (selectedBrands.length === 0 || selectedOE === true) && 
                    <div className='container mb-1 mt-1 pb-2 pt-2' style={{borderBottom: '1px solid #f3f3f3',borderTop: '1px solid #f3f3f3'}}>
                      <div className='col-lg-12'>
                        <OEParts STR_ID={STR_ID}/>
                      </div>
                  </div>
                  }
                  {
                    loading && <div className='mt-5'><center><Spinner /></center></div>
                  }

                  {localStorage.getItem('pc_id') && 
                  <div className="row">
                    <div className="col-lg-9 order-lg-1"> </div>
                    <div className="row pb-4">
                    
                  {
                      loading === false && ((selectedBrands.length !== 0 && selectedOE === true) || selectedOE!==true) && 
                      parts.map((part, index) => {

                          if(selectedBrands.includes(part.SUP_ID) || selectedBrands.length === 0){
                              var part_slug = slugify(`${part.PRODUCT_GROUP}`.toLowerCase())+'-'+slugify(`${part.ART_ARTICLE_NR}`.toLowerCase())+'-'+STR_ID+'-'+slugify(`${part.SUP_ID}`.toLowerCase())+'-'+slugify(`${part.ART_ID}`.toLowerCase())+"#"+part.VD_PRICE;
                              return <PartsHorizontalCard key={`r-part-id-${part.ART_ID}-${index}`} partImg={part.MEDIA_SOURCE} criteria={part.ARTICLE_CRITERIA} url={`/Spare-Parts/Details/${part_slug}`}  id={part.ART_ID}   title={part.PRODUCT_GROUP} number={part.ART_ARTICLE_NR} price={part.VD_PRICE??0} mrp={part.VD_PRICE_MRP??0}  qty={1} imgUrl={''} action={true}  brand={part.ART_SUP_BRAND} suplogo={part.SUP_LOGO_NAME} supid={part.SUP_ID} redLabel={3 === 3?`Aftermarket`: 'Genuine'} partInfo={part.ART_INFO} />
                            
                          }
                          
                      })
                  }
                  </div></div>}
              </div>
              </div>
            </div>
        </main>
    );
}
const ImageWithFallback = ({ src, index, alt, fallbackText, OE }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };
  const fallbackStyle = {
    textAlign: 'center',
    fontSize: '10px',
    padding: '10px',
    wordBreak: 'break-word',
    maxWidth: '90%',
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <div style={containerStyle}>
      {imgError ? (
        <div style={fallbackStyle}>{fallbackText}</div>
      ) : (
        <img style={{height: '40px', margin: 'auto', float: 'none', marginTop: OE === false?'5px': 0}} key={`img-part-${index}`} src={src} alt={alt} onError={handleError}  />
       
      )}
    </div>
  );
};
export default Parts;