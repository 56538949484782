import React, { createContext, useEffect, useState } from 'react';
import { CreateTree } from '../Helper/Utility';
export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  // Define your global configuration here
  // const config = {
  //   appName: 'My App',
  //   apiUrl: 'https://api.example.com/',
  //   // Add more configuration options as needed
  // };
  // http://localhost:3010
  // http://146.190.141.14:3010
  const [apiURL, setAPIURL] = useState('https://api.carz7.com');
  const [gdcURL, setGDCURL] = useState('https://dcapi.carz7.com');
  // const [apiURL, setAPIURL] = useState('http://localhost:3010');
  
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [PCID, setStatePCID] = useState(localStorage.getItem('pc_id'));
  const [MSID, setStateMSID] = useState(localStorage.getItem('ms_id'));
  const setContextPCID = (pc_id) =>  {
    setStatePCID(pc_id)
  }
  const setContextMSID = (ms_id) =>  {
    setStateMSID(ms_id)
  }
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [categories, setStateCategories] = useState(localStorage.getItem('categories')?JSON.parse(localStorage.getItem('categories')): []);
  const [treeCategories, setStateTreeCategories] = useState([]);
  const [cars, setStateCars] = useState([]);
  const [allCars, setStateallCars] = useState([]);
  const [makes, setStateMakes] = useState([]);
  const [years, setStateYears] = useState([]);
  const [models, setStateModels] = useState([]);
  const [engines, setStateEngines] = useState([]);
  const [CarStatus, setStateCarStatus] = useState(false);
  const [CartStatus, setStateCartStatus] = useState(false);
  const [CarList, setStateCarList] = useState([]);
  const [CartList, setStateCartList] = useState([]);
  const [CartItemsQty, setStateCartItemsQty] = useState(0);
  const [CartAmount, setStateCartAmount] = useState(0);
  const [CartProcessStatus, setCartProcessStatus] = useState(false);
  const [SideCartStatus, setStateSideCartStatus] = useState(false);
  const [lang, setStateLang] = useState(localStorage.getItem('selectedLanguage') || 'en');
  const [currency, setStateCurrency] = useState('AED');
  const [sideBarStatus, setStateSideBarStatus] = useState(false);
  const [resizeState, setResizeState] = useState({
    // isMobile: window.innerWidth <= 768,
    isMobile: true,
    loading: false,
    error: null,
  });
  const setSideBarStatus = (status) => {
    setStateSideBarStatus(status);
  }
  const setCartItemsQty = () =>  {
    try{
      if(localStorage.getItem('carts') !== null && localStorage.getItem('carts') !== ''){
        var carts = JSON.parse(localStorage.getItem('carts'));
        setStateCartItemsQty(carts.length);
        var totalAmount = 0;
        for(var i = 0; i < carts.length; i++){
          totalAmount += Number(carts[i].item_price) * Number(carts[i].item_qty);
        }
        setStateCartAmount(totalAmount);
      }else{
        
          setStateCartItemsQty(0);
      }
    }catch(err){
      setStateCartItemsQty(0);
    }
    
  }
  const setCategories = async(data) => {    
    localStorage.setItem('categories', JSON.stringify(data));
    setStateCategories(data);
    var treecats = await CreateTree(data);
    setStateTreeCategories(treecats);
  }
  const setCars = (data) => { setStateCars(data); setStateallCars(data); }


  // const setLang = (data) => { setStateLang(data); }

  const setLang = (data) => {
    localStorage.setItem('selectedLanguage', data); // Corrected this line
    setStateLang(data);
  }
  const setCurrency = (data) => { setStateCurrency(data);}
  const setMakes = (data) => { setStateMakes(data);}
  const setYears = (data) => { setStateYears(data); }
  const setModels = (data) => { setStateModels(data); }
  const setEngines = (data) => { setStateEngines(data); }

  const ChangeCarStatus = (data) => 
  {
    setStateCarStatus(data);  
  }
  const ChangeCartStatus = (data) => 
  {
    setStateCartStatus(data);  
  }

  const UpdateCarList = (data) => { setStateCarList(data); }

  const UpdateCartList = (data) => { setStateCartList(data); }

  const ChangeCartProcessStatus = (data) => { 
    setCartProcessStatus(data); 
  }
  const ChangeSideCartStatus = (data) => {
    setStateSideCartStatus(data);
  }
  const setLoginStatus = (data) =>{
    setIsLoggedIn(data);

  }

  const setWindowResize = (data) => {
    setResizeState(data);
  };
  
  var vals = {
    apiURL, setAPIURL, 
    token, setToken,
    isLoggedIn, setIsLoggedIn,
    categories, setCategories, 
    cars, setCars,allCars,
    makes, setMakes, years, setYears, models, setModels, engines, setEngines,
    CarStatus, ChangeCarStatus,
    CartStatus, ChangeCartStatus,
    CarList, UpdateCarList,
    CartList, UpdateCartList,
    CartProcessStatus, ChangeCartProcessStatus,
    SideCartStatus,ChangeSideCartStatus,
    setLoginStatus,
    resizeState,setWindowResize,
    lang, setLang,
    currency, setCurrency,
    gdcURL, setGDCURL,
    CartItemsQty, setCartItemsQty,
    PCID, setContextPCID,
    MSID, setContextMSID,
    sideBarStatus, setSideBarStatus,
    treeCategories, 
    CartAmount
  };

 


  return (
    <ConfigContext.Provider value={vals}>
      {children}
    </ConfigContext.Provider>
  );
};
