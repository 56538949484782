import React, { Component } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import { NavLink } from "react-router-dom";
import axios from "axios";
import withRouter from "../../Routes/withRouter";
import PartsCard from "../../Components/Cards/PartsCard";
import Select from 'react-select'
import {  Spinner } from "../../Components/Shimmers";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { withTranslation } from 'react-i18next';
// import { PartCardShimmer } from "../../Components/Shimmers";
class Results extends Component {
    
    static contextType = ConfigContext
    
    constructor(props, context) {
        super(props, context);

        this.state = {
          init: 0,
          total_records: 0,
          total_pages: 0,
          page: 1,
          next: false,
          prev: false,
          loading: true,
          pageOptions: [],
          category_id : this.props.params.category_slug.split('-').pop(),
          part: [],
          currentPage: 1,
          limit: 12,
          categories: [],
          queryParam: [],
          results : [],
          brands: [],
          max_price: 0,
          min_price: 0,
          selected_max_price: 0,
          selected_min_price: 0,
          initLoading: true, 
          first_time_loader: 0,
          prevSlug: '',
          car_id: '',
        };
        
        
        // Bind functions to the class instance
        // this.fetchPaginationData = this.fetchPaginationData.bind(this);
        // this.fetchProductData = this.fetchProductData.bind(this);
        
      }
      componentDidMount () {
        console.log("componentDidMount");
        const {CarList} = this.context;
        for(var i = 0; i < CarList.length; i++){
          if(CarList[i].selected === 1){
             this.setState({car_id: CarList[i].car_id});
          }
        }
        console.log("CartList", CarList)
        if(this.state.init === 0 ){
          this.LoadPageInit();
        }
      }
      componentDidUpdate(){
        console.log("componentDidUpdate");
        // this.LoadPageInit();
        if(this.state.prevSlug !== this.props.params.category_slug){
          
          this.LoadPageInit();
         }
      }
      LoadPageInit = async() => {
        
          this.setState({results: [], init: 1, initLoading:true,prevSlug: this.props.params.category_slug}, async(prevState) => {
            await this.fetchProductData();
            this.fetchPaginationData();
            await this.getPartsBrands();
            this.setState({initLoading: false});
          });
        
      }
      
      getPartsBrands = async() => {
        const {apiURL} = this.context;
          this.setState( { loading: true } );
          // var url = `${apiURL}/Parts/GetParts?country_id=225&lang='en'&page=${page}&limit=${limit}&category_id=${this.props.params.category_id}&part_brand=&min_price=0&max_price=`;
          var url = `${apiURL}/Parts/GetPartsBrands${this.GetURLQueryString()}`;
          console.log("GetPartsBrands", url)
          const response = await axios.get(url);
          
          this.setState({ brands: response.data.data }, (prevState)=>{
            
          });

      }
      async fetchProductData() {
   
        try {
          const {apiURL} = this.context;
          
          // var url = `${apiURL}/Parts/GetParts?country_id=225&lang='en'&page=${page}&limit=${limit}&category_id=${this.props.params.category_id}&part_brand=&min_price=0&max_price=`;
         
          var url = `${apiURL}/Parts/GetParts${this.GetURLQueryString()}`;
          const response = await axios.get(url);
          this.setState({ part: response.data.data}, (prevState)=>{
            this.AddOnData(response.data.data);
          });
    
        } catch (error) {
          console.error('Error fetching product data:', error);
        }
      }
      async fetchPaginationData() {
        try {
          const {apiURL} = this.context;
          // var url = `${apiURL}/Parts/GetPartsPagging?country_id=225&lang='en'&page=${page}&limit=${limit}&category_id=${this.props.params.category_id}&part_brand=&min_price=0&max_price=`;
          var url = `${apiURL}/Parts/GetPartsPagging${this.GetURLQueryString()}`;
          this.setState( { loading: true } );
          const response = await axios.get(url);
          
          
          var row = response.data;
          const pageOptions = [];
          for (let i = 1; i <= row.total_pages; i++) {
            pageOptions.push({ value: i, label: i });
          }
          console.log("row", row)
          this.setState(
              { 
                total_records: row.total_records,
                total_pages: row.total_pages,
                page: row.page,
                next: row.next,
                prev: row.prev,
                pageOptions: pageOptions,
                max_price: row.data.max_price,
                min_price: row.data.min_price,
                loading: false
              }
            );
            if(this.state.first_time_loader === 0){
              this.setState({first_time_loader: 1, selected_min_price: row.data.min_price ,selected_max_price: row.data.max_price})
            }
        } catch (error) {
          console.error('Error fetching pagination data:', error);
        }
      }
      AddParam = (key, value) => {
        
        var qIndex = this.state.queryParam.findIndex(element => element.includes(`&${key}=`));
        var upQueryRows = this.state.queryParam; 
        if (qIndex !== -1) {
          upQueryRows[qIndex] = `&${key}=${value}`;
        }else{
          upQueryRows.push(`&${key}=${value}`);
        }
        this.setState({queryParam:upQueryRows, page: 1, loading: true, results: []}, async(prevState)=>{
          var qString = '';
          this.state.queryParam.map((item)=>
            qString+=item
          )
          var navURL = `/Results/${this.props.params.category_slug}?${qString}`;
          navURL = navURL.replaceAll('?&', '?');
          navURL = navURL.replaceAll('??', '?');
          this.props.navigate(navURL);
          setTimeout(async() => {
            await this.fetchProductData();
            await this.fetchPaginationData();
          }, 500);
        });
        // ?country_id=225&lang='en'&page=${page}&limit=${limit}&category_id=${this.props.params.category_id}&part_brand=&min_price=0&max_price=
        
      }
      GetURLQueryString = () => {
        
        const {lang} = this.context;
        var qString = `?country_id=225&lang=${lang}&limit=${this.state.limit}&page=${this.state.page}&min_price=${this.state.selected_min_price}&max_price=${this.state.selected_max_price}`;
        
        if(this.props.params.category_slug){
          qString+=`&category_id=${this.props.params.category_slug.split('-').pop()}`
        }
        if(this.props.queryParam.get('part_brand')){
          qString+="&part_brand="+this.props.queryParam.get('part_brand');
        }
        if(this.state.car_id){
          qString+="&car_id="+this.state.car_id;
        }
        console.log("GetURLQueryString", qString)
        qString = qString.replaceAll('?&', '?');
        qString = qString.replaceAll('??', '?');
        return qString;
      }
    
      ShowMore = () => {
        if(this.state.next === true){
          this.setState({page: this.state.page + 1, loading: true}, async(prevState)=>{
            await this.fetchProductData();
            this.fetchPaginationData();
          }); 
        }
      }
      AddOnData = (data) => {
        const {results} = this.state;
        var old_results = results;
        data.map( (row) => old_results.push(row) );
        this.setState({results: old_results});
       
      }
      GetTotalRemaingRecords = () => {
        var loadedRecords = Number(this.state.page) * Number(this.state.limit)
        return Math.abs(this.state.total_records - loadedRecords);
      }
       handlePrinceRange = (val) => {
        this.setState({selected_max_price: val.target.value})
      }
      
      onPriceChange = (val) => {
        this.setState({selected_min_price: val[0], selected_max_price: val[1] })
      }
    render() {

      const { t } = this.props;
        const {results, brands} = this.state;
        // console.log("cateId",this.props.params.category_id);
      
        
        return(
            <main className="main">
            <div className="container">
              <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><NavLink to={'/'}><i className="icon-home" /></NavLink></li>
                  <li className="breadcrumb-item"><NavLink to={() => {}}>{t('home')}</NavLink></li>
                  <li className="breadcrumb-item active" aria-current="page">{this.props.params.category_slug.replaceAll('-', ' ')}</li>
                </ol>
              </nav>
              {
                this.state.initLoading === true?<div style={{height:'400px'}}><center><Spinner /></center></div>:
              
              <div className="row">
                <div className="col-lg-9 main-content">

                  <nav className="toolbox sticky-header" data-sticky-options="{'mobile': true}">
                    <div className="toolbox-left">
                      <NavLink to={() => {}} className="sidebar-toggle">
                        <svg data-name="Layer 3" id="Layer_3" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                          <line x1={15} x2={26} y1={9} y2={9} className="cls-1" />
                          <line x1={6} x2={9} y1={9} y2={9} className="cls-1" />
                          <line x1={23} x2={26} y1={16} y2={16} className="cls-1" />
                          <line x1={6} x2={17} y1={16} y2={16} className="cls-1" />
                          <line x1={17} x2={26} y1={23} y2={23} className="cls-1" />
                          <line x1={6} x2={11} y1={23} y2={23} className="cls-1" />
                          <path d="M14.5,8.92A2.6,2.6,0,0,1,12,11.5,2.6,2.6,0,0,1,9.5,8.92a2.5,2.5,0,0,1,5,0Z" className="cls-2" />
                          <path d="M22.5,15.92a2.5,2.5,0,1,1-5,0,2.5,2.5,0,0,1,5,0Z" className="cls-2" />
                          <path d="M21,16a1,1,0,1,1-2,0,1,1,0,0,1,2,0Z" className="cls-3" />
                          <path d="M16.5,22.92A2.6,2.6,0,0,1,14,25.5a2.6,2.6,0,0,1-2.5-2.58,2.5,2.5,0,0,1,5,0Z" className="cls-2" />
                        </svg>
                        <span>{t('filter')}</span>
                      </NavLink>
                      <div className="toolbox-item toolbox-sort">
                        <label>{t('sort_by')}:</label>
                        <div>
                          <Select onChange={(e) => this.AddParam('order',e.value)} options={[{value:'price-asc', label: 'Low to High'}, {value:'price-desc', label: 'High to Low'}]} />
                           
                        </div>
                        {/* End .select-custom */}
                      </div>
                      {/* End .toolbox-item */}
                    </div>
                    {/* End .toolbox-left */}
                    <div className="toolbox-right">
                      {/* <div className="toolbox-item toolbox-show">
                        <label>Show:</label>
                        
                          <Select onChange={(e) => this.setState({page: e.value}, (prevState) => this.AddParam('page', this.state.page))} name="page" id="page"  options={this.state.pageOptions} />
                        
                       
                      </div> */}
                       {/* End .select-custom */}
                      {/* End .toolbox-item */}
                      {/* <div className="toolbox-item layout-modes">
                        <NavLink to={() => {}} className="layout-btn btn-grid active" title="Grid">
                          <i className="icon-mode-grid" />
                        </NavLink>
                        <NavLink to={() => {}} className="layout-btn btn-list" title="List">
                          <i className="icon-mode-list" />
                        </NavLink>
                      </div> */}
                      {/* End .layout-modes */}
                    </div>
                    {/* End .toolbox-right */}
                  </nav>
                  <div className="row">
                    {
                    results ? (
                      results.map((part, index) => (
                            <div key={`r-part-id-${part.parts_price_id}-${index}`} className="col-6 col-sm-4">
                              <PartsCard part_slug={part.part_slug}  part_uid={part.parts_price_id} part_id={part.part_id} title={part.category_title} PartNumber={part.part_number} price={part.sale_price} displayPrice={part.display_price} mrpPrice={part.price_mrp} partImg={part.part_picture} action={true}  greenLabel={part.part_brand} redLabel={part.part_type === 3?`Aftermarket`: 'Genuine'}/>
                          </div>
                        ))
                    ) : (
                        <h2>
                            <center>No Result Found</center>
                        </h2>
                    )}                 
                 
                    {/* End .col-sm-4 */}
                   
                    {/* End .col-sm-4 */}
                  </div>
                  {/* End .row */}
                  <nav className="toolbox toolbox-pagination justify-content-center">
                    {
                    // this.state.next == true? its gives warning that y its commented
                    this.state.next === true?
                      this.state.loading === true?( <center><button  disabled onClick={() => false} className="btn btn-outline-primary btn-sm" >Loading ...</button></center>
                    ):<center><button onClick={() => this.ShowMore()} className="btn btn-outline-primary btn-sm" >{t('show_more')} ({this.GetTotalRemaingRecords()})</button></center>
                  :<div></div>
                  }

                  </nav>
                </div>
                {/* End .col-lg-9 */}
                <div className="sidebar-overlay" />
                <aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
                  <div className="sidebar-wrapper">
                   
                   
                    {/* End .widget */}
                    <div className="widget widget-color">
                      <h3 className="widget-title">
                        <NavLink data-toggle="collapse" to="#widget-body-4" role="button" aria-expanded="true" aria-controls="widget-body-4">{t('brands')}</NavLink>
                      </h3>
                      <div className="collapse show" id="widget-body-4">
                        <div className="widget-body pb-0">
                        <div className="row">
                          {brands.map((brand) => (
                            <div style={{width: '60px', marginLeft:'2px'}}  key={`part-brand-${brand.part_brand}`} >
                              <div onClick={() => this.AddParam('part_brand', brand.part_brand)}  id={`part-brand-${brand.part_brand}`} style={{width: '100%', height: '50px', border: '1px solid #EEE', background: 'url("'+brand.brand_picture+'") center center / 80% no-repeat rgb(255, 255, 255)', display: 'inline-block', cursor: 'pointer', textAlign: 'center'}}>
                                <img style={{display: 'none'}} src={brand.brand_picture} height="40px" onError={({ currentTarget }) => {
                                currentTarget.src = "https://placehold.jp/FFF/000/45x65.png?text="+brand.part_brand;
                                currentTarget.parentNode.style.background = 'url("https://placehold.jp/FFF/000/45x60.png?text='+brand.part_brand+'")'
                                currentTarget.parentNode.style.backgroundRepeat = 'no-repeat';
                                currentTarget.parentNode.style.backgroundPosition = 'center';
                                // currentTarget.parentNode.innerHTML = brand.part_brand;
                              }} alt={brand.part_brand} />
                              </div>
                            </div>
                            // <div className="col-md-3 brandCss justify-content-center" key={`brandKey-${brand.part_brand}`} style={{margin: '2px', border: '1px solid #EEE', padding: '5px'}}><img src={brand.brand_picture} style={{height: 20}}/> </div>
                          ))}
                          </div>
                          
                        </div>
                        {/* End .widget-body */}
                      </div>
                      {/* End .collapse */}
                    </div>
                    {/* End .widget */}
                    <div className="widget" style={{display: 'none'}}>
                      <h3 className="widget-title">
                        <NavLink data-toggle="collapse" to="#widget-body-2" role="button" aria-expanded="true" aria-controls="widget-body-2">Categories</NavLink>
                      </h3>
                      <div className="collapse show" id="widget-body-2">
                        <div className="widget-body">
                          <ul className="cat-list">
                            <li>
                              <NavLink to="#widget-category-1" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="widget-category-1">
                                Accessories<span className="products-count">(3)</span>
                                <span className="toggle" />
                              </NavLink>
                              <div className="collapse show" id="widget-category-1">
                                <ul className="cat-sublist">
                                  <li>Caps<span className="products-count">(1)</span></li>
                                  <li>Watches<span className="products-count">(2)</span></li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <NavLink to="#widget-category-2" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="widget-category-2">
                                Dress<span className="products-count">(4)</span>
                                <span className="toggle" />
                              </NavLink>
                              <div className="collapse" id="widget-category-2">
                                <ul className="cat-sublist">
                                  <li>Clothing<span className="products-count">(4)</span></li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <NavLink to="#widget-category-3" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="widget-category-3">
                                Electronics<span className="products-count">(2)</span>
                                <span className="toggle" />
                              </NavLink>
                              <div className="collapse" id="widget-category-3">
                                <ul className="cat-sublist">
                                  <li>Headphone<span className="products-count">(1)</span></li>
                                  <li>Watch<span className="products-count">(1)</span></li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <NavLink to="#widget-category-4" className="collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="widget-category-4">
                                Fashion<span className="products-count">(6)</span>
                                <span className="toggle" />
                              </NavLink>
                              <div className="collapse" id="widget-category-4">
                                <ul className="cat-sublist">
                                  <li>Shoes<span className="products-count">(4)</span></li>
                                  <li>Bag<span className="products-count">(2)</span></li>
                                </ul>
                              </div>
                            </li>
                            <li><NavLink to="#">Music<span className="products-count">(2)</span></NavLink></li>
                          </ul>
                        </div>
                        {/* End .widget-body */}
                      </div>
                      {/* End .collapse */}
                    </div>
                    {/* End .widget */}
                    <div className="widget widget-size" style={{display: 'none'}}>
                      <h3 className="widget-title">
                        <NavLink data-toggle="collapse" to="#widget-body-5" role="button" aria-expanded="true" aria-controls="widget-body-5">Sizes</NavLink>
                      </h3>
                      <div className="collapse show" id="widget-body-5">
                        <div className="widget-body pb-0">
                          <ul className="config-size-list">
                            <li className="active"><NavLink to="#">XL</NavLink></li>
                            <li><NavLink to="#">L</NavLink></li>
                            <li><NavLink to="#">M</NavLink></li>
                            <li><NavLink to="#">S</NavLink></li>
                          </ul>
                        </div>
                        {/* End .widget-body */}
                      </div>
                      {/* End .collapse */}
                    </div>
                    {/* End .widget */}
                    <div className="widget widget-featured" style={{display: 'none'}}>
                      <h3 className="widget-title">Featured</h3>
                      <div className="widget-body">
                        <div className="owl-carousel widget-featured-products">
                          <div className="featured-col">
                            <div className="product-default left-details product-widget">
                              <figure>
                                <NavLink to={() => {}}>
                                  <img src="assets/images/products/small/product-4.jpg" width={75} height={75} alt="product" />
                                  <img src="assets/images/products/small/product-4-2.jpg" width={75} height={75} alt="product" />
                                </NavLink>
                              </figure>
                              <div className="product-details">
                                <h3 className="product-title"> <NavLink to={() => {}}>Blue Backpack for
                                    the Young - S</NavLink> </h3>
                                <div className="ratings-container">
                                  <div className="product-ratings">
                                    <span className="ratings" style={{width: '100%'}} />
                                    {/* End .ratings */}
                                    <span className="tooltiptext tooltip-top" />
                                  </div>
                                  {/* End .product-ratings */}
                                </div>
                                {/* End .product-container */}
                                <div className="price-box">
                                  <span className="product-price">$49.00</span>
                                </div>
                                {/* End .price-box */}
                              </div>
                              {/* End .product-details */}
                            </div>
                            <div className="product-default left-details product-widget">
                              <figure>
                                <NavLink to={() => {}}>
                                  <img src="assets/images/products/small/product-5.jpg" width={75} height={75} alt="product" />
                                  <img src="assets/images/products/small/product-5-2.jpg" width={75} height={75} alt="product" />
                                </NavLink>
                              </figure>
                              <div className="product-details">
                                <h3 className="product-title"> <NavLink to={() => {}}>Casual Spring Blue
                                    Shoes</NavLink> </h3>
                                <div className="ratings-container">
                                  <div className="product-ratings">
                                    <span className="ratings" style={{width: '100%'}} />
                                    {/* End .ratings */}
                                    <span className="tooltiptext tooltip-top" />
                                  </div>
                                  {/* End .product-ratings */}
                                </div>
                                {/* End .product-container */}
                                <div className="price-box">
                                  <span className="product-price">$49.00</span>
                                </div>
                                {/* End .price-box */}
                              </div>
                              {/* End .product-details */}
                            </div>
                            <div className="product-default left-details product-widget">
                              <figure>
                                <NavLink to={() => {}}>
                                  <img src="assets/images/products/small/product-6.jpg" width={75} height={75} alt="product" />
                                  <img src="assets/images/products/small/product-6-2.jpg" width={75} height={75} alt="product" />
                                </NavLink>
                              </figure>
                              <div className="product-details">
                                <h3 className="product-title"> <NavLink to={() => {}}>Men Black Gentle
                                    Belt</NavLink> </h3>
                                <div className="ratings-container">
                                  <div className="product-ratings">
                                    <span className="ratings" style={{width: '100%'}} />
                                    {/* End .ratings */}
                                    <span className="tooltiptext tooltip-top" />
                                  </div>
                                  {/* End .product-ratings */}
                                </div>
                                {/* End .product-container */}
                                <div className="price-box">
                                  <span className="product-price">$49.00</span>
                                </div>
                                {/* End .price-box */}
                              </div>
                              {/* End .product-details */}
                            </div>
                          </div>
                          {/* End .featured-col */}
                          <div className="featured-col">
                            <div className="product-default left-details product-widget">
                              <figure>
                                <NavLink to={() => {}}>
                                  <img src="assets/images/products/small/product-1.jpg" width={75} height={75} alt="product" />
                                  <img src="assets/images/products/small/product-1-2.jpg" width={75} height={75} alt="product" />
                                </NavLink>
                              </figure>
                              <div className="product-details">
                                <h3 className="product-title"> <NavLink to={() => {}}>Ultimate 3D
                                    Bluetooth Speaker</NavLink> </h3>
                                <div className="ratings-container">
                                  <div className="product-ratings">
                                    <span className="ratings" style={{width: '100%'}} />
                                    {/* End .ratings */}
                                    <span className="tooltiptext tooltip-top" />
                                  </div>
                                  {/* End .product-ratings */}
                                </div>
                                {/* End .product-container */}
                                <div className="price-box">
                                  <span className="product-price">$49.00</span>
                                </div>
                                {/* End .price-box */}
                              </div>
                              {/* End .product-details */}
                            </div>
                            <div className="product-default left-details product-widget">
                              <figure>
                                <NavLink to={() => {}}>
                                  <img src="assets/images/products/small/product-2.jpg" width={75} height={75} alt="product" />
                                  <img src="assets/images/products/small/product-2-2.jpg" width={75} height={75} alt="product" />
                                </NavLink>
                              </figure>
                              <div className="product-details">
                                <h3 className="product-title"> <NavLink to={() => {}}>Brown Women Casual
                                    HandBag</NavLink> </h3>
                                <div className="ratings-container">
                                  <div className="product-ratings">
                                    <span className="ratings" style={{width: '100%'}} />
                                    {/* End .ratings */}
                                    <span className="tooltiptext tooltip-top" />
                                  </div>
                                  {/* End .product-ratings */}
                                </div>
                                {/* End .product-container */}
                                <div className="price-box">
                                  <span className="product-price">$49.00</span>
                                </div>
                                {/* End .price-box */}
                              </div>
                              {/* End .product-details */}
                            </div>
                            <div className="product-default left-details product-widget">
                              <figure>
                                <NavLink to={() => {}}>
                                  <img src="assets/images/products/small/product-3.jpg" width={75} height={75} alt="product" />
                                  <img src="assets/images/products/small/product-3-2.jpg" width={75} height={75} alt="product" />
                                </NavLink>
                              </figure>
                              <div className="product-details">
                                <h3 className="product-title"> <NavLink to={() => {}}>Circled Ultimate
                                    3D Speaker</NavLink> </h3>
                                <div className="ratings-container">
                                  <div className="product-ratings">
                                    <span className="ratings" style={{width: '100%'}} />
                                    {/* End .ratings */}
                                    <span className="tooltiptext tooltip-top" />
                                  </div>
                                  {/* End .product-ratings */}
                                </div>
                                {/* End .product-container */}
                                <div className="price-box">
                                  <span className="product-price">$49.00</span>
                                </div>
                                {/* End .price-box */}
                              </div>
                              {/* End .product-details */}
                            </div>
                          </div>
                          {/* End .featured-col */}
                        </div>
                        {/* End .widget-featured-slider */}
                      </div>
                      {/* End .widget-body */}
                    </div>
                    {/* End .widget */}
                    <div className="widget widget-block">
                      {/* <h3 className="widget-title">Custom HTML Block</h3>
                      <h5>This is a custom sub-title.</h5>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras non placerat mi. Etiam non tellus </p> */}
                    </div>
                    {/* End .widget */}
                  </div>
                  {/* End .sidebar-wrapper */}
                </aside>
              </div>}
              {/* End .row */}
            </div>
            {/* End .container */}
            <div className="mb-4" />
            {/* margin */}
          </main>
        )
    }
handePageChange = () => {
  
  this.fetchProductData()
  this.fetchPaginationData()
}
}

export default withRouter(withTranslation()(Results))
