import React, {Component} from "react";
import {NavLink} from 'react-router-dom';
import { ConfigContext } from "../../Context/ConfigContext";
import {  Spinner } from "../../Components/Shimmers";
import axios from "axios";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PartsCard from "../../Components/Cards/PartsCard";
class Offers extends Component{
  static contextType = ConfigContext;
  constructor(props){
    super(props);
    this.state = {
        offers: [],
        loadingOffers: true,
    }
  }
  GetOffers = async () => {
    const { apiURL } = this.context;
    var lang = localStorage.getItem('lang')??'en';
    var currency = localStorage.getItem('currency')??'aed';
    try {
      const response = await axios.get(
        `${apiURL}/Offers/GetOffers?country_id=225&lang=${lang}&currency=${currency}`
      );
      console.log("offers", response.data)
      if (response.data.success) {
        
        this.setState({offers: response.data.data, loadingOffers: false}, (prevState)=>{
            // console.log('offers', this.state.offers);
        });
        
      }else{
        this.setState({loadingOffers: false});
      }
    } catch (error) {
        this.setState({loadingOffers: false});
      // console.error("Error fetching part details:", error);
    }
  };
  componentDidMount(){
    this.setState({loadingOffers: true}, (prevState)=>{
        this.GetOffers();
    })
  }
    render(){
        return(
            <div className="fff">
                {
                    this.state.loadingOffers === true?<div className="col-lg-12 justify-content-center"> <center><Spinner /></center></div>:
                    (
                        this.state.offers.map((offer, indexOffer) => {
                        return <section className="featured-products-section" key={`offers-${indexOffer}`}>
                            <div className="container">
                            <h2 className="section-title heading-border ls-20 border-0">{offer.offer_title}</h2>
                            <OwlCarousel className='owl-theme' items={offer.childrens.length>8?6:5} loop margin={10} dots={false} responsive={{
                    '700':{
                        items: 2
                    },
                    '825': {
                        items: offer.childrens.length>8?6:5
                    }
                }} nav>
                                {
                                    offer.childrens.map((part, index)=>{
                                        return <PartsCard part_slug={part.part_slug} key={`offers-${part.part_uid}-${index}-${indexOffer}`}  part_uid={part.part_uid} part_id={part.part_id} title={part.part_title} PartNumber={part.part_number} price={part.price} displayPrice={part.display_price} mrpPrice={part.price_mrp} displayMRPPrice={part.display_price_mrp} partImg={part.part_picture} action={true}  greenLabel={part.discount>0?(Number(part.display_discount.slice(0, 2)) + '% SAVE'):''} redLabel={part.part_brand}/>
                                    })
                                }
                            </OwlCarousel>
                            </div>
                        </section>
                        })
                    )
                    
                }
            </div>
        )
    }
}

export default Offers