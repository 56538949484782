import axios from "axios";
import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import withRouter from "../../Routes/withRouter";
import { withTranslation } from 'react-i18next';
class Signup extends Component{
    
    static contextType = ConfigContext;
    
    componentDidMount(){
        this.GetCountries();
    }
    
    constructor(){
        super();
        this.state = {
            first_name : '',
            last_name : '',
            email_address : '',
            mobile_number : '',
            countries : [],
            country_code : '',
            country_phone_code: 0,
            error_message_for_SignIn : '',
            success_message_for_SignIn : '',
            error_message_for_SignUp : '',
            success_message_for_SignUp : '',
            errors: {},
            user_id : '',
            user_password : '',
            user_confirm_password : ''
        }
    }

    async GetCountries(){
        const { apiURL } = this.context;
        try{
            const response = await axios.get(`${apiURL}/Home/GetCountries`);

            const data = response.data;
            if(data){
                this.setState({ countries : data.data }); 
            }else{
                console.log("NO COuntires Found", response.data.message);
            }
        }catch(err){
            console.log("Something Went Wrong ",err);
        }
    } 

    handleLogin = async (e) => {
        e.preventDefault();
        const { apiURL,setLoginStatus , setToken} = this.context;
        const { user_id, user_password } = this.state;

        const formData = { login_id : user_id, password : user_password, };
    
        try {
          const { data } = await axios.post(
            `${apiURL}/Account/Login`,
            null, { params: formData }
          );
            

          if (data.success) {
            localStorage.setItem('token', data.data[0].token);
            setToken(data.data[0].token);
            localStorage.setItem('customer_id', data.data[0].customer_id);
            localStorage.setItem('email_address', data.data[0].email_address);
            localStorage.setItem('first_name', data.data[0].first_name);
            localStorage.setItem('mobile_number', data.data[0].mobile_number);
            this.setState({ success_message_for_SignIn: data.message, error_message_for_SignIn: '' });            
            setLoginStatus(!!localStorage.getItem('token'));
            setTimeout(() => {
              this.props.navigate("/");
            }, 1000);
          } else {
            this.setState({ error_message_for_SignIn: data.message, success_message_for_SignIn: '' });
          }
        } catch (error) {
          console.error("Error:", error);
          this.setState({ error_message_for_SignIn: "An error occurred while logging in.", success_message_for_SignIn: '' });
        }
      };
    

      handleSignUp = async (e) => {
        e.preventDefault();
        const { apiURL } = this.context;
        const {t} = this.props;
        const { first_name, last_name, email_address, mobile_number, password, country_code } = this.state;
        const errors = {};
        if (!first_name) {
          errors.first_name = t('first_name_error');
        } else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)?$/.test(first_name)) {
          errors.first_name = t('first_name_error');
        }


        if (!last_name) {
          errors.last_name = t('last_name_error');
        } else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)?$/.test(last_name)) {
          errors.last_name = t('last_name_error');
        }

        if (!email_address) {
          errors.email_address = t('email_error');
        }
        if (!password) {
          errors.password = t('password_error');
        }

        if (Object.keys(errors).length === 0) {
          // Form submission logic goes here
          console.log('Form submitted');
        } else {
          this.setState({ errors });
          return false;
        }

        const formData = {
          first_name,
          last_name,
          email_address,
          mobile_number,
          password,
          country_code
        };
        
        try {
          const { data } = await axios.post(
            `${apiURL}/Account/Signup`,
            null,
            { params: formData }
          );
            if (data.success) {
            this.setState({
              first_name: "",
              last_name: "",
              email_address: "",
              mobile_number: "",
              password: "",
              country_code: "",
              success_message_for_SignUp: data.message,
              error_message_for_SignUp: "",
            });
    
            setTimeout(() => {
              this.setState({ success_message_for_SignUp: "" });
            }, 1000);
            this.props.navigate("/Signup");
            // this.props.history.push("/Signup");
          } else {
            this.setState({ error_message_for_SignUp: data.message, success_message_for_SignUp: "" });
          }
        } catch (error) {
          console.error("Error:", error);
          this.setState({ error_message_for_SignUp: "An error occurred while signing up.", success_message_for_SignUp: "" });
        }
      };
       handleCountryChange = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const phoneCode = e.target.options[selectedIndex].getAttribute('data-phone_code');
    
       
        this.setState({country_phone_code: phoneCode});
      
      };
    render(){
        const { t } = this.props;
        const { user_id, user_password, first_name, last_name, email_address, mobile_number, countries, country_code, password, success_message_for_SignIn, error_message_for_SignIn, success_message_for_SignUp, error_message_for_SignUp } = this.state;
        const dir = localStorage.getItem('dir');
        return(
            <main className="main" style={{direction: dir, textAlign: dir === 'rtl'?'right': 'left'}}>
		

			<div className="container login-container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="row">
                  <div className="col-md-6">
                    <div className="heading mb-1 mt-5">
                      <h2 className="title">{t('login')}</h2>
                    </div>
                    <form 
                    onSubmit={this.handleLogin}
                    >
                    <label htmlFor="login-email">
                    {t('email')}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-input form-wide"
                      id="User_id"
                      required
                      value={user_id}
                      onChange={(e) => this.setState({ user_id : e.target.value})}
                    />
                    
                    <label htmlFor="login-password"> {t('password')} <span className="required">*</span> </label>
                    <input type="password" className="form-input form-wide" id="User_password" required value={user_password} onChange={(e) => this.setState({user_password : e.target.value})} />
                    

                    <div className="form-footer d-inline">
                      <div className="custom-control custom-checkbox float-left">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="lost-password"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="lost-password"
                        >
                          {t('remember_me')}
                        </label>
                      </div>
                      <div className='float-right'>
                      <NavLink to="/forgot-password" className="forget-password text-dark">
                      {t('forget_password')}
                      </NavLink>
                      </div>
                    </div>

                    <button type="submit" className="btn btn-dark btn-md w-100">
                    {t('login')}
                    </button>
                    {error_message_for_SignIn && (
                            <div id="error" className="alert alert-danger mt-1">
                              {error_message_for_SignIn}
                            </div>
                          )}
                          {success_message_for_SignIn && (
                            <div id="success" className="alert alert-success mt-1">
                              {success_message_for_SignIn}
                            </div>
                          )}
                  </form>

                  </div>
                  <div className="col-md-6">
                    <div className="heading mb-1 mt-5">
                      <h2 className="title">{t('create_account')}</h2>
                    </div>
                    
                    <form onSubmit={this.handleSignUp} >                        
                          <label htmlFor="first_name">{t('first_name')}</label>
                          <input
                            name="first_name"
                            className="form-input form-wide"
                            placeholder={t('first_name')}
                            value={first_name}
                            onChange={(e) => this.setState({ first_name : e.target.value})}
                            type="text"
                            required
                          />
                          {this.state.errors.first_name && <div className="colmd-12" style={{color: 'red'}}>{this.state.errors.first_name}</div>}
                          <label htmlFor="last_name">{t('last_name')}</label>
                          <input
                            name="last_name"
                            className="form-input form-wide"
                            placeholder={t('last_name')}
                            type="text"
                            value={last_name}
                            onChange={(e) => this.setState({ last_name : e.target.value})}
                            required
                          />
                          {this.state.errors.last_name && <div className="colmd-12" style={{color: 'red'}}>{this.state.errors.last_name}</div>}

                          
                          <label htmlFor="country">{t('country')}</label>
                          <select
                              name="country_code"
                              className="form-input form-wide"
                              value={country_code}
                              onChange={(e) => {this.setState({ country_code : e.target.value }); this.handleCountryChange(e)}}
                              required
                            >
                              <option value="">{t('select_country')}</option>
                              {Array.isArray(countries) &&
                                countries.map((country) => (
                                  <option data-phone_code={country.phone_code} key={country.country_id} value={country.country_id}>
                                    {country.country}
                                  </option>
                                ))}
                            </select>
                            <label htmlFor="email_address">{t('email')}</label>                          
                          <input
                            name="email_address"
                            className="form-input form-wide"
                            placeholder={t('email')}
                            value={email_address}
                            type="email"
                            onChange={(e) => this.setState({ email_address : e.target.value })}
                            required
                          />
                          {this.state.errors.email_address && <div className="colmd-12" style={{color: 'red'}}>{this.state.errors.email_address}</div>}

                          <label htmlFor="mobile_number">{t('contact_number')}</label>                          
                          <table>
                            <tbody>
                              <tr>
                                <td width={`20%`}>
                                <input
                                    name="country_phone_code"
                                    className="form-input form-wide"
                                    placeholder={`000`}
                                    type="number"
                                    width={10}
                                    length={4}
                                    readOnly
                                    value={this.state.country_phone_code}
                                    required
                                  />
                                </td>

                                <td>
                                <input
                                    name="mobile_number"
                                    className="form-input form-wide"
                                    placeholder={t('contact_number')}
                                    type="number"
                                    value={mobile_number}
                                    onChange={(e) => this.setState({ mobile_number : e.target.value})}
                                    required
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {this.state.errors.mobile_number && <div className="colmd-12" style={{color: 'red'}}>{this.state.errors.mobile_number}</div>}
                          <label htmlFor="password">{t('password')}</label>
                          <input
                            name="password"
                            className="form-input form-wide"
                            placeholder={t('password')}
                            value={password}
                            type="password"
                            onChange={(e) => this.setState({ password : e.target.value })}
                            required
                            />

                          <label htmlFor="password">{t('confirm_password')}</label>
                          <input
                            name="confirm_password"
                            className="form-input form-wide"
                            placeholder={t('confirm_password')}
                            value={this.state.user_confirm_password}
                            type="password"
                            onChange={(e) => this.setState({ user_confirm_password : e.target.value })}
                            required
                            />
                          <div className="form-footer mb-2">
                            <button type="submit" className="btn btn-dark btn-md w-100 mr-0">
                            {t('create_account')}
                            </button>
                          </div>  
                          {error_message_for_SignUp && (
                            <div id="error" className="alert alert-danger">
                              {error_message_for_SignUp}
                            </div>
                          )}
                          {success_message_for_SignUp && (
                            <div id="success" className="alert alert-success">
                              {success_message_for_SignUp}
                            </div>
                          )}
                      </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
		</main>
        )
    }
}
export default withRouter(withTranslation()(Signup))