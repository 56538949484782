exports.AddToRecent = (item_id, item_number, item_title, item_mrp, item_price, item_qty, item_img_url, item_url) => {
    
    var recents = localStorage.getItem('recents')?JSON.parse(localStorage.getItem('recents')): [];
    
    if (recents !== "" && recents !== undefined && recents !== null) {
        // recents = JSON.parse(recents);
        const index = recents.findIndex(item => item.item_id === item_id);
        if (index !== -1) {
            recents[index].item_qty = recents[index].item_qty + item_qty;
            localStorage.setItem('recents', JSON.stringify(recents));
            return true;
        }else{
            recents.push({item_id: item_id, item_number: item_number, item_title: item_title, item_mrp: item_mrp, item_price: item_price, item_qty: item_qty, item_img_url: item_img_url, item_url: item_url});
            localStorage.setItem('recents', JSON.stringify(recents));
            return true;
        }
    }else{
        recents.push({item_id: item_id, item_number: item_number, item_title: item_title, item_mrp: item_mrp, item_price: item_price, item_qty: item_qty, item_img_url: item_img_url, item_url: item_url});
        localStorage.setItem('recents', JSON.stringify(recents));
        return true;
    }
    
}


exports.DeleteRecentItem = (index) => {
    var recents = localStorage.getItem('recents');
    console.log("recents",recents);
    recents = JSON.parse(recents);
    recents.splice(index, 1);
    localStorage.setItem('recents', JSON.stringify(recents));
    return true;
}

exports.GetRecents = () => {
    var recents = localStorage.getItem('recents');
    
    if(recents === "" || recents === null){
      return [];
    }
    
    recents = recents!==""?JSON.parse(recents):[];
    // console.log("recents", recents)
    return recents;
}