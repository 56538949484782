import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import slugify from "slugify";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";
import CatelogModal from "./CatelogModal";
import TabsItems from "./TabsItems";
import Tabs from "./Tabs";
const Sections = ({tabNameForeign}) => {
    const { t } = useTranslation();
    

    return(<>
    <section className="feature-boxes-container" style={{background: '#f7f7f7'}}>
   
      <div className="container" data-animation-name="fadeInUpShorter" >
      <Tabs />
      
      </div>
    </section>
    </>)
  
}

export default Sections