import { Component } from "react";
import {NavLink} from 'react-router-dom';
import { ConfigContext } from "../../Context/ConfigContext";
// import { AddToCart, GetCarts } from "../../Helper/Carts";
import { AddToCart, GetCarts } from "../../Helper/MyCart";
import { ToastContainer, toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { AddToCartNotifier } from "../CartComponents";
class PartsCard2 extends Component{
  static contextType = ConfigContext;
  constructor(props){
    super(props);
    this.state  = {
      add_to_cart_status : false
    };
  }
  handleAddToCart = async(slug) => {
    // toast.success('Successfully toasted!');
    const {UpdateCartList, ChangeCartProcessStatus, apiURL, CartItemsQty, setCartItemsQty} = this.context;
    this.setState({add_to_cart_status: true});
    // console.log(this.props.id,this.props.number, this.props.title, this.props.mrp, this.props.price,  this.props.qty, this.props.imgUrl, this.props.url)
    await AddToCart(this.props.id,this.props.number, this.props.title, this.props.mrp, this.props.price,  this.props.qty, this.props.imgUrl, this.props.url);
    setCartItemsQty();
    const { t } = this.props;
    var partImg = this.props.partImg?this.props.partImg:`${apiURL}/public/placeholder_category.jpeg`
    toast.success(<AddToCartNotifier item_name={this.props.title}  item_img={partImg}/>, {
      onClose: () => {
        this.setState({add_to_cart_status: false});
      }
    })
    // UpdateCartList(GetCarts());
    // ChangeCartProcessStatus(true);
    
  }
   
    render(){
      const {apiURL, gdcURL} = this.context;
      const { t } = this.props;
      const {add_to_cart_status} = this.state;
      var partImg = this.props.partImg?`${gdcURL}/images/${this.props.partImg}`:`${apiURL}/public/placeholder_category.jpeg`
      var partURL = this.props.part_url; 
      return (
            <div className="product-default" data-animation-name="fadeInRightShorter">
              <figure>
               <NavLink to={this.props.url}>
                  <img src={partImg} width={280} height={280} alt="product" style={{height: '280px', width: 'auto', float: 'none', margin: 'auto'}} />
                  <img src={partImg} width={280} height={280} alt="product" />
                </NavLink>
                <div className="label-group">
                  {this.props.greenLabel===''|| this.props.greenLabel==null?<div></div>:<div className="product-label label-hot">{(this.props.greenLabel)}</div>}
                  <div className="product-label label-sale">{(this.props.redLabel)}</div>
                </div>
              </figure>
              <div className="product-details">
                {this.props.PartNumber?
                    <div className="category-list">
                    <NavLink to={this.props.url} className="product-category">#{this.props.number}</NavLink>
                    </div>:<div></div>
                }
               
                
                <h3 className="product-title">
                <NavLink to={this.props.url}>{this.props.title} - {this.props.number}</NavLink>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{width: '80%'}} />
                   
                    <span className="tooltiptext tooltip-top" />
                  </div>
                 
                </div> 
                <div>
                  {
                    this.props.criteria && this.props.criteria.split(';').map((item, index) => {
                      return <button key={`btn-cr-${index}`} className="btn btn-default btn-ellipse btn-xs" style={{marginLeft: '10px', marginTop: '10px'}}>{item}</button>
                    })
                  }
                </div>
                {/* End .product-container */}
                
                <div className="price-box">
                  <table>
                    <thead></thead>
                    <tbody>
                    <tr>
                      <td><span><del className="old-price">{this.props.displayMRPPrice}</del></span></td>
                      <td><span className="product-price">{this.props.displayPrice}</span></td>
                    </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                  
                </div>
                {/* End .price-box */}
                {this.props.action?
                <div className="product-action">
                  <NavLink to={() => false} className="btn-icon-wish" title={t(`wishlist`)}><i className="icon-heart" /></NavLink>
                  <button disabled={add_to_cart_status} onClick={() => this.handleAddToCart('')} className="btn-icon btn-add-cart"><i className="fa fa-arrow-right" />
                      <span style={{textTransform: 'uppercase'}}>
                        {t('add_to_cart')}    
                      </span>
                  </button>
                  <NavLink to={this.props.url} className="btn-quickview" title="Quick View"><i className="fas fa-external-link-alt" /></NavLink>
                </div>:<div></div>
                }
                
              </div>
              
              {/* End .product-details */}
            </div>
        )
    }
}

export default withTranslation()(PartsCard2);